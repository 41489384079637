/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis2Lines from 'utils/styleSnippets/textEllipsis2Lines';
import { ReactElement } from 'react';
import { Text } from 'components';

interface TitleProps {
	title: string;
}

export default function Title({ title }: TitleProps): ReactElement {
	return (
		<Text
			variant="mediumStrong"
			sx={{
				color: 'neutral800',
				...textEllipsis2Lines,
			}}
		>
			{title}
		</Text>
	);
}
