/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconArrowDropDown, IconArrowDropUp } from 'components/icons';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { theme } from 'styles.v2/theme';

import { ActionCategory, ActionCategoryItem } from './types';
import ActionSubGroups from './ActionSubGroups';
import Actions from './Actions';

interface ActionGroupProps {
	selectedActionId?: string;
	group: ActionCategory;
	collapsed?: boolean;
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function ActionGroup({
	selectedActionId,
	collapsed,
	group,
	onActionClick,
}: ActionGroupProps): ReactElement {
	const [expanded, setExpanded] = useState(true);

	if (collapsed) {
		return (
			<Flex
				spacing="large"
				align="center"
				style={{
					width: '58px',
					borderBottom: '1px solid ' + theme.colors.neutral200,
					marginBottom: '12px',
					paddingBottom: '12px',
				}}
			>
				<ActionSubGroups
					selectedActionId={selectedActionId}
					subGroups={group.subCategories}
					collapsed={collapsed}
					onActionClick={onActionClick}
				/>
				<Actions
					selectedActionId={selectedActionId}
					actions={group.actions}
					collapsed={collapsed}
					onActionClick={onActionClick}
				/>
			</Flex>
		);
	}

	const content = (
		<Flex style={{ width: '100%' }}>
			<ActionSubGroups
				selectedActionId={selectedActionId}
				subGroups={group.subCategories}
				onActionClick={onActionClick}
			/>
			<Actions selectedActionId={selectedActionId} actions={group.actions} onActionClick={onActionClick} />
		</Flex>
	);
	if (!group.label) {
		return content;
	}

	return (
		<div
			style={{
				paddingBottom: expanded ? '12px' : '6px',
				paddingTop: '6px',
				borderBottom: '1px solid ' + theme.colors.neutral200,
				width: '100%',
			}}
		>
			<Flex
				spacing="xxSmall"
				direction="horizontal"
				align="center"
				style={{
					py: 'small',
					cursor: 'pointer',
				}}
				onClick={() => setExpanded(!expanded)}
			>
				<Text type="mediumStrong" style={{ color: expanded ? theme.colors.slate : theme.colors.neutral700 }}>
					{group.label}
				</Text>
				{expanded ? (
					<IconArrowDropUp color={expanded ? theme.colors.slate : theme.colors.neutral700} />
				) : (
					<IconArrowDropDown color="neutral600" />
				)}
			</Flex>
			{expanded && content}
		</div>
	);
}
