/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { Tooltip } from 'components';

interface CollapsedEntryProps {
	children: ReactElement;
	tooltip: string;
	onClick: () => void;
}

export default function CollapsedEntry({ tooltip, children, onClick }: CollapsedEntryProps): ReactElement {
	return (
		<Tooltip content={tooltip}>
			<Flex align="start" justify="center" onClick={onClick}>
				{children}
			</Flex>
		</Tooltip>
	);
}
