/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconCheck, IconEnvironment, IconEnvironmentGlobal, IconWarningOpen } from 'components/icons';
import { Grid, Text } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface EnvironmentProps {
	environment: EnvironmentSummaryVO;
	isPermitted: boolean;
	isSelected: boolean;
	disabled: boolean;
	onClick: () => void;
}

export default function Environment({
	environment,
	isPermitted,
	isSelected,
	disabled,
	onClick,
}: EnvironmentProps): ReactElement {
	const hasError = !isPermitted && isSelected;

	return (
		<Grid
			cols="20px 1fr 20px"
			spacing="xSmall"
			style={{
				width: 'calc(100% - 31px)',
				padding: '12px 16px',
				cursor: 'pointer',
				onHover: {
					backgroundColor: theme.colors.neutral100,
				},
			}}
			onClick={disabled ? undefined : onClick}
		>
			{environment.global ? (
				<IconEnvironmentGlobal color={hasError ? theme.colors.coral : theme.colors.neutral700} />
			) : (
				<IconEnvironment color={hasError ? theme.colors.coral : theme.colors.neutral700} />
			)}
			<Text
				type={isSelected ? 'mediumStrong' : 'medium'}
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					color: hasError ? theme.colors.coral : theme.colors.neutral700,
				}}
			>
				{environment.name}
			</Text>

			{isSelected && <IconCheck color={hasError ? theme.colors.coral : theme.colors.neutral700} />}

			{hasError && (
				<>
					<IconWarningOpen variant="small" color={theme.colors.coral} />
					<Text
						type="smallMedium"
						style={{
							columnSpan: 'all',
							color: theme.colors.coral,
						}}
					>
						You don’t have any permission for this Environment. Please choose another one.
					</Text>
				</>
			)}
		</Grid>
	);
}
