/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

interface ResultError {
	message: string;
	statusCode?: number;
}
export interface LoadingResult {
	loading: true;
	error: undefined;
	value: undefined;
}
export interface ErrorResult {
	loading: false;
	error: ResultError;
	value: undefined;
}
interface DataResult<T> {
	loading: false;
	error: undefined;
	value: T;
}
export type DataStreamResult<T> = LoadingResult | ErrorResult | DataResult<T>;

export function createErrorResult(message: string, statusCode?: number): ErrorResult {
	return {
		loading: false,
		error: {
			message,
			statusCode,
		},
		value: undefined,
	};
}

export const loadingResult: LoadingResult = { loading: true, error: undefined, value: undefined };

export function createResult<T>(value: T): DataResult<T> {
	return { loading: false, error: undefined, value };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDataStreamResultLike(value: any): value is DataStreamResult<any> {
	return typeof value?.loading === 'boolean';
}
