/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, BaseExperimentStepExecutionVOUnion, BaseExperimentStepVOUnion, ExperimentLaneVO } from 'ui-api';
import { ExperimentPreview as ExperimentPreviewComponent } from '@steadybit/ui-components-lib';
import StepTooltipContent from 'pages/experimentsV2/Workspace/StepTooltipContent';
import { ExperimentError } from 'pages/experimentsV2/types';
import { convertDurationToSeconds } from 'utils/duration';
import { ReactElement } from 'react';

interface ExperimentPreviewProps {
	stepIdToError?: Map<string, ExperimentError[]>;
	selectedStepIds?: Set<string>;
	lanes: ExperimentLaneVO[];
	actions: ActionVO[];
}

export default function ExperimentPreview({
	stepIdToError = new Map(),
	selectedStepIds,
	actions,
	lanes,
}: ExperimentPreviewProps): ReactElement {
	return (
		<ExperimentPreviewComponent
			lanes={lanes.map((lane) => ({
				steps: lane.steps.map((step) => {
					const errors = stepIdToError.get(step.id) || [];
					const isHighlighted = selectedStepIds ? selectedStepIds.has(step.id) : false;
					const isFaded = selectedStepIds ? !selectedStepIds.has(step.id) : false;

					if (step.type === 'wait') {
						return {
							duration: convertDurationToSeconds(step.parameters.duration) || 10,
							label: step.customLabel || 'Wait',
							type: step.type,
							renderTooltip: StepTooltip.bind(null, { step, errors }),
							isFaded,
							isHighlighted,
							errors,
						};
					}

					const action = actions.find((a) => a.id === step.actionId);
					const subType = step.actionId ? action?.kind : undefined;

					return {
						duration: convertDurationToSeconds(step.parameters.duration) || 10,
						label: step.customLabel ?? action?.name ?? 'Action',
						type: step.type,
						kind: subType,
						renderTooltip: StepTooltip.bind(null, { step, errors }),
						isFaded,
						isHighlighted,
						errors,
					};
				}),
			}))}
		/>
	);
}

function StepTooltip({
	errors,
	step,
}: {
	step: BaseExperimentStepVOUnion | BaseExperimentStepExecutionVOUnion;
	errors: ExperimentError[];
}): ReactElement {
	return <StepTooltipContent step={step} errors={errors} />;
}
