/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { GetExperimentExecutionsPageResponse } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { createStableId } from 'utils/string';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useMemo, useState } from 'react';
import { useHistory } from 'url/hooks';
import { debounce } from 'lodash';

import {
	directionParam,
	environmentIdsParam,
	pageParam,
	sortParam,
	statesParam,
	teamIdsParam,
	timeParam,
	UrlState,
} from '../runs/urlParams';

export default function useRefreshingExperimentRuns(): DataStreamResult<GetExperimentExecutionsPageResponse> {
	const history = useHistory();
	const team = useTeam();
	const [{ environmentIds, direction, teamIds, time, states, page, sort }] = useUrlState<UrlState>([
		environmentIdsParam,
		directionParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
		sortParam,
	]);

	// This is a special case. Filters are handled via matrix params and matrix params only apply when on the /experimentruns route.
	// We are already doing the request (do show the counter) on the /experiments page. Counters would differ, because we are always adding
	// the current team to the URL, when navigation to /experimentruns.
	const usedTeamIds = teamIds.length === 0 && history.location.pathname === '/experiments' ? [team.id] : teamIds;

	const [reloadSignal, setReloadSignal] = useState(0);
	const experimentExecutionsResult = usePromise(
		() =>
			Services.experiments.fetchExperimentRunsList({
				direction: direction === 'ASC' ? 'asc' : 'desc',
				teamIds: usedTeamIds,
				environmentIds,
				pageSize: 15,
				states,
				page,
				sort,
				time,
			}),
		[
			createStableId(environmentIds),
			createStableId(usedTeamIds),
			createStableTime(time),
			createStableId(states),
			reloadSignal,
			direction,
			page,
			sort,
		],
	);

	const debouncedFetchExperimentRuns = useMemo(
		() => debounce(() => setReloadSignal(reloadSignal + 1), 1000, { leading: true }),
		[reloadSignal],
	);

	useEventEffect(
		(event) => {
			if (event.type.startsWith('experiment.execution')) {
				debouncedFetchExperimentRuns();
			}
		},
		[],
		debouncedFetchExperimentRuns.cancel,
		[debouncedFetchExperimentRuns],
	);

	return experimentExecutionsResult;
}

function createStableTime(time: string | [number, number] | undefined): string {
	if (!time) return '';
	if (typeof time === 'string') return time;
	return time.join(',');
}
