/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconInProgress } from 'components/icons';
import { Container } from 'components/Container';
import { theme } from 'styles.v2/theme';
import { StyleProp } from 'components';
import { ReactElement } from 'react';

interface LoadingIndicatorProps {
	variant?: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';
	color?: string;
	sx?: StyleProp;
	size?: number;
}

export default function LoadingIndicator({
	variant = 'medium',
	size,
	color,
	sx = {},
}: LoadingIndicatorProps): ReactElement {
	const internalSize = size || theme.space[variant];
	return (
		<Container sx={{ ...sx, position: 'relative', overflow: 'hidden' }} width={internalSize} height={internalSize}>
			{[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
				<IconInProgress
					key={i}
					width={internalSize}
					height={internalSize}
					color={color}
					sx={{
						// initial value
						opacity: '0.1',
						position: 'absolute',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						transform: `rotateZ(${-i * 45}deg)`,
						animationName: 'loading-indicator-opacity',
						animationIterationCount: 'infinite',
						animationTimingFunction: 'normal',
						animationDuration: '2.00s',
						animationDirection: 'normal',
						animationDelay: `${i * 0.25}s`, // 2s/8 parts

						'@keyframes loading-indicator-opacity': {
							'0%': {
								opacity: '0.1',
							},
							'50%': {
								opacity: '1',
							},
							'100%': {
								opacity: '0.1',
							},
						},
					}}
				/>
			))}
		</Container>
	);
}
