/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ExperimentStepExecutionActionVO } from 'ui-api';
import { Container, Text, Tooltip } from 'components';
import { IconBlastRadius } from 'components/icons';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import {
	getColors,
	getDarkerColorForState,
	getIconForState,
	isCanceled,
	isErrored,
	isFailed,
	isNotEnded,
	isSucceeded,
} from './utils';
import { ExperimentExecutionLogStepLine, isExperimentStepExecutionActionVO } from './experimentExecutionLog';
import ExperimentExecutionLogLine from './ExperimentExecutionLogLine';

interface ExperimentExecutionLogStepProps extends ExperimentExecutionLogStepLine {
	onClick: () => void;
	hoveredStepId: string | null;
	setHoveredStepId: (id: string | null) => void;
}

export default function ExperimentExecutionLogStep({
	time,
	state,
	step,
	onClick,
	hoveredStepId,
	setHoveredStepId,
}: ExperimentExecutionLogStepProps): ReactElement {
	const colors = isExperimentStepExecutionActionVO(step)
		? getColors(step.kind)
		: {
				backgroundColor: '#bec6da',
				backgroundImage: 'repeating-linear-gradient(120deg,transparent,transparent 4px,#aebad1 4px,#aebad1 8px)',
				color: 'neutral700',
			};

	const errored = isErrored(state);
	const failed = isFailed(state);
	const succeeded = isSucceeded(state);
	const canceled = isCanceled(state);
	const running = isNotEnded(state);
	const isAction = isExperimentStepExecutionActionVO(step);

	const isBold = !succeeded && !canceled;
	const Icon = getIconForState(state);
	const color =
		succeeded || canceled ? 'neutral700' : failed ? theme.colors.experimentWarning : getDarkerColorForState(state);

	const targetCount = isAction ? (step as ExperimentStepExecutionActionVO).targetExecutions.length : undefined;
	const erroredTargetExecutions = isAction
		? (step as ExperimentStepExecutionActionVO).targetExecutions.filter((t) => isErrored(t.state) || isFailed(t.state))
		: undefined;

	return (
		<ExperimentExecutionLogLine
			StateComponent={
				failed || errored ? (
					<Container
						sx={{
							display: 'flex',
							alignItems: 'center',
							width: 'fit-content',
							maxWidth: '100%',
							p: '4px 6px',
							borderRadius: '4px',
							background: color,
							color: 'neutral000',
						}}
					>
						<Icon variant="small" />
						{(erroredTargetExecutions?.length || 0) > 0 && (
							<Text ml="xxSmall" variant="smallStrong" sx={{ ...textEllipsis }}>
								{erroredTargetExecutions?.length}
							</Text>
						)}
					</Container>
				) : (
					<Icon variant="small" ml="xSmall" color={color} />
				)
			}
			TimeComponent={
				<Text variant={isBold ? 'smallStrong' : 'small'} color="neutral800" sx={{ fontVariantNumeric: 'tabular-nums' }}>
					{formatTime(new Date(time))}
				</Text>
			}
			onClick={onClick}
			onMouseEnter={() => setHoveredStepId(step.id)}
			onMouseLeave={() => setHoveredStepId(null)}
			sx={{
				border: running
					? '2px dashed ' + theme.colors.purple700
					: errored
						? '2px solid coral'
						: failed
							? '2px solid ' + theme.colors.experimentWarning
							: '2px solid transparent',
				backgroundColor:
					hoveredStepId === step.id
						? running
							? 'purple300'
							: errored
								? 'coral300'
								: failed
									? '#FDDEB5'
									: 'neutral300'
						: running
							? 'purple100'
							: errored
								? 'coral100'
								: failed
									? '#FEF4E6'
									: 'neutral100',
				cursor: 'pointer',
			}}
		>
			<Container display="flex" alignItems="center" justifyContent="space-between" width="100%">
				<Container
					sx={{
						width: 'fit-content',
						maxWidth: '100%',
						p: '4px 6px',
						borderRadius: '4px',
						...colors,
					}}
				>
					<Tooltip content={step.customLabel || step.name}>
						<Text variant="smallStrong" sx={{ ...textEllipsis }}>
							{step.customLabel || step.name}
						</Text>
					</Tooltip>
				</Container>

				{targetCount !== undefined && isAction && step.kind === 'ATTACK' && (
					<Container
						sx={{
							ml: 'xSmall',
							minWidth: 'fit-content',
							width: 'fit-content',
							p: '4px 8px',
							borderRadius: '4px',
							backgroundColor: 'neutral200',
						}}
					>
						<IconBlastRadius variant="small" mr="xxSmall" />
						<Text as="span" variant="smallStrong">
							{targetCount}
						</Text>
					</Container>
				)}
			</Container>
		</ExperimentExecutionLogLine>
	);
}
