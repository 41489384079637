/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { emptyTargetDefinition, getCategory, getInitialSort, TargetId, toTargetId } from 'targets/util';
import { useAttributeDefinitions } from 'attributes/useAttributeDefinitions';
import { useTargetTypesWithAdvice } from 'targets/useTargetTypesWithAdvice';
import TargetDetailsModal from 'targets/TargetDetails/TargetDetailsModal';
import { debouncedFetchTargets as fetchTargets } from 'targets/fetch';
import { GetTargetsPageResponse, TargetPredicateVO } from 'ui-api';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useTargetCounts } from 'targets/useTargetCounts';
import { ReactElement, useEffect, useMemo } from 'react';
import { Order, PageParams } from 'utils/hooks/usePage';
import { usePromise } from 'utils/hooks/usePromise';
import { Container } from 'components/Container';
import { useUrlState } from 'url/useUrlState';
import flatMap from 'lodash/flatMap';
import { Stack } from 'components';
import { groupBy } from 'lodash';
import { ampli } from 'ampli';

import { adviceParam, pageParam, queryParam, sortParam, targetIdParam, targetTypeParam } from './url';
import { createResult, loadingResult } from '../../utils/hooks/stream/result';
import { useTargetDefinitions } from '../useTargetDefinitions';
import { localeCompareIgnoreCase } from '../../utils/string';
import TargetsTableHeader from './TargetsTableHeader';
import { TargetsTable } from './TargetsTable';
import { getLabel } from '../../i18n/label';
import TableSidebar from './TableSidebar';

interface TableViewState {
	sort: Order[] | undefined;
	showAdvice: boolean;
	targetId?: TargetId;
	targetType: string;
	query: string;
	page: number;
}

interface TableViewProps {
	predicate: TargetPredicateVO;
	environmentId: string;
}

export function TableView({ environmentId, predicate }: TableViewProps): ReactElement {
	const targetCounts = useTargetCounts({ environmentId, predicate });
	const [urlState, getUrlWithState, updateUrlWithState] = useUrlState<TableViewState>([
		targetTypeParam(),
		targetIdParam,
		adviceParam,
		queryParam,
		pageParam,
		sortParam,
	]);
	const { targetType, targetId, query, page, showAdvice } = urlState;

	const targetDefinitions = useTargetDefinitions();
	const targetsByCategory = useMemo(() => {
		if (targetDefinitions.loading || targetCounts.loading) {
			return loadingResult;
		} else if (targetDefinitions.value && targetCounts.value) {
			const sortedTargetDefinitions =
				targetDefinitions.value
					.filter((t) => {
						const count = targetCounts.value[t.id];
						return count != null && count > 0;
					})
					.sort((a, b) => localeCompareIgnoreCase(getLabel(a.label), getLabel(b.label))) ?? [];
			return createResult(groupBy(sortedTargetDefinitions, getCategory));
		}
		return createResult(undefined);
	}, [targetDefinitions.value, targetCounts.value]);
	const targetDefinition = useMemo(
		() =>
			targetsByCategory.loading ||
			(!targetType && targetsByCategory?.value && Object.keys(targetsByCategory.value).length)
				? loadingResult
				: createResult(flatMap(targetsByCategory.value).find((definition) => definition.id === targetType)),
		[targetsByCategory, targetType],
	);
	const attributeDefinitions = useAttributeDefinitions();

	useEffect(() => {
		if (targetType) {
			return;
		}
		if (
			targetsByCategory.value &&
			targetCounts.value &&
			targetCounts.value.total > 0 &&
			//if no target type is set yet or the given target type doesn't exist, set it to the first target type of the first category
			(!targetType || !flatMap(targetsByCategory.value).find((definition) => definition.id === targetType))
		) {
			if (Object.keys(targetsByCategory.value).length === 0) {
				const sortedCounts = Object.keys(targetCounts.value)
					.filter((key) => key !== 'total')
					.sort((a, b) => targetCounts.value[b] - targetCounts.value[a]);
				if (sortedCounts.length > 0) {
					updateUrlWithState({ targetType: sortedCounts[0] });
				}
			} else {
				const firstCategory = Object.keys(targetsByCategory.value).reduce((a, b) =>
					localeCompareIgnoreCase(a, b) < 0 ? a : b,
				);
				const initialTargetType = targetsByCategory.value[firstCategory].reduce((a, b) =>
					localeCompareIgnoreCase(getLabel(a.label), getLabel(a.label)) < 0 ? a : b,
				);
				updateUrlWithState({ targetType: initialTargetType.id });
			}
		}
	}, [targetType, targetsByCategory]);

	const defaultSort = targetDefinition?.value ? getInitialSort(targetDefinition?.value) : [];
	const [stableSortId, sort] = useStableInstance(
		(urlState.sort ?? defaultSort).length === 0 ? defaultSort : (urlState.sort ?? defaultSort),
	);

	const resolvedTargetDefinition =
		targetDefinition.loading || !targetType
			? targetDefinition
			: targetDefinition.value
				? targetDefinition
				: createResult(emptyTargetDefinition(targetType));

	const fetchResult = usePromise(async () => {
		if (resolvedTargetDefinition.value) {
			return fetchTargets({
				additionalAttributes: showAdvice
					? ['advice.status.validation-needed', 'advice.status.action-needed', 'advice.status.implemented']
					: undefined,
				targetDefinition: resolvedTargetDefinition.value,
				page: new PageParams(page, 15, sort),
				environmentId,
				predicate,
				query,
			});
		}
		return new Promise<GetTargetsPageResponse>(() => {});
	}, [resolvedTargetDefinition.value?.id, environmentId, page, stableSortId, query, predicate]);

	useEffect(() => {
		ampli.landscapeTableViewed({
			url: window.location.href,
			target_type: targetType,
			landscape_table_show_advice: showAdvice,
		});
	}, [targetType, showAdvice]);

	const targetTypesWithAdvice = useTargetTypesWithAdvice(predicate);

	const targetCategories =
		targetsByCategory.loading || !targetCounts.value
			? undefined
			: targetsByCategory.value && Object.keys(targetsByCategory.value).length > 0
				? targetsByCategory.value
				: {};

	return (
		<Container
			display={'flex'}
			flexDirection="row"
			sx={{
				position: 'relative',
				overflow: 'hidden',
				height: '100%',
			}}
		>
			<TableSidebar
				targetCounts={targetCounts.value}
				targetsByCategory={targetCategories}
				getTargetTypeHref={(targetType) =>
					getUrlWithState({ targetType, targetId: null, page: 0, sort: [], query: '' })
				}
				activeTargetType={targetType}
				showAdvice={showAdvice}
				targetTypesWithAdvice={targetTypesWithAdvice}
				setShowAdvice={(showAdvice) => updateUrlWithState({ showAdvice })}
			/>

			<Stack px="xxLarge" py="xLarge" flexGrow={1} sx={{ overflowY: 'auto' }} size="medium">
				<TargetsTableHeader
					targetDefinition={resolvedTargetDefinition}
					fetchResult={fetchResult}
					query={query}
					onChangeQuery={(query) => updateUrlWithState({ query, page: 0 })}
				/>
				<TargetsTable
					targetDefinition={resolvedTargetDefinition}
					attributeDefinitions={attributeDefinitions}
					fetchResult={fetchResult}
					page={page}
					getPageHref={(page) => getUrlWithState({ page: page })}
					sort={sort}
					getSortHref={(sort) => getUrlWithState({ sort, page: 0 })}
					getTargetDetailHref={(target) => getUrlWithState({ targetId: toTargetId(target) })}
					query={query}
					showAdvice={showAdvice}
				/>
			</Stack>

			{targetId != null && (
				<TargetDetailsModal
					onClose={() => updateUrlWithState({ targetId: undefined })}
					environmentId={environmentId}
					defaultToAdvicePage={showAdvice}
					withAdvice={showAdvice}
					targetId={targetId}
				/>
			)}
		</Container>
	);
}
