/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useEffect, useMemo, useState } from 'react';
import { ExecutionLogEventVO } from 'ui-api';
import { useObservable } from 'react-use';

import { ExecutionLogType } from './executionLogsApi';
import { Services } from './services';

interface UseExecutionLogsParams {
	executionId: number;
	type: ExecutionLogType | string;
	id: string | string[] | undefined;
	liveUpdate?: boolean;
	selectedLogLevel?: string[];
	setAvailableLogLevel?: (logLevel: string[]) => void;
}
interface PaginatedResult {
	result: AsyncState<ExecutionLogEventVO[]>;
	loadMore: (() => void) | undefined;
}

export function useExecutionLogs({
	executionId,
	type,
	id,
	liveUpdate,
	setAvailableLogLevel,
	selectedLogLevel,
}: UseExecutionLogsParams): PaginatedResult {
	const limit = 200;
	const [numItems, setNumItems] = useState<number>(limit);
	const [observable, stop] = useMemo(
		() => Services.executionLogs.streamLogs(executionId, type, id),
		[executionId, type, id],
	);
	useEffect(() => {
		if (!liveUpdate) {
			stop();
		}
	}, [liveUpdate, stop]);

	const logEvents = useObservable(observable, undefined);
	useEffect(() => {
		if (setAvailableLogLevel) {
			setAvailableLogLevel(collectLogLevel(logEvents || []));
		}
	}, [logEvents]);

	const logResult = useMemo(() => {
		let page = logEvents || [];
		if (selectedLogLevel && selectedLogLevel.length > 0) {
			page = page.filter((log) => selectedLogLevel.includes(log.level));
		}
		return { value: page.slice(0, numItems), loading: !logEvents, canLoadMore: page.length > numItems };
	}, [logEvents, numItems, selectedLogLevel?.join(',')]);
	return {
		result: logResult,
		loadMore:
			logResult.canLoadMore && logEvents && logEvents.length > numItems
				? () => setNumItems(numItems + limit)
				: undefined,
	};
}

function collectLogLevel(logEvents: ExecutionLogEventVO[]): string[] {
	const logLevel = new Set<string>();
	logEvents.forEach((log) => logLevel.add(log.level));
	return Array.from(logLevel);
}
