/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps, Heading, Help } from 'components';
import { ReactElement } from 'react';

interface ExperimentRunCardProps extends Omit<ContainerProps, 'title'> {
	title: string | React.ReactNode;
	help?: React.ReactNode;
	additionalHeaderItems?: React.ReactNode;
}

export default function ExperimentRunCard({
	title,
	help,
	additionalHeaderItems,
	children,
	sx = {},
	...props
}: ExperimentRunCardProps): ReactElement {
	return (
		<Container
			display={'flex'}
			sx={{ boxShadow: 'applicationSmall', flexDirection: 'column', bg: 'neutral000', maxHeight: 352, ...sx }}
			{...props}
		>
			<Container
				display={'flex'}
				bg={'neutral500'}
				color={'neutral000'}
				sx={{
					flexDirection: 'row',
					borderBottom: '1px solid',
					borderColor: 'neutral200',
					borderRadius: '4px 4px 0px 0px',
					py: 'xSmall',
				}}
				alignItems={'center'}
			>
				<Heading variant={'xSmall'} sx={{ py: 'xSmall', px: 'small', mr: 'auto' }}>
					{title}
				</Heading>
				{additionalHeaderItems ? (
					<Container color={'neutral100'} sx={{ mr: 'xSmall' }}>
						{additionalHeaderItems}
					</Container>
				) : null}
				{help ? (
					<Container sx={{ mr: 'small', ':hover': { color: 'neutral800' } }}>
						<Help variant={'medium'}>{help}</Help>
					</Container>
				) : null}
			</Container>
			{children}
		</Container>
	);
}
