/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useContext, createContext } from 'react';
import { TemplatePreviewVO } from 'ui-api';

type TemplatesContextType = {
	templates: TemplatePreviewVO[] | undefined;
};

export const TemplatesContext = createContext<TemplatesContextType>({ templates: undefined });

const useTemplates = (): TemplatePreviewVO[] | undefined => {
	const { templates } = useContext(TemplatesContext);
	return templates;
};
export default useTemplates;
