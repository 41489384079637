/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { GetExperimentTemplatePreviewsPageResponse, TemplatePreviewsRequestVO, TemplatesSearchContextVO } from 'ui-api';
import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { createStableId } from 'utils/string';
import { useUrlState } from 'url/useUrlState';
import { useState } from 'react';

import { Services } from './services';

export default function useRefreshingTemplates({
	includeNonAvailable = false,
	includeHidden = false,
	searchContext,
	pathname,
	pageSize,
	teamId,
	page,
}: {
	searchContext: TemplatesSearchContextVO;
	includeNonAvailable?: boolean;
	includeHidden?: boolean;
	pageSize?: number;
	pathname: string;
	teamId?: string;
	page?: number;
}): DataStreamResult<GetExperimentTemplatePreviewsPageResponse> {
	const [{ tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, kindsParam, pageParam }] = useState(() =>
		createFilterParams(pathname),
	);

	const [{ targetTypes, actions, tags, freeTextPhrases, kinds, size, page: pageFromUrl }, , updateUrlState] =
		useUrlState<UrlState>([freeTextPhrasesParam, kindsParam, targetTypesParam, actionsParam, tagsParam, pageParam]);

	const [reloadSignal, setReloadSignal] = useState(0);
	const templatesResult = usePromise(() => {
		const body: TemplatePreviewsRequestVO = {
			includeNonAvailable,
			searchContext,
			includeHidden,

			pageSize: pageSize || size || 15,
			page: page ?? pageFromUrl,

			freeTextPhrases,
			targetTypes,
			actions,
			teamId,
			kinds,
			tags,
		};

		return Services.templatesApi.getTemplatePreviews(body);
	}, [
		reloadSignal,
		createStableId(freeTextPhrases),
		createStableId(targetTypes),
		createStableId(actions),
		createStableId(kinds),
		createStableId(tags),
		includeNonAvailable,
		pageSize || size,
		includeHidden,
		teamId,
		page ?? pageFromUrl,
	]);

	useEventEffect(
		() => {
			updateUrlState({ page: 0 });
			setReloadSignal((i) => i + 1);
		},
		['experiment.template.deleted', 'experiment.template.created'],
		() => {},
		[reloadSignal, updateUrlState],
	);
	useEventEffect(
		() => setReloadSignal((i) => i + 1),
		['experiment.template.updated'],
		() => {},
		[reloadSignal],
	);

	return templatesResult;
}
