/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage, Flex } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import { IconCheck, IconConnect } from 'components/icons';
import { createResult } from 'utils/hooks/stream/result';
import { Button, LoadingIndicator } from 'components';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { CreateHubRequest } from 'ui-api';
import { useFormikContext } from 'formik';

export default function TestConnectionButton(): ReactElement {
	const { values, errors } = useFormikContext<CreateHubRequest>();
	const { repositoryUrl } = values;

	const [testSignal, setTestSignal] = useState(0);
	const testResult = usePromise(
		() => (testSignal === 0 ? Promise.resolve(createResult(null)) : Services.hubApi.testConnection(repositoryUrl)),
		[testSignal],
	);

	const isTesting: boolean = testResult && testResult.loading && testSignal > 0;
	const testingErrors: string | undefined = testResult?.value?.error || testResult.error?.message;
	const testHasPassed = testResult.value && !testingErrors;
	useEffect(() => {
		if (testHasPassed) {
			const timeout = setTimeout(() => {
				setTestSignal(0);
			}, 2000);
			return () => clearTimeout(timeout);
		}
	}, [testHasPassed]);

	if (!repositoryUrl || errors.repositoryUrl) {
		return <div />;
	}

	return (
		<Flex spacing="large">
			{testingErrors && (
				<ErrorMessage type="medium" level="error" withIcon>
					{testingErrors}
				</ErrorMessage>
			)}

			<Button
				variant="chromelessSmall"
				disabled={isTesting}
				color={testHasPassed ? 'feedbackSuccess' : 'neutral600'}
				onClick={() => {
					if (!testHasPassed) {
						setTestSignal(testSignal + 1);
					}
				}}
			>
				{isTesting ? (
					<>
						<LoadingIndicator variant="small" sx={{ mr: 'xxSmall' }} />
						Testing connection…
					</>
				) : testHasPassed ? (
					<>
						<IconCheck variant="small" mr="xxSmall" />
						Test successful
					</>
				) : (
					<>
						<IconConnect variant="small" mr="xxSmall" />
						Test connection
					</>
				)}
			</Button>
		</Flex>
	);
}
