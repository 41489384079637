/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconDuplicate, IconReset, IconSaveDisc, IconTrash } from 'components/icons';
import { Button, Stack, Tooltip, Text, userConfirmV2, TextField } from 'components';
import { useLastVisitedView } from 'pages/landscape/hocs/lastVisitedView';
import DeleteViewModal from 'pages/customViews/modals/DeleteViewModal';
import { ReactElement, useEffect, useState } from 'react';
import { useTeam } from 'services/useTeam';
import { useFormikContext } from 'formik';
import { LandscapeViewVO } from 'ui-api';
import { useHistory } from 'url/hooks';
import { v4 as uuidv4 } from 'uuid';
import { ampli } from 'ampli';

import ActionModalHandler from '../ActionModalHandler';
import { LandscapeConfig } from '../types';
import { pathSegment } from '../urlParams';

interface ViewControlsProps {
	saveCopy: boolean;
}

export default function ViewControls({ saveCopy }: ViewControlsProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, groupBy, colorBy, sizeBy, environmentId } = formik.values;

	const [viewToDelete, setViewToDelete] = useState<LandscapeViewVO | null>(null);
	const { lastVisitedViewId, deleteLastViewedId } = useLastVisitedView();

	const { push, createHref, location } = useHistory();
	const formIsTouched = Object.keys(location.matrix[pathSegment]).length > 0;

	return (
		<>
			<ActionModalHandler />

			<Stack
				direction="horizontal"
				alignItems="center"
				justifyContent={formIsTouched && !formik.isSubmitting ? 'space-between' : 'flex-end'}
			>
				{!formik.values.isNew && (
					<>
						{viewToDelete && (
							<DeleteViewModal
								viewId={viewToDelete.id}
								viewName={viewToDelete.name}
								onClose={() => setViewToDelete(null)}
								onDeleteSuccess={() => {
									setViewToDelete(null);
									if (lastVisitedViewId === viewToDelete.id) {
										deleteLastViewedId();
									}
									push('/landscape/views');
								}}
							/>
						)}

						{!formik.values.template && (
							<ButtonWrapper onClick={() => setViewToDelete(formik.values)}>
								<Tooltip content="Delete changes">
									<div>
										<IconTrash variant="large" />
									</div>
								</Tooltip>
							</ButtonWrapper>
						)}

						{!saveCopy && (
							<ButtonWrapper
								onClick={async () => {
									await userConfirmV2({
										title: 'Duplicate view',
										message: ({ setDisabled }) => <NewViewNameContent setDisabled={setDisabled} />,
										actions: [
											{
												value: 'confirm',
												label: 'Duplicate',
												icon: <IconSaveDisc mr="xSmall" />,
												variant: 'primary',
												action: async () => {
													// eslint-disable-next-line @typescript-eslint/ban-ts-comment
													// @ts-ignore
													const newViewsName = document.getElementById('new-view-name-value')?.value;
													if (newViewsName) {
														const newId = uuidv4();
														formik.setFieldValue('id', newId);
														formik.setFieldValue('name', newViewsName);

														// Hack to wait for new value to be applied
														// Pending https://github.com/jaredpalmer/formik/issues/529
														await Promise.resolve();

														formik.submitForm();
													}
												},
											},
										],
										secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
										width: '700px',
									});
								}}
							>
								<Tooltip content="Duplicate view">
									<div>
										<IconDuplicate variant="large" />
									</div>
								</Tooltip>
							</ButtonWrapper>
						)}
					</>
				)}
				{formIsTouched && !formik.isSubmitting && (
					<ButtonWrapper
						onClick={() => {
							push(
								createHref((location) => {
									location.matrix = {};
								}),
							);

							const { latestView } = formik.values;
							formik.setFieldValue('name', latestView.name);
							formik.setFieldValue('description', latestView.description || '');
							formik.setFieldValue('filterQuery', latestView.filterQuery || '');
							formik.setFieldValue('groupBy', latestView.groupBy || []);
							formik.setFieldValue('sizeBy', latestView.sizeBy || '');
							formik.setFieldValue('colorBy', latestView.colorBy || '');
							formik.setFieldValue('environmentId', latestView.environmentId);
							formik.setFieldValue('colorConfig', latestView.colorConfig || null);
							formik.setFieldValue('environmentId', latestView.environmentId);
							formik.setFieldValue('groupConfigs', latestView.groupConfigs || []);
							formik.setFieldValue('colorOverrides', latestView.colorOverrides || null);

							ampli.landscapeExplorerResetted({
								url: window.location.href,
								environment_id: environmentId,
								landscape_explorer_colored_by: colorBy,
								landscape_explorer_filter_query: filterQuery,
								landscape_explorer_grouped_by: groupBy,
								landscape_explorer_sized_by: sizeBy,
							});
						}}
					>
						<Tooltip content="Discard changes">
							<span>
								<IconReset variant="large" />
							</span>
						</Tooltip>
					</ButtonWrapper>
				)}

				<SaveButton saveCopy={saveCopy} formIsTouched={formIsTouched} />
			</Stack>
		</>
	);
}

interface SaveButtonProps {
	saveCopy: boolean;
	formIsTouched: boolean;
}

function SaveButton({ saveCopy, formIsTouched }: SaveButtonProps): ReactElement | null {
	const formik = useFormikContext<LandscapeConfig>();

	const team = useTeam();
	if (!team.userIsTeamMember) {
		return (
			<Tooltip content={'You need to be a member of the Team to save this view.'}>
				<Button disabled variant="secondary" minWidth="70px">
					<Text variant="smallStrong" sx={{ whiteSpace: 'nowrap' }}>
						Save
					</Text>
				</Button>
			</Tooltip>
		);
	}

	if (saveCopy) {
		return (
			<>
				<ActionModalHandler />

				<Button
					variant="secondary"
					onClick={() => {
						if (!formik.isSubmitting) {
							formik.setFieldValue('action', 'create');
						}
					}}
					minWidth="fit-content"
					px="small"
				>
					<IconDuplicate variant="medium" mr="xSmall" ml="-xxSmall" />
					<Text variant="smallStrong" sx={{ whiteSpace: 'nowrap' }}>
						Save as new view
					</Text>
				</Button>
			</>
		);
	}

	if (formik.values.isNew) {
		if (!formIsTouched) {
			return null;
		}
		return (
			<Button
				variant="secondary"
				onClick={() => {
					if (!formik.isSubmitting) {
						formik.setFieldValue('action', 'create');
					}
				}}
				minWidth="70px"
			>
				<Text variant="smallStrong" sx={{ whiteSpace: 'nowrap' }}>
					Save
				</Text>
			</Button>
		);
	}

	return (
		<Button
			variant="secondary"
			onClick={() => {
				if (!formik.isSubmitting) {
					formik.setFieldValue('action', 'update');
					formik.submitForm();
				}
			}}
			minWidth="70px"
		>
			{!formik.isSubmitting ? 'Save' : 'Saving...'}
		</Button>
	);
}

interface ButtonWrapperProps {
	children: ReactElement;
	onClick: () => void;
}

function ButtonWrapper({ children, onClick }: ButtonWrapperProps): ReactElement {
	return (
		<Stack
			size="xxSmall"
			direction="horizontal"
			alignItems="center"
			onClick={onClick}
			sx={{
				minWidth: 'fit-content',

				color: 'neutral700',
				cursor: 'pointer',
				'&:hover': {
					color: 'slate',
				},
			}}
		>
			{children}
		</Stack>
	);
}

function NewViewNameContent({ setDisabled }: { setDisabled: (disabled: boolean) => void }): ReactElement {
	const [value, setValue] = useState<string>('');
	useEffect(() => {
		setDisabled(!value);
	}, [value]);

	return (
		<Stack>
			<Text color="neutral600">
				Save the view to share it with all your team members. By sharing the view&apos;s deep link, you can also make it
				accessible to people outside your team.
			</Text>
			<Stack size="xxSmall">
				<Text as="span" variant="mediumStrong" color="neutral800">
					Your view name
				</Text>
				<Stack direction="horizontal" size="none">
					<TextField
						id="new-view-name-value"
						value={value}
						onChange={(e) => setValue(e.target.value)}
						placeholder="Name"
					/>
				</Stack>
			</Stack>
		</Stack>
	);
}
