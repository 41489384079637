/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React, { ReactElement, ReactNode } from 'react';
import { Container, StyleProp } from 'components';

interface PillProps {
	id?: string;
	backgroundColor: string;
	backgroundColorOnHover?: string;
	color: string;
	colorOnHover?: string;
	children: string | number | ReactElement | ReactNode | Array<string | number | ReactElement | ReactNode>;
	onClick?: () => void;
	sx?: StyleProp | undefined;
}

const Pill = React.forwardRef<HTMLDivElement, PillProps>(
	({ id, backgroundColor, backgroundColorOnHover, color, colorOnHover, onClick, children, sx }: PillProps, ref) => {
		return (
			<Container
				id={id}
				display={'flex'}
				ref={ref}
				tx="pill"
				variant="medium"
				alignItems="center"
				bg={backgroundColor}
				color={color}
				onClick={onClick}
				sx={{
					justifyContent: 'center',
					cursor: onClick ? 'pointer' : 'auto',
					display: 'flex',
					textAlign: 'center',
					lineHeight: 1,
					whiteSpace: 'nowrap',
					':hover': {
						backgroundColor: onClick ? backgroundColorOnHover || backgroundColor : backgroundColor,
						color: onClick ? colorOnHover || color : color,
					},
					...sx,
				}}
			>
				{children}
			</Container>
		);
	},
);
Pill.displayName = 'Pill';

export default Pill;
