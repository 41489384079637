/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, Li, Ul, Text, Checkbox, Tooltip } from 'components';
import { ReactElement } from 'react';

interface Item {
	value: string;
	label: string;
}

interface MultiSelectListProps {
	selectedItemValues: string[];
	items: Item[];
	selectItem: (item: Item) => void;
	removeSelectedItem: (item: Item) => void;
	maxSelectedItems?: number;
	maxSelectedItemsTooltip?: string;
	width?: number | string;
}

export default function MultiSelectList({
	selectedItemValues,
	items,
	selectItem,
	removeSelectedItem,
	maxSelectedItems,
	maxSelectedItemsTooltip,
	width,
}: MultiSelectListProps): ReactElement {
	const maxAllowedItems: number = maxSelectedItems ?? Infinity;
	const selectedItemValuesSet = new Set(selectedItemValues);

	return (
		<Container width={width}>
			<Ul>
				{items.map((item) => {
					const isSelected = selectedItemValuesSet.has(item.value);
					const disabled = !isSelected && selectedItemValues.length >= maxAllowedItems;
					return (
						<Tooltip key={item.value} content={disabled ? maxSelectedItemsTooltip : ''}>
							<Li
								sx={{
									display: 'flex',
									alignItems: 'center',
									py: 'xSmall',
									px: 'small',
									backgroundColor: isSelected ? 'neutral100' : 'white',
									cursor: disabled ? 'default' : 'pointer',
									'&:hover': {
										backgroundColor: disabled ? 'white' : isSelected ? 'neutral200' : 'neutral100',
									},
									borderBottom: '1px solid white',
								}}
								onClick={() => {
									if (!disabled) {
										if (isSelected) {
											removeSelectedItem(item);
										} else {
											selectItem(item);
										}
									}
								}}
							>
								<Checkbox
									checked={isSelected}
									disabled={disabled}
									onChange={() => (isSelected ? removeSelectedItem(item) : selectItem(item))}
									mr="xSmall"
								/>
								<Text variant="smallStrong" color={disabled ? 'neutral500' : 'neutral800'}>
									{item.label}
								</Text>
							</Li>
						</Tooltip>
					);
				})}
			</Ul>
		</Container>
	);
}
