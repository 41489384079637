/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import EnvVariables from 'components/EnvVariables/EnvVariables';
import { FieldHookConfig, useField } from 'formik';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';

interface VariablesProps {
	onSelect: (variableKey: string) => void;
	width?: string | number;
}

export default function Variables({ onSelect, width }: VariablesProps): ReactElement | null {
	const [variablesField] = useField({ name: 'variables' } as FieldHookConfig<VariableVO[]>);
	const variables: VariableVO[] = variablesField.value || [];

	if (variables.length === 0) {
		return null;
	}

	return (
		<DropdownContentFrame maxWidth={width} sx={{ minWidth: width }}>
			<EnvVariables variables={variables} onVariableClick={(key) => onSelect(`{{${key}}}`)} withHelpText={false} />
		</DropdownContentFrame>
	);
}
