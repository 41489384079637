/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Button, FormikCheckbox, Link, Snackbar, Stack, Text } from 'components';
import { CurrentUserVO, UpdateCurrentUserRequest } from 'ui-api';
import { lazy, ReactElement, Suspense, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { ValidationError } from 'utils/error';
import { IconNewTab } from 'components/icons';
import { Services } from 'services/services';

const TosImage = lazy(() => import('./TosImage'));

type AccountSettingsFormValues = UpdateCurrentUserRequest;

interface TermsOfServicesDialogContentProps {
	user: CurrentUserVO;
}

export default function TermsOfServicesDialogContent({ user }: TermsOfServicesDialogContentProps): ReactElement {
	const [submitError, setSubmitError] = useState<Error | null>();

	const initial: AccountSettingsFormValues = user;

	const handleSubmit = async (
		{ ...values }: AccountSettingsFormValues,
		form: FormikHelpers<AccountSettingsFormValues>,
	): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.users.updateCurrentUser({
				...values,
				termsAccepted: true,
			});
			Snackbar.dark('User updated.', { toastId: 'user-updated' });

			return new Promise((resolve) => {
				const prev = window.onload;
				window.onload = () => {
					resolve();
					window.onload = prev;
				};
				window.location.reload();
			});
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	const previousTermsAccepted = user.previousTermsAccepted;

	return (
		<Formik initialValues={initial} enableReinitialize onSubmit={handleSubmit}>
			{({ isSubmitting, values }) => (
				<Form noValidate>
					<Stack size="xxLarge" mx="xLarge" my="large" alignItems="center">
						<Suspense fallback={<>loading</>}>
							<TosImage />
						</Suspense>
						<Stack size="xLarge" alignItems="center">
							<Stack size="medium" alignItems="center">
								<Stack size="xSmall" alignItems="center">
									<Text
										sx={{
											fontSize: 24,
											fontWeight: 700,
											color: 'neutral000',
											textAlign: 'center',
										}}
									>
										Our Data Privacy Terms have just changed.
									</Text>
									<Text variant="medium" color="neutral000" textAlign="center">
										You need to accept them in order to continue using Steadybit.
									</Text>
								</Stack>
								<FormikCheckbox
									mr="small"
									name="termsAccepted"
									label={
										<Text variant="medium" color="neutral000">
											I accept the Data Privacy Terms
										</Text>
									}
								/>
							</Stack>

							<Stack>
								{values.termsAccepted && (
									<Stack size="xSmall">
										<Button
											variant={'primaryLarge'}
											width={'100%'}
											mx={'auto'}
											type={'submit'}
											disabled={!values.termsAccepted}
											loading={isSubmitting}
										>
											Save
										</Button>
										{submitError ? <Text color={'coral'}> {submitError.toString()}</Text> : null}
									</Stack>
								)}
								<Stack direction="horizontal" size="xSmall" alignItems="center">
									<IconNewTab variant="small" color="purple400" />
									<Link href="https://www.steadybit.com/imprint-and-legal" external color="purple400">
										<Text variant="smallStrong">
											Read the {previousTermsAccepted ? 'new' : ''} Data Privacy Terms here
										</Text>
									</Link>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
	);
}
