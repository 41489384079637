/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, ExperimentStepActionVO, TargetTypeDescriptionVO, TemplateVO } from 'ui-api';
import { EditorSettingsContext } from 'pages/experimentsV2/useEditorSettings';
import TemplateFileModal from 'pages/templates/components/TemplateFileModal';
import ExperimentPreview from 'pages/templates/components/ExperimentPreview';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { ReactElement, useMemo, useState } from 'react';
import { Button, Stack, Tooltip } from 'components';
import Markdown from 'components/Markdown/Markdown';
import { IconSaveFile } from 'components/icons';
import { Form, Formik } from 'formik';

import Headline from './Headline';
import Actions from './Actions';
import Targets from './Targets';
import Tags from './Tags';

interface TemplateDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	template: TemplateVO;
	actions: ActionVO[];
	onEditClick?: (template: TemplateVO) => void;
	onUseClick?: (template: TemplateVO) => void;
}

export default function TemplateDetails({
	targetDefinitions,
	template,
	actions,
	onEditClick,
	onUseClick,
}: TemplateDetailsProps): ReactElement {
	const [allActions, allTargets] = useMemo(() => getActionsAndTargets(template), [template.id]);
	const [showDownloadModal, setShowDownloadModal] = useState(false);

	const permissions = useGlobalPermissions();
	const canUseTemplate = permissions.templates.canUse;

	return (
		<Stack size="xLarge">
			{showDownloadModal && <TemplateFileModal template={template} onClose={() => setShowDownloadModal(false)} />}

			<Stack size="large">
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 180px',
						gap: '6px',
					}}
				>
					<Headline title={template.templateTitle} />
					<Stack size="xSmall" alignItems="flex-end">
						<Button variant="onboardingNav" color="neutral600" onClick={() => setShowDownloadModal(true)}>
							<IconSaveFile mr="xSmall" />
							Download template
						</Button>
						{onUseClick && (
							<Tooltip
								content={!canUseTemplate ? 'You need to be a member of the Team to use this template.' : undefined}
							>
								<Button onClick={() => onUseClick(template)} disabled={!canUseTemplate}>
									Use this template
								</Button>
							</Tooltip>
						)}
						{onEditClick && (
							<Tooltip content="Editing a template doesn't change the experiments created">
								<Button onClick={() => onEditClick(template)}>Edit template</Button>
							</Tooltip>
						)}
					</Stack>
				</div>
				<Formik
					initialValues={{ lanes: template.lanes }}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={() => {}}
				>
					<Form key={template.id} noValidate>
						<EditorSettingsContext.Provider value={{ mode: 'templatePreview' }}>
							<ExperimentPreview key={template.id} lanes={template.lanes} actions={actions} />
						</EditorSettingsContext.Provider>
					</Form>
				</Formik>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 280px',
						gap: '24px',
					}}
				>
					<Markdown content={template.templateDescription} />
					{((template.tags && template.tags.length > 0) || allTargets.length > 0 || allActions.length > 0) && (
						<Stack
							size="large"
							sx={{
								p: 'small',
								backgroundColor: 'neutral100',
								borderRadius: '8px',
							}}
						>
							<Tags tags={template.tags} />
							<Targets targets={allTargets} targetDefinitions={targetDefinitions} />
							<Actions actions={allActions} actionDefinitions={actions} />
						</Stack>
					)}
				</div>
			</Stack>
		</Stack>
	);
}

export function getActionsAndTargets(template: TemplateVO): [string[], string[]] {
	const allActions = new Set<string>();
	const allTargets = new Set<string>();

	for (let iL = 0; iL < template.lanes.length; iL++) {
		const lane = template.lanes[iL];
		for (let iS = 0; iS < lane.steps.length; iS++) {
			const step = lane.steps[iS];
			if (step.type === 'action') {
				const action = step as ExperimentStepActionVO;
				allActions.add(action.actionId);
				if (action.blastRadius?.targetType) {
					allTargets.add(action.blastRadius?.targetType);
				}
			}
		}
	}
	return [Array.from(allActions), Array.from(allTargets)];
}
