/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Divider, SettingsGroup, SettingsGroupProps, Stack } from 'components';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import React, { ReactNode, useMemo } from 'react';
import { partition, sortBy } from 'lodash';
import { FieldVO } from 'ui-api';

import { SettingsGroupFieldVOItem } from './SettingsGroupFieldVOItem';

type SettingsGroupsFieldVOProps = Omit<SettingsGroupProps, 'hidden'> & {
	fields?: FieldVO[];
	name: string;
	active?: boolean;
	autoFocus?: boolean;
	showDescriptionAsTooltip?: boolean;
	additionalComponents?: ReactNode;
};

function orderAndPartitionFields(fields: FieldVO[]): [FieldVO[], FieldVO[]] {
	const ordered = sortBy(fields, ['order']);
	return partition(ordered, (f) => !f.advanced);
}

const SettingsGroupFieldVO: React.VFC<SettingsGroupsFieldVOProps> = ({
	fields = [],
	active = true,
	autoFocus = false,
	name,
	disabled,
	additionalComponents,
	width,
	showDescriptionAsTooltip,
	...props
}) => {
	const [normalFields, advancedFields] = useMemo(() => orderAndPartitionFields(fields), [fields]);

	return (
		<SettingsGroup name={name} hidden={!active} {...props}>
			{normalFields
				.filter((field) => !field.hidden)
				.map((field, i) => (
					<SettingsGroupFieldVOItem
						key={i}
						field={field}
						name={`${name}['${field.name}']`}
						disabled={disabled ?? field.disabled}
						width={width}
						showDescriptionAsTooltip={showDescriptionAsTooltip}
						autoFocus={autoFocus && i === 0}
						px="0"
					/>
				))}

			{advancedFields.length > 0 && (
				<Collapsible title="Additional Settings" contentPadding="small">
					<SettingsGroup name={name} sx={{ borderWidth: '0px 0px 0px' }} variant={props.variant}>
						{advancedFields.map((field, i) => (
							<SettingsGroupFieldVOItem
								key={i}
								field={field}
								name={`${name}['${field.name}']`}
								disabled={disabled}
								width={width}
								showDescriptionAsTooltip={showDescriptionAsTooltip}
								sx={{ px: 'small' }}
							/>
						))}
					</SettingsGroup>
				</Collapsible>
			)}

			{additionalComponents && (
				<Stack mt="small">
					<Divider />
					{additionalComponents}
				</Stack>
			)}
		</SettingsGroup>
	);
};
export default SettingsGroupFieldVO;
