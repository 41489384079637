/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, ModalOverlay, Spinner, Stack, Text } from 'components';
import ExperimentsWorkspace from 'pages/experiments/ExperimentsWorkspace';
import { directionParam, sortParam } from 'pages/experiments/urlParams';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Redirect, Route, Switch } from 'url/router';
import { PageParams } from 'utils/hooks/usePage';
import { LocationWithUrlParams } from 'url/type';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useHistory } from 'url/hooks';
import { Helmet } from 'react-helmet';
import { ReactElement } from 'react';
import { DirectionVO } from 'ui-api';

import FromTemplateModal from '../templates/FromTemplateModal/FromTemplateModal';
import ExperimentExecutionLoader from './ExperimentExecutionLoader';
import CreateExperimentModal from './create/CreateExperimentModal';
import ExperimentEditorLoader from './ExperimentEditorLoader';
import ExperimentExecutions from './ExperimentExecutions';
import ExperimentEditor from './ExperimentEditor';
import ExperimentHeader from './ExperimentHeader';

export default function Experiments(): ReactElement {
	// this is a workaround to handle the sort parameter in case somebody calls the product from an old url
	const history = useHistory();
	const [{ sort }, getUrlWithState] = useUrlState([sortParam, directionParam]);
	if (Array.isArray(sort)) {
		const sortKey = sort[0];
		const sortDirection: DirectionVO = sort[1] === 'asc' ? 'ASC' : 'DESC';
		history.replace(getUrlWithState({ sort: sortKey, direction: sortDirection }));
		return <></>;
	}

	return (
		<Container
			as="main"
			sx={{
				gridArea: 'main',
				position: 'relative',
				overflow: 'hidden',
			}}
		>
			<Switch>
				<Route path="/experimentruns">{() => <ExperimentsWorkspace activeView="experimentruns" />}</Route>

				<Route path="/experiments/edit/<new>/fromlandscape/:environmentId">
					{({ match }) => <FromLandscapeRedirector environmentId={match?.params.environmentId ?? ''} />}
				</Route>

				<Route path="/experiments/edit/<new>/design">
					{({ location }) => (
						<>
							<Helmet title={'Experiments / <new>'} />
							<ExperimentEditorLoader experimentKeyCopy={(location as LocationWithUrlParams<unknown>).query.copy}>
								<ExperimentEditor />
							</ExperimentEditorLoader>
						</>
					)}
				</Route>
				<Route path="/experiments/edit/<new>">{() => <Redirect to="/experiments/edit/<new>/design" />}</Route>

				<Route path="/experiments/edit/:experimentKey/executions/:experimentExecutionId?/:experimentExecutionSection?">
					{({ match }) => {
						const { experimentKey, experimentExecutionId, experimentExecutionSection } = match?.params ?? {};

						if (!experimentKey) {
							return <Redirect to="/experiments" />;
						}
						if (!experimentExecutionId) {
							return (
								<ExperimentEditorLoader experimentKey={experimentKey}>
									<RedirectToLastRun experimentKey={experimentKey} />
								</ExperimentEditorLoader>
							);
						}
						if (!experimentExecutionSection) {
							return <Redirect to={`/experiments/edit/${experimentKey}/executions/${experimentExecutionId}/attack`} />;
						}

						return (
							<>
								<Helmet>
									<title>
										Experiments / {experimentKey} / {experimentExecutionId}
									</title>
								</Helmet>
								<ExperimentEditorLoader experimentKey={experimentKey}>
									<ExperimentExecutionLoader experimentExecutionId={experimentExecutionId}>
										{({ experimentExecution }) => (
											<ExperimentExecutions
												experimentExecutionSection={experimentExecutionSection}
												experimentExecutionId={Number.parseInt(experimentExecutionId, 10)}
												experimentExecution={experimentExecution}
												experimentKey={experimentKey}
											/>
										)}
									</ExperimentExecutionLoader>
								</ExperimentEditorLoader>
							</>
						);
					}}
				</Route>

				<Route path="/experiments/edit/:experimentKey/design">
					{({ match }) => {
						const { experimentKey } = match?.params ?? {};
						return (
							<>
								<Helmet>
									<title>Experiments / {experimentKey}</title>
								</Helmet>
								<ExperimentEditorLoader experimentKey={experimentKey}>
									<ExperimentExecutionLoader experimentKey={experimentKey}>
										{({ experimentExecution }) => <ExperimentEditor experimentExecution={experimentExecution.value} />}
									</ExperimentExecutionLoader>
								</ExperimentEditorLoader>
							</>
						);
					}}
				</Route>
				<Route path="/experiments/edit/:experimentKey">
					{({ match }) => <Redirect to={`/experiments/edit/${match?.params.experimentKey}/design`} />}
				</Route>

				<Route path="/experiments/scheduledOnce">{() => <ExperimentsWorkspace activeView="scheduledOnce" />}</Route>
				<Route path="/experiments/scheduledRecurrent">
					{() => <ExperimentsWorkspace activeView="scheduledRecurrent" />}
				</Route>

				{/* the default route if nothing matches */}
				<Route>
					<ExperimentsWorkspace activeView="experiments" />

					<Switch>
						<Route path="/experiments/start">{({ match }) => <ExperimentStartWrapper isOpen={Boolean(match)} />}</Route>

						<Route path="/experiments/from_templates">
							{() => {
								return (
									<>
										<Helmet title={'Experiments / New from Template'} />
										<FromTemplateModal />
									</>
								);
							}}
						</Route>
					</Switch>
				</Route>
			</Switch>
		</Container>
	);
}

function FromLandscapeRedirector({ environmentId }: { environmentId: string }): ReactElement {
	const history = useHistory();
	const team = useTeam();

	// this is an entry point from the landscape page modal. Since the modal will redirect to here in a new tab, we need
	// to make sure, the location state is set correctly, so that the experiment editor can pick it up (and will not redirect back to the experiments list page).
	history.push('/experiments/edit/<new>/design', {
		preparedFormData: {
			actions: ['save'],
			initialValues: {
				externalId: '',
				hypothesis: '',
				templateId: '',
				name: '',
				teamId: team.id,
				environmentId,
				lanes: [{ id: '0', steps: [] }],
				tags: [],
				webhookIds: [],
				variables: [],
				creationMethod: 'UI_FROM_LANDSCAPE',
			},
			initialErrors: {},
		},
	});
	return <></>;
}

function ExperimentStartWrapper({ isOpen }: { isOpen: boolean }): ReactElement {
	const history = useHistory();
	const backToList = (): void => history.push('/experiments');

	return (
		<ModalOverlay open={isOpen} onClose={backToList}>
			<CreateExperimentModal onClose={backToList} />
		</ModalOverlay>
	);
}

function RedirectToLastRun({ experimentKey }: { experimentKey: string }): ReactElement | null {
	const history = useHistory();
	const [experimentExecutions] = useAsyncState(
		() => Services.experiments.fetchExperimentRuns(new URLSearchParams(), new PageParams(0, 1), experimentKey),
		[experimentKey],
	);

	if (experimentExecutions.loading) {
		return (
			<Stack justifyContent={'center'} alignItems={'center'} mt={'large'}>
				<Spinner variant="xLarge" color="neutral300" />
			</Stack>
		);
	}

	if (experimentExecutions.value?.totalElements === 0 || experimentKey === '<new>') {
		return (
			<Stack size="none">
				<ExperimentHeader section="executions" />
				<Container display={'flex'} sx={{ flex: '1 1 auto', alignItems: 'center', justifyContent: 'center' }}>
					<Text variant={'xLarge'} muted>
						This experiment has not yet run.
					</Text>
				</Container>
			</Stack>
		);
	}

	history.replace(
		`/experiments/edit/${experimentKey}/executions/${experimentExecutions.value?.content[0]?.id}/attack${location.search}`,
	);

	return null;
}
