/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconAdviceActionNeeded, IconAdviceImplemented, IconAdviceValidation } from 'components/icons';
import { AdviceStatusVO } from 'ui-api';
import { Container } from 'components';
import { ReactElement } from 'react';

interface AdviceIconWrapperProps {
	variant?: 'small' | 'xSmall';
	status: AdviceStatusVO;
}

export default function AdviceIconWrapper({ status, variant = 'small' }: AdviceIconWrapperProps): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: variant === 'xSmall' ? 20 : 32,
				height: variant === 'xSmall' ? 20 : 32,

				bg:
					status === 'ACTION_NEEDED'
						? 'coral'
						: status === 'VALIDATION_NEEDED'
							? 'experimentWarning'
							: 'feedbackSuccess',
				borderRadius: '50%',
			}}
		>
			<AdviceIcon state={status} variant={variant} />
		</Container>
	);
}
interface AdviceIconProps {
	state: AdviceStatusVO;
	variant?: 'small' | 'xSmall';
}

export function AdviceIcon({ state, variant = 'small' }: AdviceIconProps): ReactElement {
	if (state === 'ACTION_NEEDED') {
		return <IconAdviceActionNeeded variant={variant} color="neutral000" />;
	}
	if (state === 'VALIDATION_NEEDED') {
		return <IconAdviceValidation variant={variant} color="neutral000" />;
	}
	return <IconAdviceImplemented variant={variant} color="neutral000" />;
}
