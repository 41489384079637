/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Box, Colors, Flex, Spacings, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';

import { IconChevronDown, IconChevronUp, IconClose } from '../../../icons';
import DropdownContentFrame from './components/DropdownContentFrame';
import Skeletons from '../../../Skeleton/Skeletons';
import { theme } from '../../../../styles.v2/theme';
import DropdownInput from '../DropdownInput';

interface FilterDropDownProps {
	placeholder: string;
	loading: boolean;
	label: string;
	name: string;
	children: (p: {
		width: string | number | undefined;
		value: string;
		selectItem: (value: string) => void;
	}) => ReactElement;
	clearAll?: () => void;
}

export function FilterDropDown({
	name,
	label,
	placeholder,
	loading,
	clearAll,
	children,
}: FilterDropDownProps): ReactElement {
	const [groupFilter, setGroupFilter] = useState<string | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const labelIsSet = !!label;

	const Chevron = isOpen ? IconChevronUp : IconChevronDown;

	let content;
	if (loading) {
		content = (
			<Box
				align="center"
				style={{
					width: 'calc(100% - 17px)',
					height: '37px',
					px: 'xSmall',
					border: '1px solid ' + Colors.neutral300,
					borderRadius: 'xxSmall',
				}}
			>
				<Skeletons height={24} widths={[100]} />
			</Box>
		);
	} else {
		content = (
			<DropdownInput
				placeholder={placeholder}
				placement="bottom-start"
				width="100%"
				value={isOpen ? groupFilter || '' : label || ''}
				onValueChanged={(v) => setGroupFilter(v || null)}
				onOpen={() => {
					setGroupFilter(null);
					setIsOpen(true);
				}}
				onClose={() => setIsOpen(false)}
				sx={{
					bg: labelIsSet && !isOpen ? 'purple100' : 'neutral000',
					borderColor: labelIsSet ? 'purple300' : 'neutral300',
					color: labelIsSet ? theme.colors.slate + ' !important' : 'neutral800',
					fontWeight: labelIsSet ? '500' : 'normal',
					paddingRight: '26px',
				}}
				iconRight={
					label && !isOpen && clearAll ? (
						<IconClose
							variant="xSmall"
							onClick={() => {
								clearAll();
								setGroupFilter(null);
							}}
							sx={{
								mt: '6px',
								mr: '6px',
								bg: labelIsSet ? 'purple100' : 'neutral000',
								cursor: 'pointer',
								color: 'neutral800',
								'&:hover': {
									color: 'neutral800',
								},
							}}
						/>
					) : (
						<Chevron variant="xSmall" mr="xSmall" mt="xxSmall" />
					)
				}
			>
				{({ width, selectItem }) => (
					<DropdownContentFrame maxHeight={600}>
						{children({ value: groupFilter || '', width, selectItem })}
					</DropdownContentFrame>
				)}
			</DropdownInput>
		);
	}

	return (
		<Flex spacing="xxSmall" style={{ width: '100%' }}>
			<Text type="small" style={{ color: Colors.neutral600 }}>
				{name}
			</Text>
			{content}
		</Flex>
	);
}

export function FilterDropDownListWrapper({
	width,
	children,
}: {
	width: string | number | undefined;
	children: ReactElement;
}): ReactElement {
	return (
		<Flex
			style={{
				minWidth: width,
				py: 'xSmall',
			}}
		>
			<Box
				style={{
					width: 'calc(100% - 32px)',
					marginLeft: Spacings.small,
					marginRight: Spacings.small,
				}}
			>
				{children}
			</Box>
		</Flex>
	);
}
