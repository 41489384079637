/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentSaveResult } from 'services/experimentApi';
import { userInput } from 'components/Dialog/UserInput';
import { suppressSubmitOnEnter } from 'utils/form';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { Form, Formik } from 'formik';
import { Snackbar } from 'components';

import { PreparedFormData } from './ExperimentEditorLoader';
import ValidationHandler from './ValidationHandler';
import { ExperimentFormValues } from './types';

interface ExperimentEditorFormHandlerProps {
	reloadExperiment: () => void;
	formData: PreparedFormData;
	children: ReactNode;
}

export default function ExperimentEditorFormHandler({
	reloadExperiment,
	formData,
	children,
}: ExperimentEditorFormHandlerProps): ReactElement | null {
	const history = useHistory();

	return (
		<Formik
			initialValues={{ ...formData, ...formData.initialValues }}
			initialErrors={formData.initialErrors}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			onSubmit={async (values) => {
				const result = await saveExperiment(values);
				if (result === null) {
					return;
				}

				const [, saveResult] = result;
				if (saveResult && saveResult.created) {
					history.replace(
						`/experiments/edit/${saveResult && saveResult.experimentKey}/design`,
						'redirect_after_initial_save',
					);
				} else {
					reloadExperiment();
				}
			}}
		>
			{({ values }) => (
				<Form key={values.version} onKeyDown={suppressSubmitOnEnter} noValidate>
					<ValidationHandler />
					{children}
				</Form>
			)}
		</Formik>
	);
}

export async function saveExperiment(
	values: ExperimentFormValues,
): Promise<[ExperimentFormValues, ExperimentSaveResult | null] | null> {
	const newValuesOrNull = await provideNameIfNotExist(values);

	if (newValuesOrNull === null) {
		return null;
	}

	values = newValuesOrNull;
	if (values.environmentId) {
		await Services.environments.updateEnvironmentVariables(values.environmentId, { variables: values.variables });
	}
	try {
		const result = await Services.experiments.createOrUpdateExperiment(values);
		Snackbar.dark(`Experiment ${result.experimentKey} saved.`, {
			toastId: 'experiment-saved',
		});
		return [values, result];
	} catch {
		console.error('Could not save experiment', { experimentKey: values.experimentKey });
		return [values, null];
	}
}

export async function provideNameIfNotExist(values: ExperimentFormValues): Promise<ExperimentFormValues | null> {
	if (!values.name) {
		const inputResult = await userInput({
			title: 'Name your experiment',
			placeholder: 'Untitled Experiment',
			buttonOkCaption: 'Save Experiment',
		});
		if (inputResult.action === 'cancel') {
			return null;
		}
		values = {
			...values,
			name: inputResult.input || '',
		};
	}

	return values;
}
