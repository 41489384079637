/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Link, Stack, Text } from 'components';
import { IconArrowRight } from 'components/icons';
import { ReactElement } from 'react';
import { Brand } from 'images';

import { ReactComponent as ReliabilityHubSvg } from '../images/reliabilityHub.svg';
import extensionsImage from '../images/extensions.png';
import { ampli } from '../../../ampli';

export default function ExtensionsWidget(): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="none"
			sx={{
				justifyContent: 'space-between',
				width: 470,
				height: 220,
				backgroundColor: 'neutral000',
				py: 'medium',
				pl: 'medium',
				overflow: 'hidden',
			}}
		>
			<Container display="flex" flexDirection="column" justifyContent="space-between">
				<Stack direction="vertical" size="xSmall">
					<Stack size="none">
						<Brand color="#0F054C" width={72} />
						<ReliabilityHubSvg width={107} height={14} />
					</Stack>
					<Text variant="largeStrong" color="neutral800" width={210} lineHeight="22px">
						Open Source Extensions
					</Text>
					<Text variant="small" color="neutral700" lineHeight="18px">
						Expand Steadybit&apos;s technology support by installing additional extensions.
					</Text>
				</Stack>
				<Link
					href="https://hub.steadybit.com/extensions?utm_campaign=extensions&utm_source=platform-dashboard&utm_medium=button&utm_content=suggested-extensions"
					onClick={() => ampli.dashboardHeroTriggered({ dashboard_hero_action: 'install_extensions' })}
					target="_blank"
					rel="noopener"
				>
					<Button variant="secondary">
						Install Extensions <IconArrowRight ml="xSmall" />
					</Button>
				</Link>
			</Container>

			<img src={extensionsImage} style={{ width: 194, height: 153, marginTop: 18, marginRight: -7 }} />
		</Stack>
	);
}
