/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { EnvironmentSummaryVO } from 'ui-api';
import { Services } from 'services/services';
import { ReactElement } from 'react';

import TemplatePlaceholder, { Placeholder } from './TemplatePlaceholder';
import EnvironmentVariables from './EnvironmentVariables';

interface PagesProps {
	selectedEnvironment: EnvironmentSummaryVO | undefined;
	environments: EnvironmentSummaryVO[];
	hasEnvironmentPage: boolean;
	placeholder: Placeholder;
	page: number;
}

export default function Pages({
	selectedEnvironment,
	hasEnvironmentPage,
	environments,
	placeholder,
	page,
}: PagesProps): ReactElement | null {
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);

	if (page === 0 && hasEnvironmentPage) {
		return (
			<EnvironmentVariables
				selectedEnvironment={selectedEnvironment}
				actionsResult={actionsResult}
				environments={environments}
			/>
		);
	}

	if (!placeholder) {
		return null;
	}

	return <TemplatePlaceholder key={placeholder.key} placeholder={placeholder} actionsResult={actionsResult} />;
}
