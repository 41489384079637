/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEffect } from 'react';

export function useRefreshIntercept(when: boolean): void {
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
			if (when) {
				event.preventDefault();
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [when]);
}
