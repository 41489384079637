/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React from 'react';

import { IconCheck } from '../icons';
import { Text } from '../Text';

interface SelectOptionProps extends ContainerProps {
	selected?: boolean;
	focused?: boolean;
	disabled?: boolean;
}

export const SelectOption = React.forwardRef<HTMLDivElement, SelectOptionProps>(
	({ focused = false, selected = false, disabled = false, children, ...props }, ref) => {
		const dataTag = selected ? { 'data-select-option-text-selected': true } : { 'data-select-option-text': true };
		return (
			<Container
				display={'flex'}
				as="li"
				ref={ref}
				minHeight={32}
				px={12}
				justifyContent="space-between"
				alignItems="center"
				sx={{
					bg: focused ? 'neutral100' : 'neutral000',
					listStyle: 'none',
					cursor: disabled ? 'default' : 'pointer',
				}}
				{...props}
			>
				<Text
					color={disabled ? 'neutral600' : 'neutral800'}
					width="100%"
					sx={{
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}
					{...dataTag}
				>
					{children}
				</Text>
				{selected && <IconCheck color={disabled ? 'neutral600' : 'neutral800'} />}
			</Container>
		);
	},
);
SelectOption.displayName = 'SelectOption';
