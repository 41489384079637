/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import Chunks from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/Chunks';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';
import { useField } from 'formik';

export default function ExperimentNameField(): ReactElement {
	const [{ value: experimentName }] = useField<string>('__originalExperimentName');
	return (
		<Stack direction="horizontal" size="xSmall">
			<Text as="span" variant="mediumStrong" color="neutral800" sx={{ textWrap: 'nowrap', minWidth: '140px' }}>
				Experiment name:
			</Text>

			<div>
				<Chunks value={experimentName} />
			</div>
		</Stack>
	);
}
