/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { FieldMetaProps } from 'formik';
import { VariableVO } from 'ui-api';

export function hasError(meta: FieldMetaProps<unknown>): boolean {
	return Boolean(meta.touched && meta.error);
}

export function smellsLikeEnvVar(value: unknown): boolean {
	return typeof value === 'string' && value.length > 4 && value.startsWith('{{') && value.endsWith('}}');
}

export function smellsLikeTemplatePlaceholder(value: unknown): boolean {
	return typeof value === 'string' && value.length > 4 && value.startsWith('[[') && value.endsWith(']]');
}

export function getEnvVar(str: string, variables: VariableVO[]): string {
	if (typeof str !== 'string' || !smellsLikeEnvVar(str)) {
		return str;
	}
	const variableValue = variables.find((v) => v.key === str.slice(2, -2))?.value;
	return variableValue || str;
}
