/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage } from '@steadybit/ui-components-lib';
import { TableDataCell, TableRow } from 'components';
import { ReactElement } from 'react';

interface TableErrorRowProps {
	error: string;
}

export const TableErrorRow = ({ error }: TableErrorRowProps): ReactElement => {
	return (
		<TableRow>
			<TableDataCell colSpan={42}>
				<ErrorMessage type="medium" level="error" withIcon>
					{error}
				</ErrorMessage>
			</TableDataCell>
		</TableRow>
	);
};
