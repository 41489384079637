/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Spacings, Text } from '@steadybit/ui-components-lib';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { ReactElement } from 'react';

import emptyPredicateImage from './images/empty_predicate.png';

interface EmptyTableNoPredicateProps {
	targetDefinitionId: string;
	message: string;
	title: string;
}
export default function EmptyTableNoPredicate({
	title,
	message,
	targetDefinitionId,
}: EmptyTableNoPredicateProps): ReactElement {
	return (
		<Flex
			spacing="xxSmall"
			align="center"
			justify="center"
			style={{
				marginTop: Spacings.xxxLarge,
			}}
		>
			<AttackedTargetIndicator targetType={targetDefinitionId} variant="xxLarge" color="purple700" mb="small" />
			<Text type="largeStrong">{title}</Text>
			<Text
				type="medium"
				style={{
					color: Colors.neutral600,
					textAlign: 'center',
					maxWidth: '340px',
				}}
			>
				{message}
			</Text>
			<img
				src={emptyPredicateImage}
				loading="lazy"
				style={{
					width: '400px',
					height: '286px',
				}}
			/>
		</Flex>
	);
}
