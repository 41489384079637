/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	AgentInstallationGuidance,
	AgentReportVO,
	AgentVO,
	ChangeAgentLogLevelRequest,
	GetAgentsPageResponse,
} from 'ui-api';
import { unescapeNewLines } from 'utils/unsecape';
import { withBaseHref } from 'utils/getBaseHref';
import { PageParams } from 'utils/hooks/usePage';
import { exhaustMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import axios from 'axios';

import { TenantsApi } from './tenantsApi';

export class AgentsApi {
	private tenants: TenantsApi;

	constructor(tenants: TenantsApi) {
		this.tenants = tenants;
	}

	async getAgentInstallationGuidance(): Promise<AgentInstallationGuidance> {
		return (await axios.get<AgentInstallationGuidance>('/ui/agents/installation')).data;
	}

	async findAgents(query: string, page: PageParams): Promise<GetAgentsPageResponse> {
		const params = page.toUrlSearchParams({ query });
		return (await axios.get<GetAgentsPageResponse>('/ui/agents', { params })).data;
	}

	async fetchAgent(agentId: string): Promise<AgentVO> {
		return (await axios.get<AgentVO>(`/ui/agents/${agentId}`)).data;
	}

	async fetchLastReported(): Promise<AgentReportVO> {
		return (await axios.get<AgentReportVO>('/ui/agents/lastreported')).data;
	}

	async setLogLevel(agentId: string, body: ChangeAgentLogLevelRequest): Promise<void> {
		await axios.post(`/ui/agents/${agentId}/loglevel`, body);
	}

	async setLogLevelAll(body: ChangeAgentLogLevelRequest): Promise<void> {
		await axios.post('/ui/agents/loglevel', body);
	}

	async deregisterAgent(agentId: string): Promise<void> {
		await axios.post('/ui/agents/' + agentId + '/deregister');
	}

	async deregisterAllAgents(): Promise<void> {
		await axios.post('/ui/agents/deregister/all');
	}

	streamAgentLog(agentId: string, tenantKey: string): Observable<string> {
		return from(this.getAgentLogUrl(agentId, tenantKey)).pipe(
			exhaustMap(
				(url) =>
					new Observable<string>((subscriber) => {
						let opened = false;
						const eventSource = new EventSource(url);
						eventSource.onmessage = (m) => subscriber.next(unescapeNewLines(String(m.data)));
						eventSource.onopen = () => (opened = true);
						eventSource.onerror = (e) => {
							//this event is triggered when connect fails.
							//as the eventSource tries to reconnect after connection failure
							//we signal the error only for the first connect - we don't want to reconnect
							if (!opened) {
								subscriber.error(e);
							} else {
								subscriber.complete();
							}
						};
						return () => eventSource.close();
					}),
			),
		);
	}

	async getAgentLogUrl(agentId: string, tenantKey: string): Promise<string> {
		return withBaseHref(`/ui/agents/${agentId}/log?tenantKey=${tenantKey}`);
	}
}
