/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DeleteIcon } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import { getColors, getIcon, getLabel } from 'pages/experiments/components/utils';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ActionKindVO } from 'ui-api';
import { ReactElement } from 'react';
import { Pill } from 'components';

interface FreeTextProps {
	children: string;
	onDelete?: () => void;
	onClick?: () => void;
}

export default function Kind({ children, onClick, onDelete }: FreeTextProps): ReactElement {
	const kind = children as ActionKindVO;
	const Icon = getIcon(kind);
	const { backgroundColor, color } = getColors(kind);
	const label = getLabel(kind);

	return (
		<Pill
			backgroundColor={backgroundColor}
			color={color}
			sx={{
				width: 'fit-content',
				borderRadius: '4px',
				fontSize: '12px',
				p: '4px 8px',
				...textEllipsis,
				textTransform: 'capitalize',
			}}
			onClick={onClick}
		>
			<Icon mr="xxSmall" />
			{label}
			{onDelete && <DeleteIcon color={color} onClick={onDelete} />}
		</Pill>
	);
}
