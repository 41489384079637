/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Container, Pill, Snackbar, Stack, Text, Tooltip } from 'components';
import { useLastVisitedView } from 'pages/landscape/hocs/lastVisitedView';
import { IconEnvironment, IconShare, IconTrash } from 'components/icons';
import textEllipsis3Lines from 'utils/styleSnippets/textEllipsis3Lines';
import { EnvironmentSummaryVO, LandscapeViewVO } from 'ui-api';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useHistory } from 'url/hooks';

import ViewFrame from './ViewFrame';

interface ViewProps {
	view: LandscapeViewVO;
	environment?: EnvironmentSummaryVO;
	onDelete: () => void;
}

export const PREVIEW_DIMENSIONS = {
	width: 240,
	height: 134,
};

export function useShareClipboard(): [string | null, Dispatch<SetStateAction<string | null>>] {
	const [linkToCopy, setLinkToCopy] = useState<string | null>(null);
	useEffect(() => {
		if (!linkToCopy) {
			return;
		}

		navigator.clipboard.writeText(linkToCopy).then(() => {
			setLinkToCopy(null);
			return Snackbar.dark(
				<>
					<Text variant={'mediumStrong'} sx={{ display: 'inline', mr: 'xxSmall' }}>
						Link copied to your clipboard!
					</Text>
					<Text variant={'medium'} sx={{ display: 'inline' }}>
						Share it with other Steadybit users of your organization.
					</Text>
				</>,
				{ toastId: 'view-link-copied' },
			);
		});
	}, [linkToCopy]);
	return [linkToCopy, setLinkToCopy];
}
export default function View({ view, environment, onDelete }: ViewProps): ReactElement {
	const { createHref } = useHistory();
	const [disableParent, setDisableParent] = useState(false);
	const [, setLinkToCopy] = useShareClipboard();
	const { setLastViewedId } = useLastVisitedView();

	const [imageHeight, setImageHeight] = useState<string>('auto');
	const imageRef = useRef<HTMLImageElement>(null);
	useEffect(() => {
		if (
			imageRef.current &&
			imageRef.current.width / imageRef.current.height < PREVIEW_DIMENSIONS.width / PREVIEW_DIMENSIONS.height
		) {
			setImageHeight('100%');
		}
	}, [imageRef.current]);

	return (
		<ViewFrame
			disabled={disableParent}
			href={createHref((location) => {
				location.pathname = `/landscape/explore/${encodeURIComponent(view.id)}`;
			})}
			onClick={() => {
				setLastViewedId(view.id);
			}}
		>
			<Container
				display="flex"
				alignItems="center"
				justifyContent="center"
				width={PREVIEW_DIMENSIONS.width}
				height={PREVIEW_DIMENSIONS.height}
				minWidth={PREVIEW_DIMENSIONS.width}
				minHeight={PREVIEW_DIMENSIONS.height}
				overflow="hidden"
				bg="neutral700"
			>
				<img ref={imageRef} src={view.screenshot} style={{ height: imageHeight }} />
			</Container>
			<Stack size="xSmall" alignItems="flex-start" justifyContent="space-between" height="100%">
				<Stack size="xxSmall" width="100%">
					<Tooltip content={view.name}>
						<Text variant="mediumStrong" color="neutral800" sx={{ ...textEllipsis }}>
							{view.name}
						</Text>
					</Tooltip>
					<Tooltip content={<Text maxWidth="540px">{view.description}</Text>}>
						<Text
							variant="small"
							color="neutral600"
							sx={{
								...textEllipsis3Lines,
							}}
						>
							{view.description}
						</Text>
					</Tooltip>
					<Pill
						backgroundColor="neutral200"
						color="neutral800"
						sx={{
							width: 'fit-content',
							maxWidth: '100%',
						}}
					>
						<IconEnvironment minWidth={16} minHeight={16} ml="xxSmall" mr="xSmall" />
						<Text variant="small" mr="xxSmall" sx={{ ...textEllipsis }}>
							{environment?.name || view.environmentId}
						</Text>
					</Pill>
				</Stack>

				<Stack direction="horizontal" justifyContent="space-between" width="100%">
					<ViewFrameButton onClick={onDelete} setDisableParent={setDisableParent}>
						<IconTrash mr="xxSmall" />
						<Text variant="mediumStrong">Delete</Text>
					</ViewFrameButton>
					<ViewFrameButton
						onClick={() =>
							setLinkToCopy(
								location.origin +
									createHref((location) => {
										location.pathname = `/landscape/explore/${encodeURIComponent(view.id)}`;
									}),
							)
						}
						setDisableParent={setDisableParent}
					>
						<IconShare mr="xxSmall" />
						<Text variant="mediumStrong">Share</Text>
					</ViewFrameButton>
				</Stack>
			</Stack>
		</ViewFrame>
	);
}

interface ViewFrameButtonProps {
	children: ReactNode[];
	onClick: () => void;
	setDisableParent: (disable: boolean) => void;
}

export function ViewFrameButton({ children, onClick, setDisableParent }: ViewFrameButtonProps): ReactElement {
	return (
		<Button
			variant="chromeless"
			sx={{
				p: 0,
				'&:hover': {
					textDecoration: 'underline',
				},
			}}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick();
			}}
			onMouseEnter={() => setDisableParent(true)}
			onMouseLeave={() => setDisableParent(false)}
		>
			{children}
		</Button>
	);
}
