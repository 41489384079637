/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	RouterButton,
	SettingsGroup,
	SettingsGroupItem,
	ShortenedText,
	Spinner,
	Stack,
	Text,
	Tooltip,
} from 'components';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconCheck, IconChevronDown, IconChevronUp, IconView } from 'components/icons';
import React, { ReactElement, RefObject, useCallback, useMemo, useState } from 'react';
import textEllipsis2Lines from 'utils/styleSnippets/textEllipsis2Lines';
import DropdownInput from 'components/Select/Dropdown/DropdownInput';
import TeamIcon from 'pages/settings/teams/components/TeamIcon';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { useTenantSwitcher } from 'tenancy/useTenantSwitcher';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { AnimatePresence, motion } from 'framer-motion';
import { usePromise } from 'utils/hooks/usePromise';
import { TeamSummaryVO, TenantVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

export default function TenantAndTeamSwitcher({
	expanded,
	setShowTeamSwitcher,
}: {
	expanded: boolean;
	setShowTeamSwitcher: (v: boolean) => void;
}): ReactElement {
	const tenants = usePromise(() => Services.tenants.fetchTenants(), []);
	const multiTenant = useMemo(() => tenants.value && tenants.value.length > 1, [tenants.value]);

	const [teams, fetch] = useAsyncState(() => Services.teams.findTeams('', false, 0, 100), []);
	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if ('team.created' === event.type || 'team.deleted' === event.type || 'team.updated' === event.type) {
					debouncedFetch();
				}
			},
			[debouncedFetch, teams],
		),
		[],
		debouncedFetch.cancel,
		[teams.value],
	);

	const currentTenant = useTenant();
	const currentTeam = useTeam();

	const userIsMemberOfCurrentTeam = useMemo(() => {
		return (
			teams.loading ||
			(teams.value && !!teams.value.filter((team) => team.userIsTeamMember).find((team) => team.id === currentTeam.id))
		);
	}, [currentTeam, teams]);

	const [hovered, setHovered] = useState(false);

	return (
		<DropDown
			onOpen={() => setShowTeamSwitcher(true)}
			onClose={() => setShowTeamSwitcher(false)}
			renderComponent={({ setShowMenu, showMenu, ref }) => {
				return (
					<Stack
						ref={ref as RefObject<HTMLDivElement>}
						direction="horizontal"
						size="xxSmall"
						onClick={() => setShowMenu(!showMenu)}
						sx={{
							position: 'relative',
							alignItems: 'center',
							padding: '0px 12px 4px 0px',
							height: '60px',

							backgroundColor: showMenu ? 'purple600' : undefined,
							borderRadius: '4px',
							overflow: 'hidden',

							cursor: 'pointer',
							'&:hover': {
								backgroundColor: 'purple600',
							},
						}}
						data-cy="team"
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
					>
						<div
							style={{
								position: 'absolute',
								top: '26px',
								left: '9px',
								width: 30,
								height: 30,
								backgroundColor: hovered ? theme.colors.purple600 : theme.colors.purple700,
								borderRadius: '4px',
							}}
						/>
						<div
							style={{
								position: 'absolute',
								top: '18px',
								left: '3px',
								width: 34,
								height: 34,
								backgroundColor: theme.colors.purple600,
								borderRadius: '4px',
							}}
						/>

						<Container sx={{ zIndex: 1, minWidth: '40px' }}>
							<TeamIcon
								userIsMember={!!userIsMemberOfCurrentTeam}
								color={currentTeam.logoColor}
								logoId={currentTeam.logoId}
							/>
						</Container>

						{expanded && (
							<AnimatePresence initial>
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.1, delay: 0.3 }}
								>
									<Stack size="none" width="100%">
										{multiTenant && (
											<Stack direction="horizontal" size="xxSmall">
												<Text
													as="span"
													variant="small"
													color="purple300"
													sx={{ whiteSpace: 'nowrap', lineHeight: '12px' }}
													data-private
												>
													{currentTenant.name}
												</Text>
												<Text
													as="span"
													variant="small"
													color="purple300"
													sx={{ whiteSpace: 'nowrap', lineHeight: '12px' }}
												>
													/
												</Text>
											</Stack>
										)}
										<Stack direction="horizontal" alignItems="center" justifyContent="space-between">
											<Text
												variant="mediumStrong"
												color="neutral000"
												sx={{
													...textEllipsis2Lines,
													lineHeight: '14px',
												}}
											>
												{currentTeam.name} {userIsMemberOfCurrentTeam ? null : <ViewOnly />}
											</Text>
											{showMenu ? <IconChevronUp color="purple300" /> : <IconChevronDown color="purple300" />}
										</Stack>
									</Stack>
								</motion.div>
							</AnimatePresence>
						)}
					</Stack>
				);
			}}
		>
			{() => <DropdownContent tenants={tenants.value || []} teams={teams.value || []} />}
		</DropDown>
	);
}

interface DropdownContentProps {
	teams: TeamSummaryVO[];
	tenants: TenantVO[];
}

function DropdownContent({ teams, tenants }: DropdownContentProps): ReactElement {
	const tenant = useTenant();

	const currentTenant = useTenant();
	const currentTeam = useTeam();
	const isAdmin = currentTenant.user.role === 'ADMIN';

	const tenantSwitcher = useTenantSwitcher();

	const handleTenantSelect = useCallback((tenant: TenantVO) => tenantSwitcher(tenant.key), []);

	const handleTeamSelect = useCallback((team: TeamSummaryVO) => {
		Services.teams.setTeam(tenant.key, team.key);
		const pathSplit = window.location.pathname.split('/');
		const targetPath = pathSplit.length > 0 ? pathSplit[1] : '';
		ampli.teamSwitched({
			team_id_switched_to: team.id,
		});
		window.location.assign('/' + targetPath);
	}, []);

	const userTeams = useMemo(() => (teams ? teams.filter((team) => team.userIsTeamMember) : []), [teams]);
	const otherTeams = useMemo(() => (teams ? teams.filter((team) => !team.userIsTeamMember) : []), [teams]);

	const userIsNotPartOfAnyTeam = userTeams.length === 0;
	const userHasOtherTeams = otherTeams.length > 0;
	const [showOtherTeams, setShowOtherTeams] = useState(false);

	const [userTenantQuery, setUserTenantQuery] = useState<string>(currentTenant.name);
	const [filterTenants, setFilterTenants] = useState<boolean>(false);

	return (
		<DropdownContentFrame
			sx={{
				py: 'small',
				borderRadius: '4px',
				maxHeight: 'calc(80vh - 100px)',
				overflow: 'auto',
				minWidth: '380px',
				maxWidth: '380px',
			}}
		>
			<>
				{tenants && tenants.length > 1 ? (
					<Container
						sx={{
							px: 'small',
							pt: 'xSmall',
							pb: 'small',
							borderBottom: '1px solid',
							borderBottomColor: 'neutral200',
						}}
					>
						<DropdownInput
							value={userTenantQuery}
							onValueChanged={(v) => {
								setUserTenantQuery(v);
								setFilterTenants(true);
							}}
							iconRight={(showMenu) =>
								showMenu ? (
									<IconChevronUp variant="small" mt="5px" mr="5px" />
								) : (
									<IconChevronDown variant="small" mt="5px" mr="5px" />
								)
							}
							width="100%"
							sx={{ cursor: 'pointer' }}
						>
							{({ width }) => (
								<DropdownContentFrame>
									<Labels
										selectedId={currentTenant.key}
										type="strict"
										onSelect={({ id }) => {
											const selectedTenant = id ? tenants.find((t) => t.key === id) : null;
											if (selectedTenant && selectedTenant.key !== currentTenant.key) {
												handleTenantSelect(selectedTenant);
											}
										}}
										labels={tenants
											.filter((tenant) => {
												if (!filterTenants) {
													return true;
												}
												return includes(tenant.name, userTenantQuery);
											})
											.map((tenant) => ({ label: tenant.name, id: tenant.key }))}
										width={width}
										withoutTooltip
									/>
								</DropdownContentFrame>
							)}
						</DropdownInput>
					</Container>
				) : null}
				{isAdmin && (
					<Container
						display={'flex'}
						px={'small'}
						py={'xSmall'}
						flexDirection={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Text variant={'smallStrong'}>Teams</Text>
						<RouterButton to={'/settings/teams'} variant={'secondarySmall'}>
							Manage Teams
						</RouterButton>
					</Container>
				)}
			</>

			<SettingsGroup sx={{ border: 'none', maxWidth: 400 }}>
				{userTeams.map((team) => (
					<TeamEntry
						key={team.id}
						team={team}
						checked={currentTeam.id === team.id}
						onClick={() => handleTeamSelect(team)}
					/>
				))}

				{userIsNotPartOfAnyTeam && (
					<Text variant="small" color="neutral600" px="small" pt="small">
						Your user is not assigned to any team
					</Text>
				)}

				{userHasOtherTeams && (
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							pb: 'small',
							pt: 'medium',
							px: 'small',
							cursor: 'pointer',
						}}
						onClick={() => setShowOtherTeams(!showOtherTeams)}
					>
						<Text variant="smallStrong">Other Teams (view only)</Text>
						{showOtherTeams ? (
							<IconChevronUp variant="small" color="neutral800" />
						) : (
							<IconChevronDown variant="small" color="neutral800" />
						)}
					</Container>
				)}

				{showOtherTeams && (
					<OtherTeams currentTeam={currentTeam} otherTeams={otherTeams} handleTeamSelect={handleTeamSelect} />
				)}
			</SettingsGroup>
		</DropdownContentFrame>
	);
}

const ViewOnly: React.VFC = () => {
	return (
		<Tooltip content={'View only - you are not a member of this team'} placement={'bottom'}>
			<span>
				<IconView color="neutral300" ml={'xxSmall'} />
			</span>
		</Tooltip>
	);
};

interface OtherTeamsProps {
	currentTeam: TeamSummaryVO;
	otherTeams: TeamSummaryVO[];
	handleTeamSelect: (team: TeamSummaryVO) => void;
}

function OtherTeams({ currentTeam, otherTeams, handleTeamSelect }: OtherTeamsProps): ReactElement {
	const [teams] = useAsyncState(() => Services.teams.findTeams('', true, 0, 100), [], []);

	return (
		<>
			{otherTeams.map((team) => (
				<TeamEntry
					key={team.id}
					team={team}
					checked={currentTeam.id === team.id}
					onClick={() => handleTeamSelect(team)}
				/>
			))}
			{teams.loading && (
				<Container display={'flex'} px={12} pb={'xxSmall'} alignItems={'center'}>
					<Spinner color={'neutral400'} />
				</Container>
			)}
		</>
	);
}

const TeamEntry: React.VFC<{ team: TeamSummaryVO; checked: boolean; onClick: () => void }> = ({
	team,
	checked,
	onClick,
}) => {
	return (
		<SettingsGroupItem
			sx={{
				cursor: 'pointer',
				':hover': {
					bg: 'neutral100',
				},
			}}
			py={'xSmall'}
			px={'small'}
			key={team.id}
			onClick={onClick}
		>
			<Stack direction="horizontal" alignItems="center" justifyContent="space-between">
				<TeamIcon userIsMember={team.userIsTeamMember} color={team.logoColor} logoId={team.logoId} />

				<ShortenedText
					as={'label'}
					variant={checked ? 'mediumStrong' : 'medium'}
					color={checked ? 'neutral800' : 'neutral700'}
					sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
					flexGrow={1}
				>
					{team.name} ({team.key})
				</ShortenedText>
				{checked && <IconCheck flex={'0 0 auto'} />}
			</Stack>
		</SettingsGroupItem>
	);
};
