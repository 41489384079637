/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { useAutoFocus } from 'utils/hooks/useAutoFocus';
import { useObservable } from 'react-use';
import React, { useState } from 'react';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { TextField } from '../TextField';
import { ModalOverlay } from '../Modal';
import { Button } from '../Button';
import { Dialog } from './Dialog';

type UserInputOptions = {
	title: string;
	placeholder?: string;
	buttonOkCaption?: string;
};

type UserInputResult = {
	action: 'cancel' | 'ok';
	input?: string;
};

type OutstandingInput = UserInputOptions & {
	resolve: (result: UserInputResult) => void;
};

const outstandingInputs$ = new ReplaySubject<OutstandingInput | null>(1);

export const UserInputContainer: React.VFC = () => {
	const userInput = useObservable(outstandingInputs$);
	const [value, setValue] = useState<string>('');

	const cancel = (): void => {
		userInput?.resolve({ action: 'cancel' });
		setValue('');
	};

	const confirm = (): void => {
		userInput?.resolve({ action: 'ok', input: value });
		setValue('');
	};

	return (
		<ModalOverlay open={Boolean(userInput)} onClose={cancel} zIndex={40}>
			{({ close }) => {
				const { title, placeholder, buttonOkCaption } = ensure(userInput);
				return (
					<Dialog>
						<DialogHeader title={title} onClose={close} />
						<DialogContent>
							<AutofocusInputTextField
								value={value}
								setValue={setValue}
								confirm={confirm}
								cancel={cancel}
								placeholder={placeholder}
							/>
						</DialogContent>
						<DialogFooter>
							<Button
								ml={'auto'}
								variant={'secondary'}
								onClick={(e) => {
									e.stopPropagation();
									cancel();
								}}
								data-cy="cancel-button"
							>
								Cancel
							</Button>
							<Button
								ml={'small'}
								variant={'primary'}
								onClick={(e) => {
									e.stopPropagation();
									confirm();
								}}
								data-cy="ok-button"
								disabled={!value?.length}
							>
								{buttonOkCaption ? buttonOkCaption : 'Ok'}
							</Button>
						</DialogFooter>
					</Dialog>
				);
			}}
		</ModalOverlay>
	);
};

interface AutofocusInputTextFieldProps {
	value: string;
	setValue: (value: string) => void;
	placeholder?: string;
	confirm: () => void;
	cancel: () => void;
}

const AutofocusInputTextField: React.VFC<AutofocusInputTextFieldProps> = ({
	value,
	setValue,
	placeholder,
	confirm,
	cancel,
}) => {
	const autoFocusRef = useAutoFocus();
	return (
		<TextField
			value={value}
			onChange={(e) => setValue(e.target.value)}
			placeholder={placeholder}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					confirm();
				} else if (e.key === 'Escape' || e.key === 'Esc') {
					cancel();
				}
			}}
			ref={autoFocusRef}
		/>
	);
};

export function userInput(arg: UserInputOptions): Promise<UserInputResult> {
	return new Promise<UserInputResult>((resolve) => {
		outstandingInputs$.next({
			resolve: (result: UserInputResult) => {
				outstandingInputs$.next(null);
				resolve(result);
			},
			...arg,
		});
	});
}
