/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Banner, Link, Stack, Text } from 'components';
import { ReactElement } from 'react';

import { useShouldUpdateUI } from './useShouldUpdateUI';

export default function UpdatedWarning(): ReactElement | null {
	const needsUpdate = useShouldUpdateUI();
	if (!needsUpdate) {
		return null;
	}

	return (
		<Banner
			id="steadybit-update-warning"
			variant="warning"
			title="Steadybit has been updated"
			description={
				<Stack size="none">
					<Text as="span" variant="small">
						Please refresh your browser to apply the changes.{' '}
						<Link onClick={() => window.location.reload()}>Refresh now!</Link>
					</Text>
				</Stack>
			}
			sx={{
				py: '6px',
			}}
		/>
	);
}
