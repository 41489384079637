/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Tooltip, Text } from 'components';
import { ReactElement } from 'react';

import Grouped, { GroupedProps } from './Grouped';
import { Label } from './types';

type GroupedLabelsProps = Omit<GroupedProps, 'renderLabel'>;

export default function GroupedLabels(props: GroupedLabelsProps): ReactElement {
	return <Grouped {...props} renderLabel={renderLabel} />;
}

function renderLabel({ label, highlighted }: Label): ReactElement {
	return (
		<Tooltip key={label} content={label}>
			<Text
				color={highlighted ? 'slate' : 'neutral800'}
				variant={highlighted ? 'smallStrong' : 'small'}
				sx={{ ...textEllipsis }}
			>
				{label}
			</Text>
		</Tooltip>
	);
}
