/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import Duration from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/Duration';
import FieldWrapper from 'pages/experimentsV2/StepConfigurationSidebar/Fields/FieldWrapper';
import StepEditHeader from 'pages/experiments/components/experimentStepEditHeader';
import { ExperimentStepWaitVO } from 'ui-api';
import { IconDelay } from 'components/icons';
import { ReactElement } from 'react';
import { Stack } from 'components';
import { useField } from 'formik';

interface WaitProps {
	waitStep: ExperimentStepWaitVO;
	onDuplicate: () => void;
	onDelete: () => void;
	onClose: () => void;
	stepPath: string;
}

export default function Wait(props: WaitProps): ReactElement {
	const { waitStep, stepPath, onClose, onDelete, onDuplicate } = props;
	const [{ value }, , { setValue, setTouched }] = useField<string>(`${stepPath}.parameters.duration`);

	return (
		<Stack size="small" width="100%">
			<StepEditHeader
				backgroundColor="steelBlueMid"
				icon={<IconDelay />}
				stepPath={stepPath}
				step={waitStep}
				caption="Wait"
				disabled={false}
				onClose={onClose}
				onDelete={onDelete}
				onDuplicate={onDuplicate}
			/>

			<Stack size="small" width="100%" pt="6px" px="small">
				<FieldWrapper
					field={{
						name: 'duration',
						label: 'Duration',
						type: 'duration',
						required: true,
					}}
				>
					<Duration
						value={value}
						disabled={false}
						setValue={(v) => {
							setValue(v);
							setTouched(true);
						}}
					/>
				</FieldWrapper>
			</Stack>
		</Stack>
	);
}
