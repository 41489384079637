/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { AnimatePresence } from 'framer-motion';
import { Container } from 'components';
import { useKey } from 'react-use';
import ReactDOM from 'react-dom';
import React from 'react';

import { MotionBox } from '../motion';

export interface ModalOverlayRender {
	open: boolean;
	close: () => void;
}

export interface ModalOverlayProps {
	open?: boolean;
	centerContent?: boolean;
	onClose?: () => void;
	children: ((props: ModalOverlayRender) => React.ReactNode) | React.ReactNode;
	onExitComplete?: () => void;
	zIndex?: number;
}

const animateContent = {
	initial: { opacity: 0, y: -10 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 10 },
	transition: { duration: 0.3 },
};
export const ModalOverlay: React.VFC<ModalOverlayProps> = ({
	children,
	onClose,
	onExitComplete,
	open = true,
	centerContent = false,
	zIndex = 10,
}) => {
	const hostEl = React.useMemo(() => document.createElement('div'), []);

	useKey(
		'Escape',
		() => {
			if (open) {
				const modals: HTMLElement[] = Array.from(document.querySelectorAll('[aria-modal=true]'));
				if (modals[modals.length - 1] === hostEl) {
					onClose?.();
				}
			}
		},
		undefined,
		[open],
	);

	React.useLayoutEffect(() => {
		if (open) {
			hostEl.setAttribute('aria-modal', 'true');
			document.body.appendChild(hostEl);
		}
	}, [open, hostEl]);

	const content = (
		<AnimatePresence
			onExitComplete={() => {
				hostEl.remove();
				onExitComplete?.();
			}}
		>
			{open ? (
				<Container
					display={'flex'}
					sx={{
						position: 'fixed',
						top: 0,
						right: 0,
						left: 0,
						bottom: 0,
						justifyContent: 'center',
						backdropFilter: 'blur(1px)',
						zIndex,
					}}
				>
					<Backdrop onClick={onClose} />
					<MotionBox
						{...animateContent}
						sx={{
							pointerEvents: 'none',
							display: 'flex',
							width: '100%',
							height: '100%',
							position: 'absolute',
							willChange: 'opacity, transform',
							alignItems: centerContent ? 'center' : 'flex-start',
							justifyContent: 'center',
							'& *': {
								pointerEvents: 'initial',
							},
						}}
					>
						{typeof children === 'function'
							? children({
									open,
									close: () => onClose?.(),
								})
							: children}
					</MotionBox>
				</Container>
			) : null}
		</AnimatePresence>
	);

	return ReactDOM.createPortal(content, hostEl);
};

const backdropAnimate = {
	initial: { opacity: 0 },
	animate: { opacity: 0.15 },
	exit: { opacity: 0 },
	transition: { duration: 0.3 },
};
const Backdrop: React.VFC<{ onClick?: React.MouseEventHandler<HTMLElement> }> = ({ onClick }) => (
	<MotionBox
		{...backdropAnimate}
		onClick={(e) => onClick?.(e)}
		sx={{
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			position: 'fixed',
			bg: 'neutral800',
			willChange: 'opacity',
		}}
	/>
);
