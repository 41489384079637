/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

export function toInt(s?: string, fallback?: number): number | null {
	return s !== undefined && s !== '' ? parseInt(s) : (fallback ?? null);
}

export function toString(i?: number | string | null): string {
	if (i == null) {
		return '';
	}

	if (typeof i === 'number') {
		if (isNaN(i)) {
			return '';
		}

		return String(i);
	} else if (isNaN(parseInt(i))) {
		return '';
	} else {
		return i;
	}
}

export function roundToTwoDecimals(value: number): number {
	return ((value * 100) | 0) / 100;
}
