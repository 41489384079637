/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { getHash } from 'utils/hash';
import { useMemo } from 'react';

/**
 * Returns the same previous object instance as long the hash
 * has not changed.
 */
export function useStableInstance<T>(v: T): [string, T] {
	const hash: string = v ? getHash(v) : '';
	return useMemo(() => {
		return [hash, v];
	}, [hash]);
}
