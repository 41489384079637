/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Heading, Stack, Text } from 'components';
import { Fragment, ReactElement, ReactNode } from 'react';
import { IconCheck } from 'components/icons';
import { theme } from 'styles.v2/theme';

export interface Step {
	label: string;
	description?: (currentPage: number, stepIndex: number) => string | ReactNode;
	onClick?: () => void;
}

interface StepIndicatorsProps {
	currentPage: number;
	title?: string;
	steps: Step[];
}

export default function StepIndicators({ title, currentPage, steps }: StepIndicatorsProps): ReactElement | null {
	return (
		<Stack sx={{ overflowX: 'hidden' }}>
			{title && <Heading variant="large">{title}</Heading>}
			<Stack size="xSmall">
				{steps.map(({ label, description, onClick }, index) => {
					const desc = description ? description(currentPage, index) : undefined;
					return (
						<Fragment key={index}>
							<Step
								step={index + 1}
								label={label}
								active={currentPage === index}
								completed={currentPage > index}
								onClick={onClick}
							/>
							{!desc && index === steps.length - 1 ? undefined : (
								<Description
									description={desc}
									active={currentPage === index}
									completed={currentPage > index}
									last={index === steps.length - 1}
								/>
							)}
						</Fragment>
					);
				})}
			</Stack>
		</Stack>
	);
}

interface StepProps {
	completed?: boolean;
	active: boolean;
	label: string;
	step: number;
	onClick?: () => void;
}

function Step({ step, label, active, completed, onClick }: StepProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="xSmall"
			sx={{
				alignItems: 'center',
				cursor: onClick ? 'pointer' : 'default',
			}}
			onClick={onClick}
		>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',

					width: 28,
					height: 28,
					borderRadius: '50%',
					bg: completed ? 'cyan700' : active ? 'slate' : 'neutral200',
				}}
			>
				{!completed ? (
					<Text variant="smallStrong" color={active || completed ? 'neutral000' : 'neutral700'}>
						{step}
					</Text>
				) : (
					<IconCheck color="neutral200" />
				)}
			</Container>

			<Text
				variant={active || completed ? 'smallStrong' : 'small'}
				color={active || completed ? 'neutral800' : 'neutral600'}
			>
				{label}
			</Text>
		</Stack>
	);
}

interface DescriptionProps {
	description: ReactNode;
	completed?: boolean;
	active: boolean;
	last?: boolean;
}

function Description({ description, active, completed, last }: DescriptionProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="small"
			sx={{
				alignItems: 'stretch',
				ml: 11,
				minHeight: '24px',
			}}
		>
			<Container
				sx={{
					...(active || completed
						? {
								borderLeft: last
									? '2px solid transparent'
									: '2px solid ' + (completed ? theme.colors.cyan700 : theme.colors.slate),
							}
						: {
								py: '12px',
								width: 2,
								backgroundImage: 'linear-gradient(' + theme.colors.neutral300 + ' 60%, rgba(0,0,0,0) 0%)',
								backgroundPosition: 'right',
								backgroundSize: '3px 13px',
								backgroundRepeat: 'repeat-y',
							}),
				}}
			/>

			{typeof description === 'string' ? (
				<Text variant="small" color="neutral700" pl={7}>
					{description}
				</Text>
			) : (
				description
			)}
		</Stack>
	);
}
