/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useCreateExperimentFromScratch from 'pages/experimentsV2/create/useCreateExperimentFromScratch';
import TemplateCard, { LoadingCard } from 'templates/components/TemplateCard/TemplateCard';
import { Button, Container, Pagination, RouterButton, Stack, Text } from 'components';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { ReactElement, Suspense, lazy, useState } from 'react';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { ActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { IconTemplate } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { useTeam } from 'services/useTeam';
import { useHistory } from 'url/hooks';
import { ampli } from 'ampli';

import {
	UrlState,
	createFilterParams,
	includeNonPermittedParam,
	pageParam,
	selectedTemplateIdParam,
	useTemplateIdParam,
} from './urlParams';
import { useFromTemplatePage } from './FromTemplateModal';
import { useTenant } from '../../../tenancy/useTenant';

const AdvertiseCard = lazy(() => import('../components/AdvertiseCard'));

interface TemplateListProps {
	targetDefinitionsResult: DataStreamResult<TargetTypeDescriptionVO[]>;
	actionsResult: DataStreamResult<ActionVO[]>;
}

export default function TemplateList({ targetDefinitionsResult, actionsResult }: TemplateListProps): ReactElement {
	const page = useFromTemplatePage();
	const team = useTeam();
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';
	const onCreateExperimentFromDraft = useCreateExperimentFromScratch();
	const history = useHistory();

	const [{ tagsParam, actionsParam, targetTypesParam }] = useState(() => createFilterParams('/from_templates'));
	const [{ tags, targetTypes, actions, includeNonPermitted }, , updateUrlState] = useUrlState<UrlState>([
		includeNonPermittedParam,
		selectedTemplateIdParam,
		useTemplateIdParam,
		targetTypesParam,
		actionsParam,
		tagsParam,
		pageParam,
	]);

	const templatesResult = useRefreshingTemplates({
		teamId: includeNonPermitted ? undefined : team.id,
		searchContext: 'NEW_EXPERIMENT',
		pageSize: page.pageParams.size,
		pathname: '/from_templates',
	});

	const isLoading = !targetDefinitionsResult.value || !actionsResult.value || !templatesResult.value;

	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	if (!isLoading && !templatesResult.error && templatesResult.value.totalElements === 0) {
		return <EmptyListContent isAnyFilterActive={tags.length > 0 || targetTypes.length > 0 || actions.length > 0} />;
	}

	return (
		<Stack size="large" justifyContent="center" mt="large">
			<Container
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '24px',
				}}
			>
				{canCreateTemplates && (
					<Suspense fallback={<LoadingCard />}>
						<AdvertiseCard
							description={
								isAdmin
									? {
											headline: 'Craft your own and share it!',
											subheadline: '>_ sharing is caring',
											text: (
												<>
													Share your knowledge with your teams by crafting a new
													<br />
													template!
												</>
											),
											ctaText: 'Create new template',
											ctaOnClick: async () => {
												history.push('/settings/templates/design/<new>');
											},
										}
									: {
											headline: <>Missing a template?</>,
											subheadline: '>_ Start from scratch',
											text: (
												<>
													Create a new experiment and <br />
													ask your admin to turn it
													<br />
													into a template!
												</>
											),
											ctaText: 'Create from scratch',
											ctaOnClick: async () => {
												history.push('/experiments/edit/<new>/design/', await onCreateExperimentFromDraft());
											},
										}
							}
						/>
					</Suspense>
				)}

				{templatesResult.error ? (
					<></>
				) : isLoading ? (
					<LoadingCards />
				) : (
					templatesResult.value?.content.map((templatePreview) => (
						<TemplateCard
							key={templatePreview.id}
							template={templatePreview}
							onDetailsClick={() => {
								updateUrlState({ selectedTemplateId: templatePreview.id });
								ampli.experimentTemplateDetailsViewed({
									experiment_template_name: templatePreview.templateTitle,
									experiment_template_view_context: 'new_experiment',
								});
							}}
							onUseClick={() => {
								updateUrlState({ useTemplateId: templatePreview.id, selectedTemplateId: null });
								ampli.experimentTemplateUsed({ experiment_template_name: templatePreview.templateTitle });
							}}
							targetDefinitions={targetDefinitionsResult.value}
							actions={actionsResult.value}
						/>
					))
				)}
			</Container>
			<Pagination
				activePage={page.pageParams.page}
				totalPages={templatesResult.value?.totalPages}
				onClick={(newPage) => {
					updateUrlState({ page: newPage });
				}}
			/>
		</Stack>
	);
}

function LoadingCards(): ReactElement {
	return (
		<>
			<LoadingCard />
			<LoadingCard />
			<LoadingCard />
		</>
	);
}

function EmptyListContent({ isAnyFilterActive }: { isAnyFilterActive: boolean }): ReactElement {
	const onCreateExperimentFromDraft = useCreateExperimentFromScratch();
	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;
	const history = useHistory();

	return (
		<Stack alignItems="center">
			<div style={{ maxWidth: '750px', height: '450px' }}>
				<AstroidScreen
					title={
						<Text variant="xLargeStrong" color="slate">
							No Templates yet
						</Text>
					}
					icon={<IconTemplate variant="xxxLarge" color="slate" />}
					description={
						isAnyFilterActive ? (
							<Stack size="small">
								<Text variant="medium" color="neutral600" textAlign="center">
									The are no templates matching your search criteria.
								</Text>
							</Stack>
						) : (
							<Stack size="small">
								<Text variant="medium" color="neutral600" textAlign="center">
									The are no templates to choose from yet.
								</Text>
								<Text variant="medium" color="neutral600" textAlign="center">
									Create the first template or design an experiment from scratch.
								</Text>
								<Stack
									direction="horizontal"
									size="xLarge"
									justifyContent={canCreateTemplates ? 'space-between' : 'center'}
								>
									{canCreateTemplates && (
										<RouterButton variant="secondarySmall" to="/settings/templates/design/<new>">
											Create your first template
										</RouterButton>
									)}
									<Button
										variant="primarySmall"
										onClick={async () => {
											history.push('/experiments/edit/<new>/design/', await onCreateExperimentFromDraft());
										}}
									>
										Create experiment from scratch
									</Button>
								</Stack>
							</Stack>
						)
					}
				/>
			</div>
		</Stack>
	);
}
