/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Heading, Button, Text, Container, ContainerProps, SvgCircle } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { theme } from 'styles.v2/theme';
import React from 'react';

import { IconCheck } from '../icons';

export interface SidebarWizardStepProps extends ContainerProps {
	name: string;
	number?: number;
	checked?: boolean;
	active?: boolean;
	onStepClick?: React.MouseEventHandler;
	inactiveChildren?: React.ReactNode;
}

export const WizardSidebarStep: React.FC<SidebarWizardStepProps> = ({
	children,
	name,
	number = 1,
	checked = false,
	active = false,
	onStepClick = () => {},
	inactiveChildren = [],
}) => {
	const fill = active ? 'slate' : checked ? 'cyanDark' : 'neutral200';
	const borderColor = active ? 'slate' : checked ? 'cyanDark' : 'neutral300';

	return (
		<>
			<Container display={'flex'} py={'xxSmall'} alignItems={'center'} flexDirection={'row'}>
				<Container flex={'0 0 auto'} sx={{ position: 'relative', height: 32, width: 32 }}>
					<svg height={'32'} width={'32'}>
						<SvgCircle r={active ? 16 : 14} cx={16} cy={16} sx={{ fill }} />
					</svg>

					<Container
						display={'flex'}
						color={checked || active ? 'neutral000' : 'neutral600'}
						alignItems={'center'}
						justifyContent={'center'}
						sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
					>
						{checked ? <IconCheck /> : <Text variant={active ? 'mediumStrong' : 'smallStrong'}>{number}</Text>}
					</Container>
				</Container>
				<Button as={'button'} onClick={onStepClick} ml={14} variant={'chromeless'} type={'button'}>
					<motion.span layout transition={{ duration: 0.1 }}>
						{active ? (
							<Heading variant={'small'} color={'neutral800'}>
								{name}
							</Heading>
						) : (
							<Text variant={'medium'} color={checked ? 'neutral800' : 'neutral600'}>
								{name}
							</Text>
						)}
					</motion.span>
				</Button>
			</Container>

			<Container
				display={'flex'}
				ml={15}
				pl={31}
				py={'small'}
				my={'xxSmall'}
				flex={'0 0 auto'}
				maxHeight={checked || active ? 900 : 24}
				sx={{
					// Using background gradient as dashed line, to have more
					// control over the gap between each dash
					backgroundImage: `linear-gradient(${theme.colors[borderColor]} 50%, ${
						checked || active ? theme.colors[borderColor] : 'rgba(255,255,255,0)'
					} 0%)`,
					backgroundSize: '2px 16px',
					backgroundPosition: 'left top',
					backgroundRepeat: 'repeat-y',
					overflow: 'hidden',
					...(checked || active
						? {}
						: {
								':last-of-type': {
									backgroundImage: 'none',
								},
							}),
				}}
			>
				<Text variant={'medium'} color={'neutral800'} sx={{ opacity: checked || active ? 1 : 0 }}>
					<AnimatePresence initial={false}>
						{checked && !active && (
							<motion.div
								key={'one'}
								style={{ overflow: 'hidden', willChange: 'opacity' }}
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: 'auto', opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
								transition={{ duration: 0.35, ease: 'easeOut' }}
							>
								<Container display={'flex'} flexDirection={'row'} flexWrap={'wrap'} mb={-4}>
									{inactiveChildren}
								</Container>
							</motion.div>
						)}

						{active && (
							<motion.div
								key={'two'}
								style={{ overflow: 'hidden' }}
								initial={{ height: 0, opacity: 0 }}
								animate={{ height: 'auto', opacity: 1 }}
								exit={{ height: 0, opacity: 0 }}
								transition={{ duration: 0.35, ease: 'easeOut' }}
							>
								{children}
							</motion.div>
						)}
					</AnimatePresence>
				</Text>
			</Container>
		</>
	);
};
