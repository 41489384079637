/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { getActionsAndTargets } from 'pages/templates/FromTemplateModal/TemplateDetails/TemplateDetails';
import { BaseExperimentStepVOUnion, ExperimentStepActionVO, TargetTypeDescriptionVO } from 'ui-api';
import ExperimentPreview from 'templates/components/TemplateCard/ExperimentPreview';
import { getColors, getIcon, getLabel } from 'pages/experiments/components/utils';
import Actions from 'pages/templates/FromTemplateModal/TemplateDetails/Actions';
import Targets from 'pages/templates/FromTemplateModal/TemplateDetails/Targets';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import Tags from 'pages/templates/components/TemplateTags';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { convertDurationToSeconds } from 'utils/duration';
import { IconClose, IconNewTab } from 'components/icons';
import Markdown from 'components/Markdown/Markdown';
import { usePromise } from 'utils/hooks/usePromise';
import { Target } from 'pages/components/Target';
import { Services } from 'services/services';
import { Link, Pill } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import ActionIconWithBorder from './ActionIconWithBorder';
import { ActionCategoryItem } from './types';
import useActions from '../useActions';

export interface SelectedEntity {
	action?: ActionCategoryItem;
	templateId?: string;
}

interface SelectedEntityPopupProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	selectedEntity: SelectedEntity;
	onClose: () => void;
}

export default function SelectedEntityPopup({
	targetDefinitions,
	selectedEntity,
	onClose,
}: SelectedEntityPopupProps): ReactElement | null {
	if (!selectedEntity.action && !selectedEntity.templateId) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				top: '137px',
				bottom: '24px',
				left: 'calc(100% - 8px)',
				pointerEvents: 'none',
			}}
		>
			<Flex
				spacing="small"
				style={{
					backgroundColor: theme.colors.neutral000,
					boxShadow: '0px 0px 7px 5px rgba(0, 0, 0, 0.1)',
					minWidth: '330px',
					maxWidth: '330px',
					maxHeight: '100%',
					pointerEvents: 'auto',
				}}
			>
				<IconClose
					variant="medium"
					sx={{
						position: 'absolute',
						top: '6px',
						right: '6px',
						cursor: 'pointer',
						':hover': { color: theme.colors.neutral500 },
					}}
					onClick={onClose}
				/>
				<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
					{selectedEntity.action?.id && (
						<ActionDetails action={selectedEntity.action} targetDefinitions={targetDefinitions} />
					)}
					{selectedEntity.templateId && (
						<TemplateDetails templateId={selectedEntity.templateId} targetDefinitions={targetDefinitions} />
					)}
				</ErrorBoundary>
			</Flex>
		</div>
	);
}

interface TemplateDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	templateId: string;
}

function TemplateDetails({ targetDefinitions, templateId }: TemplateDetailsProps): ReactElement {
	const { allActions } = useActions();
	const templateResult = usePromise(() => Services.templatesApi.getTemplate(templateId), [templateId]);

	if (!templateResult.value) {
		return (
			<Flex align="center" justify="center" style={{ width: 'calc(100% - 48px)', padding: '32px 24px 24px' }}>
				<LoadingIndicator color="slate" />
			</Flex>
		);
	}

	const template = templateResult.value;
	const [containingActions, containingTargets] = getActionsAndTargets(template);

	return (
		<Flex spacing="small" style={{ padding: '36px 24px 24px 24px', overflow: 'hidden' }}>
			<ExperimentPreview
				width={270}
				lanes={template.lanes.map((lane) => ({
					...lane,
					steps: lane.steps.map((step) => ({
						...step,
						actionId: isExperimentStepActionVO(step) ? step.actionId : 'wait',
						durationInSeconds: convertDurationToSeconds(step.parameters.duration) || 0,
					})),
				}))}
				actions={allActions}
				disabled={false}
			/>
			<Tags tags={template.tags} small />

			{/* To get the scrollbar on the right place */}
			<Flex spacing="small" style={{ width: '278px', overflowY: 'auto', margin: '0 -20px', padding: '0 24px' }}>
				<Text type="mediumStrong">{template.templateTitle}</Text>
				<Markdown content={template.templateDescription} small />
				<Flex spacing="xSmall">
					<Targets targets={containingTargets} targetDefinitions={targetDefinitions} />
					<Actions actions={containingActions} actionDefinitions={allActions} />
				</Flex>
			</Flex>

			{template.hubLink && <DetailLink href={`${template.hubLink}/templateRedirect/${template.id}`} />}
		</Flex>
	);
}

function isExperimentStepActionVO(step: BaseExperimentStepVOUnion): step is ExperimentStepActionVO {
	return (step as ExperimentStepActionVO).actionId !== undefined;
}

interface ActionDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	action: ActionCategoryItem;
}

function ActionDetails({ action, targetDefinitions }: ActionDetailsProps): ReactElement {
	const actionResult = usePromise(() => Services.actions.findAction(action.id), [action.id]);

	if (actionResult.loading) {
		return (
			<Flex align="center" justify="center" style={{ width: 'calc(100% - 48px)', padding: '32px 24px 24px' }}>
				<LoadingIndicator color="slate" />
			</Flex>
		);
	}

	const actionVO = actionResult.value;
	if (!actionVO || !actionVO.hubSummary) {
		return (
			<Flex spacing="small" style={{ width: 'calc(100% - 48px)', padding: '24px' }}>
				<Flex direction="horizontal" spacing="xSmall">
					<ActionIconWithBorder action={actionVO} />
				</Flex>

				<Text type="mediumStrong">{action.label}</Text>

				<Text type="medium">{actionVO ? actionVO.description : 'No description available'}</Text>
			</Flex>
		);
	}

	const { backgroundColor, color } = getColors(actionVO.kind);
	const Icon = getIcon(actionVO.kind);

	return (
		<Flex spacing="small" style={{ padding: '24px', overflow: 'hidden' }}>
			<Flex direction="horizontal" align="center" spacing="xSmall">
				<Text type="small">Action kind:</Text>
				<Pill
					backgroundColor={backgroundColor}
					color={color}
					sx={{
						width: 'fit-content',
						borderRadius: '4px',
						fontSize: '12px',
						p: '4px 8px',
						...textEllipsis,
						textTransform: 'capitalize',
					}}
				>
					<Icon mr="xxSmall" />
					{getLabel(actionVO.kind)}
				</Pill>
			</Flex>

			<Flex direction="horizontal" align="center" spacing="xSmall">
				<ActionIconWithBorder action={actionVO} />
				<Tags tags={actionVO.hubTags} small />
			</Flex>

			{/* To get the scrollbar on the right place */}
			<Flex spacing="small" style={{ width: '282px', overflowY: 'auto', margin: '0 -24px', padding: '0 24px ' }}>
				<Text type="mediumStrong">{action.label}</Text>

				<Markdown content={actionVO.hubSummary} small />

				{'target' in actionVO && actionVO.target.type && (
					<Flex direction="horizontal" align="center" spacing="xSmall">
						<Text type="smallStrong" style={{ color: theme.colors.neutral600 }}>
							Target:
						</Text>
						<Target targetId={actionVO.target.type} targetDefinitions={targetDefinitions} />
					</Flex>
				)}
			</Flex>

			{actionVO.hubLink && <DetailLink href={actionVO.hubLink} />}
		</Flex>
	);
}

function DetailLink({ href }: { href: string }): ReactElement {
	return (
		<Link href={href} external>
			<Text type="smallStrong">More details</Text>
			<IconNewTab variant="small" ml="xxSmall" />
		</Link>
	);
}
