/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { TargetAttributeDescriptionVO, TargetPredicateVO, TargetTypeDescriptionVO, TargetVO } from 'ui-api';
import { EmptyListContentProps, TargetsTable } from 'targets/TableView/TargetsTable';
import { debouncedFetchTargets as fetchTargets } from 'targets/fetch';
import { ExperimentFormValues } from 'pages/experiments/experiment';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import { createResult } from 'utils/hooks/stream/result';
import { Order, PageParams } from 'utils/hooks/usePage';
import { usePromise } from 'utils/hooks/usePromise';
import { ReactElement, useState } from 'react';
import { getInitialSort } from 'targets/util';
import { useUpdateEffect } from 'react-use';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';

interface TargetViewTableProps {
	EmptyResult?: (props: EmptyListContentProps) => ReactElement;
	attributeDefinitions: TargetAttributeDescriptionVO[];
	getTargetDetailHref?: (target: TargetVO) => string;
	targetDefinition: TargetTypeDescriptionVO;
	framePadding?: 'none' | 'medium';
	predicate?: TargetPredicateVO;
	targetsPerPage?: number;
	environmentId?: string;
	borderless?: boolean;
	framed?: boolean;
	query: string;
}

export function TargetViewTable({
	framePadding = 'none',
	attributeDefinitions,
	getTargetDetailHref,
	targetsPerPage = 10,
	targetDefinition,
	framed = false,
	environmentId,
	EmptyResult,
	borderless,
	predicate,
	query,
}: TargetViewTableProps): JSX.Element {
	const predicateString = toPredicateString(predicate);
	const initialSort: Order[] = getInitialSort(targetDefinition);
	const [page, setPage] = useState(() => new PageParams(0, targetsPerPage, initialSort));
	useUpdateEffect(() => setPage((p) => p.withPage(0)), [query, targetDefinition.id, predicateString]);
	useUpdateEffect(() => setPage((p) => p.withSort(initialSort)), [initialSort]);
	const variables = useFormikContext<ExperimentFormValues>().values.variables;

	const fetchResult = usePromise(
		() =>
			fetchTargets({
				targetDefinition,
				page,
				query,
				environmentId: environmentId,
				predicate,
				variables,
			}),
		[environmentId, page, query, predicate],
	);

	const table = (
		<TargetsTable
			getTargetDetailHref={getTargetDetailHref}
			targetDefinition={createResult(targetDefinition)}
			attributeDefinitions={createResult(attributeDefinitions)}
			onChangeSort={(sort: Order[]) => setPage(() => page.withSort(sort))}
			onChangePage={(p: number) => setPage(() => page.withPage(p))}
			fetchResult={fetchResult}
			EmptyListResult={EmptyResult}
			borderless={borderless}
			sort={page.sort}
			page={page.page}
			query={query}
		/>
	);

	if (!framed) {
		return table;
	}

	return (
		<Container
			sx={{
				px: framePadding === 'none' ? '0' : 'medium',
				pt: framePadding === 'none' ? '0' : 'xSmall',
				pb: 'small',
				background: 'neutral000',
				borderLeft: '1px solid ' + theme.colors.neutral300,
				borderRight: '1px solid ' + theme.colors.neutral300,
				borderBottom: '1px solid ' + theme.colors.neutral300,
			}}
		>
			{table}
		</Container>
	);
}
