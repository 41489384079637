/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import { useField, useFormikContext } from 'formik';
import { IconDescription } from 'components/icons';
import React, { useEffect, useState } from 'react';
import { TextField } from 'components';

import { ExperimentFormValues } from '../../experiment';

export const ExperimentSubHeaderHypthesis: React.VFC<{
	disabled: boolean;
}> = ({ disabled }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue, setFieldTouched } = formik;
	const { hypothesis } = formik.values;

	const [hypothesisEdit, setHypothesisEdit] = useState<string | undefined>(undefined);
	const [inititalHypothesis, setInitialHypothesis] = useState<string | undefined>(hypothesis);
	useEffect(() => setInitialHypothesis(hypothesis), [hypothesis]);

	const [, meta] = useField('hypothesis');
	const hasError = Boolean(meta.error);

	return (
		<DropdownPill
			Icon={IconDescription}
			hasError={hasError}
			value={hypothesis || 'No Hypothesis'}
			sx={{ maxWidth: 400, overflow: 'hidden', textOverflow: 'ellipsis' }}
			placement="bottom-start"
			onOpen={() => setHypothesisEdit(hypothesis)}
			onClose={() => {
				setFieldValue('hypothesis', hypothesisEdit, false);
				setFieldTouched('hypothesis', true);
				setHypothesisEdit(undefined);
			}}
		>
			{() => (
				<TextField
					sx={{ minHeight: 150, minWidth: 400 }}
					color={'neutral700'}
					as="textarea"
					value={hypothesisEdit}
					placeholder="What outcome do you expect from this experiment?"
					variant={'small'}
					pl={'xSmall'}
					onChange={(e) => setHypothesisEdit(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Escape' || e.key === 'Esc') {
							setHypothesisEdit(inititalHypothesis);
							e.target.dispatchEvent(new Event('dropdown_close', { bubbles: true }));
							e.stopPropagation();
							e.preventDefault();
						}
					}}
					maxLength={20_000}
					readOnly={disabled}
				/>
			)}
		</DropdownPill>
	);
};
