/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconCalendar, IconIntegrations, IconSlack, IconWebhook, IconWebhookPreflight } from 'components/icons';
import { ModalOverlay, Tab, TabList, TabPanel, Tabs } from 'components';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import ListHeader from 'components/List/presets/ListHeader';
import useFeatureFlag from 'services/useFeatureFlag';
import { Route, Switch } from 'url/router';
import { useHistory } from 'url/hooks';
import { TUTORIALS } from 'tutorials';
import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import EditWebhook from './webhooks/editWebhook';
import WebhookList from './webhooks/webhookList';
import AddWebhook from './webhooks/addWebhook';
import IcalSettings from './ical/IcalSettings';
import HelpText from '../components/HelpText';
import { WebhookVO } from '../../../ui-api';

const Integrations: React.VFC<{ active?: string }> = ({ active }) => {
	const preflightWebhooksEnabled = useFeatureFlag('webhookPreflight');
	const icalAccessFeatureEnabled = useFeatureFlag('icalAccess');
	const history = useHistory();

	function getWebhookAddTitle(active: string | undefined): string | undefined {
		switch (active) {
			case 'slack':
				return 'Add Slack Integration';
			case 'webhookPreflight':
				return 'Add Preflight Webhook';
			default:
				return undefined;
		}
	}

	function getWebhookEditTitle(active: string | undefined): ((webhook?: WebhookVO) => string) | undefined {
		switch (active) {
			case 'slack':
				return (webhook) => `Edit Slack Integration ${webhook?.name}`;
			case 'webhookPreflight':
				return (webhook) => `Edit Preflight Webhook ${webhook?.name}`;
			default:
				return undefined;
		}
	}

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Integrations" Icon={IconIntegrations} />}
				description={<HelpText>{TUTORIALS.settings.integrations.children}</HelpText>}
			/>

			<Tabs onChange={(target) => history.push(target)} value={active}>
				<TabList>
					<Tab value={'slack'}>
						<IconSlack size={'small'} mr={'xSmall'} ml={-8} /> Slack
					</Tab>
					{preflightWebhooksEnabled && (
						<Tab value={'webhookPreflight'}>
							<IconWebhookPreflight size={'small'} mr={'xSmall'} ml={-8} />
							Preflight Webhooks
						</Tab>
					)}
					<Tab value={'webhook'}>
						<IconWebhook size={'small'} mr={'xSmall'} ml={-8} />
						Custom Webhooks
					</Tab>
					{icalAccessFeatureEnabled && (
						<Tab value={'icalAccess'}>
							<IconCalendar size={'small'} mr={'xSmall'} ml={-8} />
							iCal Access
						</Tab>
					)}
				</TabList>
				<TabPanel value={'slack'}>
					<WebhookList
						type={active}
						pathSegment="/slack"
						onCreate={() => '/settings/integrations/slack/create'}
						onEdit={(id) => `/settings/integrations/slack/${id}`}
						labelCreateWebhook={'Add Slack Integration'}
						labelNoData={'No Slack Integrations found'}
					/>
				</TabPanel>
				<TabPanel value={'webhook'}>
					<WebhookList
						type={active}
						pathSegment="/webhook"
						onCreate={() => '/settings/integrations/webhook/create'}
						onEdit={(id) => `/settings/integrations/webhook/${id}`}
					/>
				</TabPanel>
				{preflightWebhooksEnabled && (
					<TabPanel value={'webhookPreflight'}>
						<WebhookList
							type={active}
							pathSegment="/webhookPreflight"
							onCreate={() => '/settings/integrations/webhookPreflight/create'}
							onEdit={(id) => `/settings/integrations/webhookPreflight/${id}`}
						/>
					</TabPanel>
				)}
				{icalAccessFeatureEnabled && (
					<TabPanel value={'icalAccess'}>
						<IcalSettings />
					</TabPanel>
				)}
			</Tabs>
			<Switch>
				<Route path={'/settings/integrations/:active(slack|webhook|webhookPreflight)/create'}>
					{({ match }) => (
						<ModalOverlay open={Boolean(match)} onClose={() => history.push(`/settings/integrations/${active}`)}>
							{({ close }) => (
								<AddWebhook
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									type={match!.params.active}
									onClose={close}
									labelTitle={getWebhookAddTitle(active)}
								/>
							)}
						</ModalOverlay>
					)}
				</Route>
				<Route exact path={'/settings/integrations/:active(slack|webhook|webhookPreflight)/:id'}>
					{({ match }) => (
						<ModalOverlay
							open={!!match && match.params.id !== 'create'}
							onClose={() => history.push(`/settings/integrations/${active}`)}
						>
							{match
								? ({ close }) => (
										<EditWebhook onClose={close} id={match.params.id} labelTitle={getWebhookEditTitle(active)} />
									)
								: null}
						</ModalOverlay>
					)}
				</Route>
			</Switch>
		</ContentWrapper>
	);
};

export default Integrations;
