/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

interface Build {
	version: string;
	time: string;
}

export interface ServerInfo {
	build?: Build;
}

export class ServerInfoApi {
	async fetchServerInfo(): Promise<ServerInfo | null> {
		try {
			return (await axios.get('/ui/info')).data as ServerInfo;
		} catch {
			return null;
		}
	}
}
