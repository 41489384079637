/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Language } from 'prism-react-renderer';
import ReactMarkdown from 'react-markdown';
import { Code, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import remarkGfm from 'remark-gfm';

import './Markdown.css';

interface MarkdownProps {
	small?: boolean;
	content: string;
}

export default function Markdown({ small, content }: MarkdownProps): ReactElement | null {
	if (!content) {
		return null;
	}

	return (
		<Text
			sx={{
				maxWidth: '100%',
				fontSize: 'small',
				lineHeight: '140%',
				'& p': {
					margin: 0,
				},
			}}
		>
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={{
					h1(props) {
						return (
							<Text
								as="h1"
								variant={small ? 'smallStrong' : 'heading'}
								color="neutral800"
								{...props}
								mt="small"
								mb="xxSmall"
							/>
						);
					},
					h2(props) {
						return (
							<Text
								as="h2"
								variant={small ? 'smallStrong' : 'largeStrong'}
								color="neutral800"
								{...props}
								mt="xSmall"
								mb="xxSmall"
							/>
						);
					},
					h3(props) {
						return (
							<Text
								as="h3"
								variant={small ? 'smallStrong' : 'mediumStrong'}
								color="neutral800"
								{...props}
								mt="xSmall"
								mb="xxSmall"
							/>
						);
					},
					h4(props) {
						return <Text as="h4" variant="smallStrong" color="neutral800" {...props} mt="xSmall" mb="xxSmall" />;
					},
					h5(props) {
						return <Text as="h5" variant="smallStrong" color="neutral800" {...props} mt="xSmall" mb="xxSmall" />;
					},
					h6(props) {
						return <Text as="h6" variant="smallStrong" color="neutral800" {...props} mt="xSmall" mb="xxSmall" />;
					},
					a(props) {
						return (
							<a {...props} target="_blank" rel="noreferrer">
								<Text variant="small" as="span" color="slate">
									{props.children}
								</Text>
							</a>
						);
					},
					em(props) {
						return (
							<Text
								as="span"
								variant="smallStrong"
								color="neutral800"
								backgroundColor="neutral100"
								px="xxSmall"
								py="2px"
								{...props}
							/>
						);
					},
					code(props) {
						let highlightActive = false;
						const codeContent = props.children[0] as string;
						const language = (props.className?.replace('language-', '') as Language) || 'markdown';
						return (
							<Code
								{...props}
								withCopyToClipboard
								getContentToCopyOrDownload={() => {
									return codeContent.replace(/% startHighlight %|% endHighlight %/g, '').replace(/^\s*\n/gm, '');
								}}
								lang={language}
								getTokenStyle={() => ({
									color: highlightActive ? theme.colors.coral : theme.colors.neutral800,
								})}
								getLineStyle={(tokens) => {
									const line = tokens.join('').trim();
									if (!line) {
										return {
											display: 'none',
										};
									}
									if (line === '% startHighlight %') {
										highlightActive = true;
										return {
											display: 'none',
										};
									} else if (line === '% endHighlight %') {
										highlightActive = false;
										return {
											display: 'none',
										};
									}
									return {
										color: theme.colors.neutral800,
										fontFamily: 'monospace',
										fontSize: '12px',
										lineHeight: '20px',
									};
								}}
								sx={{
									border: '1px dashed ' + theme.colors.neutral300,
									borderRadius: 4,
								}}
							>
								{codeContent}
							</Code>
						);
					},
					table(props) {
						return (
							<table className="md_table" {...props} style={{ width: '100%', borderCollapse: 'collapse' }}>
								{props.children}
							</table>
						);
					},
				}}
			>
				{content.replaceAll('<br/>', '&nbsp;\n')}
			</ReactMarkdown>
		</Text>
	);
}
