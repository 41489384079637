/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Pill, Tooltip } from 'components';
import { Stack } from 'components/Stack';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';

interface EnvVariablesProps {
	variables: VariableVO[];
	withHelpText?: boolean;
	onVariableClick: (variableKey: string) => void;
}

export default function EnvVariables({ variables, onVariableClick }: EnvVariablesProps): ReactElement | null {
	if (variables.length === 0) {
		return null;
	}

	return (
		<Container p={'small'}>
			<Stack direction="vertical" size="xSmall" alignItems="flex-start">
				{variables.map((variable) => {
					return (
						<Tooltip key={variable.key} content={variable.value}>
							<div>
								<Pill
									backgroundColor="slateMidLight40p"
									backgroundColorOnHover="slateMidLight"
									color="neutral800"
									onClick={() => onVariableClick(variable.key)}
								>
									{`{{${variable.key}}}`}
								</Pill>
							</div>
						</Tooltip>
					);
				})}
			</Stack>
		</Container>
	);
}
