/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import { ReactElement, useState } from 'react';

import { SidebarActionMaker, WorkspaceLaneMaker, correctDropAnimation } from '../DragAndDropHandler';
import useIsExperimentDisabled from '../useIsExperimentDisabled';
import { ActionCategoryItem } from './types';
import useActions from '../useActions';
import Action from './Action';

interface ActionsProps {
	actions?: ActionCategoryItem[];
	selectedActionId?: string;
	collapsed?: boolean;
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function Actions({
	selectedActionId,
	collapsed,
	actions,
	onActionClick,
}: ActionsProps): ReactElement | null {
	const [fixedId] = useState(Math.random());
	const disabled = useIsExperimentDisabled();
	const { actions: availableActions } = useActions();

	if (!actions) {
		return null;
	}

	return (
		<Droppable droppableId={'droppable' + fixedId} isDropDisabled type="steps">
			{(droppableProvided) => (
				<div
					ref={droppableProvided.innerRef}
					style={{
						display: collapsed ? 'flex' : 'grid',
						gridTemplateColumns: collapsed ? undefined : '1fr 1fr	',
						flexDirection: 'column',
						alignItems: collapsed ? 'center' : undefined,
						width: '100%',
						gap: '4px',
					}}
				>
					{actions.map((_action, i) => {
						const { id, label } = _action;
						const action = availableActions.find((a) => a.id === id);
						if (disabled) {
							return <Action key={id} label={label} action={action} />;
						}

						return (
							<Draggable key={SidebarActionMaker + id} draggableId={SidebarActionMaker + id} index={i}>
								{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
									const isDraggingOverLane: boolean =
										dragSnapshot.draggingOver && dragSnapshot.draggingOver.startsWith(WorkspaceLaneMaker)
											? true
											: false;

									const isDragging = dragSnapshot.isDragging;
									if (isDragging && selectedActionId) {
										onActionClick(null);
									}

									return (
										<div
											ref={dragProvided.innerRef}
											{...dragProvided.draggableProps}
											{...dragProvided.dragHandleProps}
											style={correctDropAnimation(dragSnapshot, dragProvided.draggableProps.style)}
											onClick={isDragging ? undefined : () => onActionClick(_action)}
										>
											<Action
												key={id}
												isDraggingOverLane={isDraggingOverLane}
												isSelected={selectedActionId === id}
												isDragging={isDragging}
												collapsed={collapsed}
												action={action}
												label={label}
											/>
										</div>
									);
								}}
							</Draggable>
						);
					})}

					{droppableProvided.placeholder}
				</div>
			)}
		</Droppable>
	);
}
