/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { EditorSettingsContext } from 'pages/experimentsV2/useEditorSettings';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import { TemplatesContext } from 'pages/experimentsV2/useTemplates';
import { ActionsContext } from 'pages/experimentsV2/useActions';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { Stack } from 'components';
import { useField } from 'formik';

import WelcomeToTemplatesModal from '../WelcomeToTemplatesModal/WelcomeToTemplatesModal';
import TemplatePlaceholdersContent from './TemplatePlaceholdersContent';
import TemplateDescriptionContent from './TemplateDescriptionContent';
import TemplateExperimentContent from './TemplateExperimentContent';
import { UrlState, selectedViewParam } from './urlParams';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import TemplateSubHeader from './TemplateSubHeader';
import TemplateHeader from './TemplateHeader';

export default function TemplateEditor(): ReactElement {
	const actions = usePromise(() => Services.actions.fetchActions(), []);
	const [{ selectedView }] = useUrlState<UrlState>([selectedViewParam]);

	const [{ value: teamId }] = useField<string>('teamId');

	const templatesResult = useRefreshingTemplates({
		searchContext: 'NEW_EXPERIMENT',
		pathname: '/not-used',
		pageSize: 1_000_000,
		teamId,
	});

	return (
		<ActionsContext.Provider value={{ actions: actions.value || [], allActions: actions.value || [] }}>
			<TemplatesContext.Provider value={{ templates: templatesResult.value?.content || [] }}>
				<UnsavedChangesPrompt />
				<Stack size="none">
					<TemplateHeader />
					<TemplateSubHeader />
					<WelcomeToTemplatesModal />
					<EditorSettingsContext.Provider value={{ mode: 'templateEditor' }}>
						{selectedView === 'editor' && <TemplateExperimentContent />}
						{selectedView === 'placeholders' && <TemplatePlaceholdersContent />}
						{selectedView === 'description' && <TemplateDescriptionContent />}
					</EditorSettingsContext.Provider>
				</Stack>
			</TemplatesContext.Provider>
		</ActionsContext.Provider>
	);
}
