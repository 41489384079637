/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement } from 'react';
import { Text } from 'components';

interface HeadlineProps {
	title: string;
}

export default function Headline({ title }: HeadlineProps): ReactElement {
	return (
		<Text as="h2" variant="xLargeStrong" sx={{ ...textEllipsis }}>
			{title}
		</Text>
	);
}
