/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import { Input } from './Text';

interface TextArrayProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string[];
	setValue: (value: string[]) => void;
}

export default function TextArray({ value, disabled, hasErrors, setValue }: TextArrayProps): ReactElement {
	return (
		<Input
			value={parse(value)}
			onChange={(v) => {
				setValue(serialize(v));
			}}
			disabled={disabled}
			hasErrors={hasErrors}
		/>
	);
}

function parse(value: string | string[]): string {
	try {
		return Array.isArray(value) ? value.join(',') : typeof value === 'string' ? value : '';
	} catch {
		return '';
	}
}

function serialize(value: string | string[]): string[] {
	if (Array.isArray(value)) {
		return value;
	}
	const v = value.split(',').map((v) => v.trim());
	if (v.join('') === '') {
		return [];
	}
	return v;
}
