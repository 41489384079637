/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import {
	BasicStyleProperties,
	InteractionTypes,
	getAriaAndDataProps,
	resolveFlex,
	resolveStyleProp,
	resolveSx,
	resolveTxAndVariant,
} from 'components/types';
import React, { ChangeEventHandler, Ref } from 'react';
import { Box } from 'theme-ui';

export interface ContainerProps extends BasicStyleProperties, InteractionTypes<HTMLDivElement> {
	display?: 'inline-flex' | 'flex' | 'block' | 'grid';
	flex?: string;
	flexBasis?: string;
	flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
	flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
	flexGrow?: number;
	flexShrink?: number;
	alignItems?:
		| 'baseline'
		| 'center'
		| 'flex-center'
		| 'flex-end'
		| 'flex-start'
		| 'left'
		| 'right'
		| 'start'
		| 'stretch';
	alignSelf?: 'left' | 'center' | 'right' | 'flex-start';
	justifyItems?: 'center';
	justifyContent?: 'center' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end';
	textAlign?: 'left' | 'center' | 'right';

	as?: React.ElementType;
	variant?: string;
	size?: string | number;
	tx?: string;

	min?: number;
	max?: number;
	maxLength?: number;

	autoComplete?: string;
	placeholder?: string;
	children?: React.ReactNode | string;
	disabled?: boolean;

	ref?: Ref<HTMLElement>;
	href?: string;
	to?: string;

	type?: string;
	title?: string;
	value?: string | number;
	role?: string;
	checked?: boolean;
	autoFocus?: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Container = React.forwardRef<HTMLElement, ContainerProps>(
	(
		{
			children,

			onBlur,
			onClick,
			onFocus,
			onKeyDown,
			onMouseEnter,
			onMouseMove,
			onPointerDown,
			onMouseLeave,
			onMouseDown,
			onMouseUp,
			onTouchStart,
			onTouchEnd,
			min,
			max,
			maxLength,
			display,
			flex,
			flexBasis,
			flexDirection,
			flexWrap,
			flexGrow,
			flexShrink,
			alignItems,
			alignSelf,
			justifyItems,
			justifyContent,
			textAlign,
			as,
			size,
			placeholder,
			disabled,
			type,
			title,
			value,
			role,
			checked,
			onChange,
			id,
			autoComplete,
			className,
			name,
			backgroundColor,
			bg,
			color,
			height,
			minHeight,
			maxHeight,
			width,
			minWidth,
			maxWidth,
			m,
			mb,
			ml,
			mr,
			mt,
			mx,
			my,
			p,
			pb,
			pl,
			pr,
			pt,
			px,
			py,
			overflow,
			overflowX,
			overflowY,
			opacity,
			tabIndex,
			style,
			href,
			to,
			autoFocus,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			colSpan,

			sx = {},
			tx,
			variant,

			...props
		},
		ref,
	) => {
		const remainingKnownProps = {
			onBlur,
			onClick,
			onFocus,
			onKeyDown,
			onMouseEnter,
			onMouseMove,
			onPointerDown,
			onMouseLeave,
			onMouseDown,
			onMouseUp,
			onTouchStart,
			onTouchEnd,
			style,
			as,
			size,
			placeholder,
			disabled,
			type,
			tabIndex,
			title,
			value,
			role,
			checked,
			onChange,
			id,
			className,
			name,
			colSpan,
			autoFocus,
			autoComplete,
			href: href || to,
		};

		return (
			<Box
				ref={ref}
				// can exist on input fields
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				min={min}
				// can exist on input fields
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				max={max}
				maxLength={maxLength}
				sx={{
					backgroundColor,
					bg,
					color,
					colSpan,

					height: resolveStyleProp(height),
					minHeight: resolveStyleProp(minHeight),
					maxHeight: resolveStyleProp(maxHeight),
					width: resolveStyleProp(width),
					minWidth: resolveStyleProp(minWidth),
					maxWidth: resolveStyleProp(maxWidth),
					m: resolveStyleProp(m),
					mb: resolveStyleProp(mb),
					ml: resolveStyleProp(ml),
					mr: resolveStyleProp(mr),
					mt: resolveStyleProp(mt),
					mx: resolveStyleProp(mx),
					my: resolveStyleProp(my),
					p: resolveStyleProp(p),
					pb: resolveStyleProp(pb),
					pl: resolveStyleProp(pl),
					pr: resolveStyleProp(pr),
					pt: resolveStyleProp(pt),
					px: resolveStyleProp(px),
					py: resolveStyleProp(py),
					opacity: resolveStyleProp(opacity),

					overflow,
					overflowX,
					overflowY,
					display,
					flexDirection,
					flexWrap,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					flexBasis,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					flexGrow,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					flexShrink,
					alignItems,
					alignSelf,
					justifyItems,
					justifyContent,
					textAlign,
					autoComplete,
					...resolveFlex(flex),
					...resolveSx(sx),
				}}
				variant={resolveTxAndVariant(tx, variant)}
				{...remainingKnownProps}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				{...getAriaAndDataProps(props)}
			>
				{children}
			</Box>
		);
	},
);
Container.displayName = 'Container';
