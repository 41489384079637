/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Icon, getBackgroundStyle, getScaleFaktor } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import { ActionVO, TemplatePreviewLaneVO, TemplatePreviewStepVO } from 'ui-api';
import { ActionIcon } from 'hocs/ActionIcon';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface ExperimentSmallPreviewProps {
	lanes: TemplatePreviewLaneVO[];
	actions: ActionVO[];
}

export default function ExperimentSmallPreview({ lanes, actions }: ExperimentSmallPreviewProps): ReactElement {
	const widthOfPreview: number = 58;
	const pixelPerSecond: number = 1.75;
	const gapPerStep: number = 1;
	const scale = getScaleFaktor(lanes, widthOfPreview, pixelPerSecond, gapPerStep);

	const { neutral000, neutral300, experimentAttack } = theme.colors;

	return (
		<div
			style={{
				position: 'relative',

				minHeight: '48px',
				maxHeight: '48px',
				minWidth: '52px',
				maxWidth: '52px',

				backgroundColor: neutral000,
				border: '1px solid ' + neutral300,
				overflow: 'hidden',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
					overflow: 'hidden',
				}}
			>
				{lanes.slice(0, 4).map((lane, i) => (
					<Lane key={i} lane={lane} scale={scale} actions={actions} />
				))}
				{lanes.length > 4 && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '8px',
							borderRadius: '2px',
							backgroundColor: experimentAttack,
							width: '50px',
							padding: '0 1px',
						}}
					>
						<span
							style={{
								fontSize: '8px',
								color: neutral000,
								paddingBottom: '1px',
							}}
						>
							{`+${lanes.length - 4} more`}
						</span>
					</div>
				)}
			</div>
		</div>
	);
}

function Lane({
	actions,
	scale,
	lane,
}: {
	lane: TemplatePreviewLaneVO;
	actions: ActionVO[];
	scale: number;
}): ReactElement {
	return (
		<div style={{ display: 'flex', gap: '1px' }}>
			{lane.steps.map((step, i) => {
				const resolvedAction = actions.find((action) => action.id === step.actionId);
				return <Step key={i} step={step} scale={scale} action={resolvedAction} />;
			})}
		</div>
	);
}

function Step({
	action,
	scale,
	step,
}: {
	action: ActionVO | undefined;
	step: TemplatePreviewStepVO;
	scale: number;
}): ReactElement {
	const stepWidth = step.durationInSeconds * scale - 8;
	const type = action?.kind || step.actionId;

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				height: '8px',
				padding: '0 1px',

				background: getBackgroundStyle(type),
				minWidth: `${stepWidth}px`,
				maxWidth: `${stepWidth}px`,
				overflow: 'hidden',
			}}
		>
			{action ? (
				<ActionIcon
					id={action.id}
					color="neutral000"
					style={{
						minWidth: '6px',
						maxWidth: '6px',
						minHeight: '6px',
						maxHeight: '6px',
					}}
				/>
			) : (
				<Icon
					type={type}
					style={{
						minWidth: '6px',
						maxWidth: '6px',
						minHeight: '6px',
						maxHeight: '6px',
						color: 'neutral000',
					}}
				/>
			)}
		</div>
	);
}
