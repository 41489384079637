/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import StepsSidebar from 'pages/experimentsV2/StepsSidebar/StepsSidebar';
import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import DragAndDropHandler from 'pages/experimentsV2/DragAndDropHandler';
import Workspace from 'pages/experimentsV2/Workspace/Workspace';
import { ReactElement } from 'react';
import { Stack } from 'components';

import StepConfigurationSidebar from './StepConfigurationSidebar/StepConfigurationSidebar';

export default function TemplateExperimentContent(): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	// experiment header and subheader
	const height = applicationHeaderHeight + 65 + 56 + 60;

	return (
		<>
			{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
			<DragAndDropHandler>
				<Stack direction="horizontal" size="none">
					<StepsSidebar height={height} />
					<Workspace height={height} />
				</Stack>
			</DragAndDropHandler>

			<StepConfigurationSidebar top="175px" />
		</>
	);
}
