/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { getColors } from 'pages/experiments/components/utils';
import { Flex } from '@steadybit/ui-components-lib';
import { ActionIcon } from 'hocs/ActionIcon';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { ActionVO } from 'ui-api';

interface ActionIconWithBorderProps {
	action: ActionVO | undefined;
	small?: boolean;
}

export default function ActionIconWithBorder({ action, small }: ActionIconWithBorderProps): ReactElement {
	const { backgroundColor, color } = action?.kind
		? getColors(action.kind)
		: {
				backgroundColor: theme.colors.experimentWait,
				color: theme.colors.neutral000,
			};

	const size = small ? '24px' : '40px';
	const variant = small ? 'small' : 'large';

	return (
		<Flex
			align="center"
			justify="center"
			style={{
				minWidth: size,
				maxWidth: size,
				minHeight: size,
				maxHeight: size,
				borderRadius: 'xxSmall',
				backgroundColor,
			}}
		>
			{action && <ActionIcon variant={variant} id={action.id} color={color} />}
		</Flex>
	);
}
