/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { FormikTextField, Label, Stack, Text, Tooltip } from 'components';
import { WebhookInfoVO } from 'services/webhooksApi';
import { Box } from 'theme-ui';
import React from 'react';

import { IconInformationCircle } from '../../../../../components/icons';

interface WebhookParametersProps {
	type: WebhookInfoVO;
	disabled?: boolean;
}

export const WebhookParameters: React.VFC<WebhookParametersProps> = ({ type, disabled }) => {
	return (
		<>
			{type.parameters.map((parameter) => {
				return (
					<Box key={parameter.name}>
						<Stack direction={'horizontal'}>
							<Label variant={'mediumStrong'}>{parameter.label}</Label>
							{parameter.description ? (
								<Tooltip
									color={'light'}
									placement={'bottom'}
									content={
										<Text variant={'small'} maxWidth={'25em'}>
											{parameter.description}
										</Text>
									}
								>
									<div style={{ lineHeight: '16px' }}>
										<IconInformationCircle variant={'small'} color={'neutral400'} mt={2} />
									</div>
								</Tooltip>
							) : null}
						</Stack>
						<FormikTextField
							name={`${parameter.name}`}
							type={'text'}
							maxLength={255}
							autoComplete={'off'}
							disabled={disabled}
							required={parameter.required}
							defaultValue={parameter.defaultValue}
						/>
					</Box>
				);
			})}
		</>
	);
};
