/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { toPredicateString } from 'queryLanguage/parser/serializer';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import { ActionVO, ExperimentStepActionVO, FieldVO } from 'ui-api';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Services } from 'services/services';
import { Divider, Stack } from 'components';
import { partition, sortBy } from 'lodash';
import { ReactElement } from 'react';
import { useField } from 'formik';

import useIsExperimentDisabled from '../useIsExperimentDisabled';
import FieldWrapper from './Fields/FieldWrapper';
import Boolean from './Fields/Controls/Boolean';
import Fields from './Fields/Fields';
import Section from './Section';

interface ActionParametersProps {
	actionStep: ExperimentStepActionVO;
	action: ActionVO;
	stepPath: string;
}

export default function ActionParameters({ action, actionStep, stepPath }: ActionParametersProps): ReactElement {
	const { actionId, blastRadius } = actionStep;

	const [environmentIdField] = useField<string>({ name: 'environmentId' });
	const environmentId = environmentIdField.value;

	const predicateString: string = toPredicateString(blastRadius?.predicate);
	const [actionParametersResult] = useAsyncState(async () => {
		return actionId && environmentId
			? await Services.experiments.fetchActionParameters(actionId, environmentId, blastRadius?.predicate)
			: [];
	}, [environmentId, actionId, predicateString]);

	const allFields = actionParametersResult.value || [];

	const [normalFields, advancedFields] = orderAndPartitionFields(allFields);

	if (normalFields.length === 0 && advancedFields.length === 0) {
		return (
			<Stack size="small" width="100%" pt="6px" px="medium">
				<IgnoreFailureToggle stepPath={stepPath} />
			</Stack>
		);
	}

	return (
		<>
			<Section title={action.kind === 'ATTACK' ? 'Attack Settings' : 'Settings'}>
				<Stack size="small" width="100%" pt="6px">
					<Fields fields={normalFields} stepPath={stepPath} />

					{advancedFields.length > 0 ? (
						<>
							<Collapsible title="Additional Settings" contentPadding="small">
								<Stack size="small" width="100%" pt="6px" px="small">
									<Fields fields={advancedFields} stepPath={stepPath} />
								</Stack>
							</Collapsible>

							<Divider />

							<IgnoreFailureToggle stepPath={stepPath} />
						</>
					) : (
						<IgnoreFailureToggle stepPath={stepPath} />
					)}
				</Stack>
			</Section>
		</>
	);
}

function orderAndPartitionFields(fields: FieldVO[]): [FieldVO[], FieldVO[]] {
	const ordered = sortBy(
		fields.filter((f) => !f.hidden),
		['order'],
	);
	return partition(ordered, (f) => !f.advanced);
}

function IgnoreFailureToggle({ stepPath }: { stepPath: string }): ReactElement {
	const disabled = useIsExperimentDisabled();
	const [ignoreFailureField, , { setValue, setTouched }] = useField<boolean>(`${stepPath}.ignoreFailure`);

	return (
		<FieldWrapper
			field={{
				name: 'boolean',
				type: 'boolean',
				required: true,
				label: 'Continue on any failures / errors',
				description: 'If this step fails / errors, the experiment will continue.',
			}}
		>
			<Boolean
				value={ignoreFailureField.value}
				setValue={(_v) => {
					setValue(_v);
					setTouched(true);
				}}
				disabled={disabled}
			/>
		</FieldWrapper>
	);
}
