/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2, RouterButton, Stack, Text } from 'components';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { PageLocation, usePage } from 'utils/hooks/usePage';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { IconAdd } from 'components/icons';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

import {
	includeNonPermittedParam,
	newExperimentTagsParam,
	pageParam,
	selectedTemplateIdParam,
	UrlState,
	useTemplateIdParam,
} from './urlParams';
import useCreateExperimentFromScratch from '../../experimentsV2/create/useCreateExperimentFromScratch';
import UseTemplateModal from '../UseTemplateModal/UseTemplateModal';
import FromTemplateDetailsModal from './TemplateDetailsModal';
import IncludePermittedToggle from './IncludePermittedToggle';
import SearchBar from '../../components/SearchBar/SearchBar';
import TemplateList from './TemplateList';

export const useFromTemplatePage = (): PageLocation =>
	usePage('/from_templates', { size: 14, sort: [['templateTitle', 'desc', 'ignoreCase']] });

export default function FromTemplateModal(): ReactElement {
	const page = useFromTemplatePage();
	const history = useHistory();

	function onClose(): void {
		history.push(page.toString('/experiments'));
	}

	const onCreateExperimentFromDraft = useCreateExperimentFromScratch();
	const targetDefinitionsResult = useTargetDefinitions();
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const searchObjectsResult = usePromise(() => Services.templatesApi.getSearchObjects(), []);
	const permissions = useGlobalPermissions();

	const [{ newExperimentTags, selectedTemplateId, useTemplateId, includeNonPermitted }, , updateUrlState] =
		useUrlState<UrlState>([
			selectedTemplateIdParam,
			newExperimentTagsParam,
			useTemplateIdParam,
			pageParam,
			includeNonPermittedParam,
		]);

	if (useTemplateId) {
		return (
			<UseTemplateModal
				experimentCreationMethod="UI_TEMPLATE"
				newExperimentTags={newExperimentTags}
				templatePreviewId={useTemplateId}
				onClose={() => updateUrlState({ useTemplateId: null })}
			/>
		);
	}

	return (
		<>
			<ModalOverlay open onClose={onClose}>
				<ModalV2 width="90vw" maxWidth="1660px">
					<ModalHeaderV2
						title="Templates"
						renderActions={() => (
							<Stack direction="horizontal" size="large" mr="medium">
								{permissions.templates.canCreate && (
									<RouterButton variant="chromeless" to={'/settings/templates/design/<new>'}>
										<IconAdd mr="xSmall" ml="-xxSmall" />
										<Text variant="mediumStrong">Create new template</Text>
									</RouterButton>
								)}
								{permissions.experiments.canCreate && (
									<Button
										variant="secondary"
										onClick={async () =>
											history.push(
												page.toString('/experiments/edit/<new>/design/'),
												await onCreateExperimentFromDraft(),
											)
										}
									>
										<IconAdd mr="xSmall" ml="-xxSmall" />
										<Text variant="mediumStrong">Experiment from scratch</Text>
									</Button>
								)}
							</Stack>
						)}
						onClose={onClose}
					/>
					<ModalContentV2>
						<Stack size="large">
							<SearchBar
								mode="templatePreviews"
								targetDefinitionsResult={targetDefinitionsResult}
								searchObjectsResult={searchObjectsResult}
								actions={actionsResult.value || []}
								cypressTag="template-search-input"
								pathname="/from_templates"
							/>

							<Stack direction="horizontal" size="large" justifyContent="space-between">
								<Text variant="largeStrong">Results</Text>
								<IncludePermittedToggle
									checked={includeNonPermitted}
									onChange={(checked) => updateUrlState({ page: 0, includeNonPermitted: checked })}
								/>
							</Stack>

							<TemplateList targetDefinitionsResult={targetDefinitionsResult} actionsResult={actionsResult} />
						</Stack>
					</ModalContentV2>
				</ModalV2>
			</ModalOverlay>
			{selectedTemplateId && (
				<FromTemplateDetailsModal
					actionsResult={actionsResult}
					templatePreviewId={selectedTemplateId}
					targetDefinitionsResult={targetDefinitionsResult}
					onClose={onClose}
					onGoBack={() => updateUrlState({ selectedTemplateId: null })}
					onUseClick={(_template) => {
						updateUrlState({
							selectedTemplateId: null,
							useTemplateId: selectedTemplateId,
						});
						ampli.experimentTemplateUsed({ experiment_template_name: _template.templateTitle });
					}}
				/>
			)}
		</>
	);
}
