/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepVOUnion } from 'ui-api';
import { ReactElement } from 'react';

import { ExperimentError } from '../types';
import Step from './Step';

interface StepsProps {
	stepIdToError: Map<string, ExperimentError[]>;
	steps: BaseExperimentStepVOUnion[];
}

export default function Steps({ steps, stepIdToError }: StepsProps): ReactElement {
	return (
		<>
			{steps.map((step, i) => {
				return <Step key={step.id} stepIdToError={stepIdToError} step={step} index={i} />;
			})}
		</>
	);
}
