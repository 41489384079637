/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Stack, Text, TextField } from 'components';
import { ErrorMessage } from '@steadybit/ui-components-lib';
import { useField, useFormikContext } from 'formik';
import { IconTags } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import DescriptionEditor from '../components/DescriptionEditor/DescriptionEditor';
import DropdownSearchInput from '../../components/SearchBar/DropdownSearchInput';
import { TemplateError, TemplateFormValues } from './types';
import { Tag } from '../components/TemplateTags';

/*
  This is pretty similar pages/experimentsV2/ExperimentTags.tsx
  but there are many small differences so we keep with 2 code bases
*/
export default function TemplateDescriptionContent(): ReactElement {
	const { values, errors, setFieldValue, setFieldTouched } = useFormikContext<TemplateFormValues>();

	const errorMessages: { [key: string]: TemplateError } = errors as { [key: string]: TemplateError };

	return (
		<Stack alignItems="center" py="large">
			<Stack width="100%" maxWidth="1200px">
				<Text variant="largeStrong" color="neutral800">
					Template Description
				</Text>

				<Block
					title="Title*"
					description="Provide a title for this experiment template. It will be shown in the search results, detailed view, and as part of the dialogue that is shown to users of this template."
				>
					<Stack size="xSmall">
						<TextField
							value={values.templateTitle}
							placeholder="Title"
							onChange={(e) => {
								setFieldValue('templateTitle', e.target.value);
								setFieldTouched('templateTitle', true);
							}}
							hasError={!!errors.templateTitle}
						/>
						{errorMessages.templateTitle && (
							<ErrorMessage type="xSmall" level={errorMessages.templateTitle.level} withIcon>
								{errorMessages.templateTitle.message}
							</ErrorMessage>
						)}
					</Stack>
				</Block>

				<Block
					title="Details*"
					description="Provide additional context for the template, e.g., its intent, when it is valuable, and how the experiment's steps relate to each other to achieve the overall goal."
				>
					<Stack size="xSmall">
						<DescriptionEditor
							value={values.templateDescription}
							hasError={!values.templateDescription}
							onChange={(templateDescription) => {
								setFieldValue('templateDescription', templateDescription);
								setFieldTouched('templateDescription', true);
							}}
						/>
						{errorMessages.templateDescription && (
							<ErrorMessage type="xSmall" level={errorMessages.templateDescription.level} withIcon>
								{errorMessages.templateDescription.message}
							</ErrorMessage>
						)}
					</Stack>
				</Block>

				<Block title="Tags" description="Assign tags to make it easier to discover your template.">
					<Tags />
				</Block>
			</Stack>
		</Stack>
	);
}

interface BlockProps {
	children: ReactElement;
	description: string;
	title: string;
}

function Block({ title, description, children }: BlockProps): ReactElement {
	return (
		<Stack
			size="xxSmall"
			style={{
				padding: '24px',
				backgroundColor: theme.colors.neutral100,
				borderRadius: '8px',
			}}
		>
			<Text variant="mediumStrong" color="neutral800">
				{title}
			</Text>
			<Text variant="small" color="neutral600">
				{description}
			</Text>
			{children}
		</Stack>
	);
}

function Tags(): ReactElement {
	const [{ value: tags }, , { setValue, setTouched }] = useField<string[]>('tags');
	const [{ value: allTags }] = useField<string[]>('allTags');

	return (
		<DropdownSearchInput
			placeholder="Assign an existing tag or create a new one"
			icon={IconTags}
			renderDropdownContent={({ value, setValue: setQuery }) => {
				const queryMatchesExisitngTag = [...allTags, ...tags].some((tag) => tag.toLowerCase() === value.toLowerCase());

				const selectableTags = allTags.filter(
					(tag) => tag.toLowerCase().includes(value.toLowerCase()) && !tags.includes(tag),
				);

				if (selectableTags.length === 0 && (value.length === 0 || queryMatchesExisitngTag)) {
					return <></>;
				}

				return (
					<Container p="medium">
						{selectableTags.length > 0 && (
							<>
								<Text variant="small" color="neutral600">
									Select an option or add a new one
								</Text>
								<Container
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: '8px',
										mt: 'xSmall',
									}}
								>
									{selectableTags.map((tag) => (
										<Tag
											key={tag}
											onClick={() => {
												setValue([...tags, tag]);
												setTouched(true);
												setQuery('');
											}}
										>
											{tag}
										</Tag>
									))}
								</Container>
								{value.length > 0 && <Container sx={{ height: '1px', my: 'medium', bg: 'neutral400' }} />}
							</>
						)}
						{value.length > 0 && !queryMatchesExisitngTag && (
							<Stack direction="horizontal" alignItems="center">
								<Text variant="small" color="neutral600">
									Create
								</Text>
								<Tag
									onClick={() => {
										setValue([...tags, value]);
										setTouched(true);
										setQuery('');
									}}
								>
									{value}
								</Tag>
							</Stack>
						)}
					</Container>
				);
			}}
		>
			{tags.map((tag) => (
				<Tag
					key={tag}
					onDelete={() => {
						setValue(tags.filter((t) => t !== tag));
						setTouched(true);
					}}
				>
					{tag}
				</Tag>
			))}
		</DropdownSearchInput>
	);
}
