/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import React, { useLayoutEffect, useState } from 'react';
import { clamp } from 'lodash';

import { ReactComponent as BlastRadius } from './illustration.svg';

type BlastCanvasProps = React.SVGProps<SVGSVGElement> & {
	impacted: number;
	total: number;
};

export default function BlastCanvas({ impacted, total }: BlastCanvasProps): ReactElement {
	const [id] = useState('' + Math.random());
	useLayoutEffect(() => {
		const svgWrapper = document.getElementById(id) as HTMLElement | undefined;
		if (!svgWrapper) {
			return;
		}
		const children = svgWrapper.getElementsByTagName('g');

		let ufoAttackBackground;
		let blastRadiusAffected;
		for (let i = 0; i < children.length; i++) {
			if (children[i].getAttribute('id') === 'ufoAttackBackground') {
				ufoAttackBackground = children[i];
			}
			if (children[i].getAttribute('id') === 'blastRadiusAffected') {
				blastRadiusAffected = children[i];
			}
		}

		if (ufoAttackBackground) {
			ufoAttackBackground.style.filter = 'blur(0.8px)';
		}

		if (blastRadiusAffected) {
			if (total > 0 && impacted > 0) {
				blastRadiusAffected.style.transform = `scale(${clamp(impacted / total, 0.05, 1)})`;
				blastRadiusAffected.style.transformOrigin = '56px 70px';
			} else {
				blastRadiusAffected.style.transform = 'scale(0)';
				blastRadiusAffected.style.transformOrigin = '56px 70px';
			}
		}
	}, [impacted, total]);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return <BlastRadius id={id} />;
}
