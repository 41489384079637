/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	IconAdvanced,
	IconAstronaut,
	IconAws,
	IconAzure,
	IconConsole,
	IconDatabase,
	IconDatadog,
	IconDocker,
	IconExperiment,
	IconGatling,
	IconGoogleCloud,
	IconInstana,
	IconJMeter,
	IconK6,
	IconKafka,
	IconKong,
	IconKubernetes,
	IconLinux,
	IconNewRelic,
	IconOnPrem,
	IconPostman,
	IconPrometheus,
	IconReport,
	IconSettings,
	IconUser,
	IconWeakSpot,
} from 'components/icons';
import { Button, Container, Stack, StyleProp, Text } from 'components';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ampliIdentify } from 'tracking/amplitude';
import { Services } from 'services/services';
import { useDebounce } from 'react-use';
import { theme } from 'styles.v2/theme';
import { CurrentUserVO } from 'ui-api';
import { useHistory } from 'url/hooks';
import { ampli } from 'ampli';

interface UserQuestionaireContentProps {
	user: CurrentUserVO;
	page: number;
}

export default function UserQuestionaireContent({ page, user }: UserQuestionaireContentProps): ReactElement {
	const [jobRole, setJobRole] = useState<string>(user.jobRole);
	const [intention, setintention] = useState<string>(user.intention);
	const [usedTechnologies, setUsedTechnologies] = useState<string[]>(user.usedTechnologies ?? []);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [updateSignal, setUpdateSignal] = useState<number>(0);
	const debouncedSignalIncrease = (): void => setUpdateSignal(updateSignal + 1);

	const history = useHistory();

	async function trackCurrentView(): Promise<void> {
		const userToSave = {
			...user,
			jobRole: jobRole ?? user.jobRole,
			intention: intention ? intention : user.intention,
			usedTechnologies: usedTechnologies ?? user.usedTechnologies,
		};
		ampliIdentify(userToSave);
		ampli.onboardingQuestionnaireViewed({
			onboarding_question: page === 1 ? 'job_role' : page === 2 ? 'intention' : 'technology_focus',
		});
	}

	useEffect(() => {
		trackCurrentView();
	}, [page]);

	async function saveCurrentUserState(questionaireState: string, trackAnswered: boolean): Promise<void> {
		if (isSaving) {
			return;
		}
		setIsSaving(true);
		const userToSave = {
			...user,
			jobRole: jobRole ?? user.jobRole,
			intention: intention ? intention : user.intention,
			usedTechnologies: usedTechnologies ?? user.usedTechnologies,
			questionaireState,
		};
		try {
			await Services.users.updateCurrentUser(userToSave);
			ampliIdentify(userToSave);
			if (trackAnswered) {
				ampli.onboardingQuestionnaireAnswered();
			}
		} catch (e) {
			console.error(e);
		}
		setIsSaving(false);
	}

	useDebounce(
		() => {
			if (updateSignal > 0) {
				saveCurrentUserState('NOT_SEEN', false);
			}
		},
		1_000,
		[updateSignal],
	);

	async function onComplete(questionaireState: string, trackAnswered: boolean): Promise<void> {
		if (isSaving) {
			return;
		}
		await saveCurrentUserState(questionaireState, trackAnswered);

		if (questionaireState === 'COMPLETED') {
			ampli.onboardingQuestionnaireCompleted();
		} else if (questionaireState === 'SKIPPED') {
			ampli.onboardingQuestionnaireSkipped();
		}

		return new Promise((resolve) => {
			const prev = window.onload;
			window.onload = () => {
				resolve();
				window.onload = prev;
			};
			window.location.reload();
		});
	}

	return (
		<Stack alignItems="center" size="xLarge">
			<Stack alignItems="center" size="xSmall">
				<PageIndicator page={page} />
				<Title>{getTitle(page)}</Title>
				<Description>{getDescription(page)}</Description>
			</Stack>

			<Container
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '16px',
					maxWidth: '620px',
					justifyContent: 'center',
				}}
			>
				{page === 1 &&
					[
						{ label: 'Engineer', key: 'DEVELOPER', icon: <IconConsole /> },
						{ label: 'SRE', key: 'SRE', icon: <IconSettings /> },
						{ label: 'Manager', key: 'MANAGER', icon: <IconUser /> },
						{ label: 'QA Engineer', key: 'PERFORMANCE_QA_ENGINEER', icon: <IconExperiment /> },
						{ label: 'Other', key: 'OTHER', icon: <IconAstronaut /> },
					].map(({ label, icon, key }) => (
						<Item
							key={key}
							icon={icon}
							label={label}
							active={jobRole === key}
							onClick={() => {
								setJobRole(key);
								debouncedSignalIncrease();
								history.push('/userQuestionaire/2');
							}}
							singleChoice
						/>
					))}

				{page === 2 &&
					[
						{
							label: 'Do Chaos Engineering to improve reliability',
							key: 'DO_CE_IMPROVE_RELIABILITY',
							icon: <IconReport />,
						},
						{ label: 'Roll out Chaos Engineering to teams', key: 'ROLL_OUT_CE', icon: <IconWeakSpot /> },
						{
							label: 'Compare Chaos Engineering solutions',
							key: 'COMPARE_CE_SOLUTIONS',
							icon: <IconAdvanced />,
						},
						{ label: 'Learn Chaos Engineering', key: 'LEARN_CE', icon: <IconExperiment /> },
						{ label: 'I`m still figuring it out', key: 'UNSURE', icon: <IconAstronaut /> },
					].map(({ label, key, icon }) => (
						<Item
							key={key}
							icon={icon}
							label={label}
							active={intention === key}
							onClick={() => {
								setintention(key);
								debouncedSignalIncrease();
								history.push('/userQuestionaire/3');
							}}
							singleChoice
						/>
					))}

				{page === 3 &&
					[
						{ label: 'AWS Cloud', key: 'aws cloud', icon: <IconAws /> },
						{ label: 'Azure Cloud', key: 'azure cloud', icon: <IconAzure /> },
						{ label: 'DataDog', key: 'datadog', icon: <IconDatadog /> },
						{ label: 'Docker', key: 'docker', icon: <IconDocker /> },
						{ label: 'Gatling', key: 'gatling', icon: <IconGatling /> },
						{ label: 'Google Cloud Platform (GCP)', key: 'gcp cloud', icon: <IconGoogleCloud /> },
						{ label: 'Instana', key: 'instana', icon: <IconInstana /> },
						{ label: 'JMeter', key: 'jmeter', icon: <IconJMeter /> },
						{ label: 'K6', key: 'k6', icon: <IconK6 /> },
						{ label: 'Kafka', key: 'kafka', icon: <IconKafka /> },
						{ label: 'Kong', key: 'kong', icon: <IconKong /> },
						{ label: 'Kubernetes', key: 'kubernetes', icon: <IconKubernetes /> },
						{ label: 'Linux Hosts', key: 'linux hosts', icon: <IconLinux /> },
						{ label: 'New Relic', key: 'new relic', icon: <IconNewRelic /> },
						{ label: 'On-Premises Infrastructure', key: 'on-prem', icon: <IconOnPrem /> },
						{ label: 'Postman', key: 'postman', icon: <IconPostman /> },
						{ label: 'Prometheus', key: 'prometheus', icon: <IconPrometheus /> },
						{ label: 'Relational Database', key: 'relational database', icon: <IconDatabase /> },
					].map(({ label, key, icon }) => (
						<Item
							key={key}
							icon={icon}
							label={label}
							active={usedTechnologies.indexOf(key) !== -1}
							onClick={() => {
								if (usedTechnologies.indexOf(key) !== -1) {
									setUsedTechnologies(usedTechnologies.filter((item) => item !== key));
								} else {
									setUsedTechnologies([...usedTechnologies, key]);
								}
								debouncedSignalIncrease();
							}}
							sx={{ width: '178px' }}
						/>
					))}
			</Container>

			{page === 3 && usedTechnologies.length > 0 ? (
				<Button
					id="skip-questions"
					data-cy="skip-questions"
					onClick={() => onComplete('COMPLETED', true)}
					disabled={isSaving}
				>
					Let’s start! 🚀
				</Button>
			) : (
				<Text
					id="skip-questions"
					data-cy="skip-questions"
					as="span"
					variant="smallStrong"
					sx={{
						color: 'slate',
						cursor: 'pointer',
					}}
					onClick={() => onComplete('SKIPPED', false)}
				>
					Skip questions and start with Steadybit
				</Text>
			)}
		</Stack>
	);
}

interface PageIndicatorProps {
	page: number;
}

function PageIndicator({ page }: PageIndicatorProps): ReactElement {
	return (
		<Container display="flex">
			<Text as="span" variant="small" mr="xxxSmall">
				(
			</Text>
			<Text as="span" variant="smallStrong">
				{page}
			</Text>
			<Text as="span" variant="small">
				/3
			</Text>
			<Text as="span" variant="small" ml="xxxSmall">
				)
			</Text>
		</Container>
	);
}

interface TitleProps {
	children: string;
}

function Title({ children }: TitleProps): ReactElement {
	return (
		<Text
			as="h2"
			sx={{
				fontSize: '24px',
				lineHeight: '130%',
				fontWeight: 700,
				color: '#0F054C',
			}}
		>
			{children}
		</Text>
	);
}

interface DescriptionProps {
	children: string;
}

function Description({ children }: DescriptionProps): ReactElement {
	return (
		<Text as="h2" variant="medium">
			{children}
		</Text>
	);
}

interface ItemProps {
	singleChoice?: boolean;
	onClick: () => void;
	icon: ReactNode;
	label: string;
	subText?: string;
	active?: boolean;
	sx?: StyleProp;
}

function Item({ active, icon, label, subText, onClick, singleChoice, sx = {} }: ItemProps): ReactElement {
	const [hovered, setHover] = useState<boolean>(false);
	const isActive = active || hovered;
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: '8px ',

				minWidth: '148px',
				maxWidth: '178px',
				minHeight: '44px',
				borderRadius: '3px',
				backgroundColor: active ? (hovered ? 'purple200' : 'purple100') : hovered ? 'purple100' : 'neutral200',
				color: isActive ? 'slate' : 'neutral800',
				cursor: 'pointer',
				border: '1px solid ' + (isActive ? theme.colors.slate : 'transparent'),
				...sx,
			}}
			onClick={onClick}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Stack direction="horizontal" size="xSmall" alignItems="center">
				<Container minWidth={20} minHeight={20}>
					{icon}
				</Container>
				<Stack size="none">
					<Text variant="smallStrong" lineHeight="120%">
						{label}
					</Text>
					{subText && (
						<Text variant="xSmallStrong" lineHeight="120%">
							({subText})
						</Text>
					)}
				</Stack>
			</Stack>
			{singleChoice && (
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',

						ml: '8px',
						minWidth: '11px',
						minHeight: '11px',
						borderRadius: '50%',
						backgroundColor: 'neutral000',
						border: '2px solid ' + (isActive ? theme.colors.slate : theme.colors.neutral400),
					}}
				>
					{isActive && (
						<Container
							sx={{
								minWidth: '5px',
								minHeight: '5px',
								borderRadius: '50%',
								backgroundColor: 'slate',
							}}
						/>
					)}
				</Container>
			)}
		</Container>
	);
}

function getTitle(page: number): string {
	if (page === 1) {
		return 'What is your role?';
	}
	if (page === 2) {
		return 'What are you trying to achieve?';
	}
	return 'On which technologies do you focus on?';
}

function getDescription(page: number): string {
	if (page === 1) {
		return 'Helps us to improve your experience at the Steadybit platform.';
	}
	if (page === 2) {
		return 'Helps us to align the Steadybit platform to your goals.';
	}
	return 'Help us to suggest you to the right extensions and templates.';
}
