/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import EmptyListContent from 'components/List/EmptyListContent';
import { IconAdviceGeneral } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { TargetPredicateVO } from 'ui-api';
import { Container } from 'components';
import { ReactElement } from 'react';

import { UrlState, selectedAdviceIdParam, selectedTargetIdParam } from '../urlParams';
import TargetCategories from './TargetCategories';
import TargetDetails from './TargetDetails';

interface AdviceContentProps {
	predicate: TargetPredicateVO;
	environmentId: string;
}

export default function AdviceContent({ environmentId, predicate }: AdviceContentProps): ReactElement | null {
	const [urlvalues] = useUrlState<UrlState>([selectedAdviceIdParam, selectedTargetIdParam]);
	const { selectedAdviceId, selectedTargetId } = urlvalues;

	if (!selectedAdviceId) {
		return null;
	}

	return (
		<Container
			sx={{
				position: 'relative',
				height: '100%',
				overflowY: 'hidden',
			}}
		>
			<AbsoluteWrapper>
				<TargetCategories adviceType={selectedAdviceId} predicate={predicate} />
			</AbsoluteWrapper>
			{selectedTargetId && (
				<AbsoluteWrapper>
					<TargetDetails environmentId={environmentId} targetId={selectedTargetId} adviceType={selectedAdviceId} />
				</AbsoluteWrapper>
			)}
		</Container>
	);
}

function AbsoluteWrapper({ children }: { children: ReactElement }): ReactElement {
	return (
		<Container
			sx={{
				position: 'absolute',
				top: '0',
				bottom: '0',
				left: '0',
				right: '0',
				backgroundColor: 'neutral000',
			}}
		>
			{children}
		</Container>
	);
}

export function NotFound({
	adviceIsAvailableInGeneral,
	adviceIsNotAvailableForTarget,
}: {
	adviceIsAvailableInGeneral?: boolean;
	adviceIsNotAvailableForTarget?: boolean;
}): ReactElement | null {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				height: '100%',
			}}
		>
			<EmptyListContent
				icon={<IconAdviceGeneral variant="xxLarge" color="slate" />}
				title="Advice not available"
				description={
					adviceIsAvailableInGeneral
						? 'The selected advice is not available in the selected environment'
						: adviceIsNotAvailableForTarget
							? 'The selected advice is not available anymore in the selected target'
							: undefined
				}
			/>
		</Container>
	);
}
