/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import React, { ReactNode } from 'react';
import { Container } from 'components';

import { TextField, TextFieldProps } from '../TextField';
import { Select } from '../Select';

const SingleUnit: React.FC<{ disabled?: boolean; children: ReactNode }> = ({ children, disabled }) => (
	<Container
		display={'flex'}
		tx={'textfield'}
		flex={'0 0 auto'}
		px={'xSmall'}
		alignItems={'center'}
		sx={{
			variant: 'text.medium',
			borderRadius: '0 4px 4px 0',
			border: '1px solid',
			borderLeftWidth: 0,
			borderColor: disabled ? 'neutral200' : 'neutral300',
			bg: disabled ? 'neutral100' : 'neutral000',
			backgroundImage: disabled ? 'none' : 'linear-gradient(180deg, rgba(244, 247, 251, 0) 0%, #F4F7FB 100%);',
			color: disabled ? 'neutral600' : 'neutral800',
			px: 'small',
			userSelect: 'none',
		}}
	>
		{children}
	</Container>
);

export interface UnitFieldUnit {
	label: string;
	value: string;
}

export interface UnitFieldProps extends TextFieldProps {
	unit: UnitFieldUnit;
	units?: UnitFieldUnit[];
	min?: number;
	max?: number;
	onUnitChange?: (unit: UnitFieldUnit) => void;
	unitSelectorDisabled?: boolean;
}

export const UnitField = React.forwardRef<HTMLDivElement, UnitFieldProps>(
	({ unit, units, onUnitChange, disabled, value, unitSelectorDisabled, ...props }, ref) => {
		return (
			<Container display={'flex'} flex={'0 0 auto'} justifyContent="flex-end">
				<TextField
					disabled={disabled}
					sx={{
						borderRadius: '4px 0 0 4px',
					}}
					value={value ?? ''}
					{...props}
				/>
				{units && units.length > 1 && onUnitChange ? (
					<Select<UnitFieldUnit>
						ref={ref}
						value={unit}
						options={units}
						disabled={disabled || unitSelectorDisabled}
						onChange={(unit) => unit && onUnitChange(unit)}
						flex={'0 0 auto'}
						menuWidth={'min-content'}
						sx={{
							'[data-select-button]': {
								height: '100%',
								borderRadius: '0 4px 4px 0',
								borderLeftWidth: 0,
							},
						}}
					/>
				) : (
					<SingleUnit disabled={disabled}>{unit.label}</SingleUnit>
				)}
			</Container>
		);
	},
);
UnitField.displayName = 'UnitField';
