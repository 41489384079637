/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TemplatePlaceholderRegexHandlebar, listPlaceholderKeyValuePairs, listPlaceholders } from 'templates/utils';
import { EnvVarRegexHandlebar, listVariableKeyValuePairs, listVariables } from 'utils/envVars';
import FieldValueHandler from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Field';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { ExperimentStepActionVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import Editor from 'components/Editor/Editor';
import { Services } from 'services/services';
import { Stack, Text } from 'components';
import { useField } from 'formik';

interface ActionParametersProps {
	actionStep: ExperimentStepActionVO;
	environmentVariable?: string;
	environmentId: string;
	placeholder?: string;
	stepPath: string;
}

export default function ActionParameters({
	environmentVariable,
	environmentId,
	placeholder,
	actionStep,
	stepPath,
}: ActionParametersProps): ReactElement {
	const { customLabel, actionId, blastRadius, parameters } = actionStep;

	const [fromCustomLabel] = useState(() =>
		environmentVariable ? [] : listPlaceholderKeyValuePairs({ customLabel }, placeholder),
	);
	const [fromParameters] = useState(() =>
		environmentVariable
			? listVariableKeyValuePairs(parameters, environmentVariable)
			: listPlaceholderKeyValuePairs(parameters, placeholder),
	);
	const [fromBlastRadius] = useState(() =>
		environmentVariable
			? listVariables(blastRadius?.predicate).filter((_v) => _v === environmentVariable)
			: listPlaceholders(blastRadius?.predicate).filter((_v) => _v === placeholder),
	);

	const [actionParametersResult] = useAsyncState(async () => {
		return await Services.experiments.fetchActionParameters(actionId, environmentId);
	}, [actionId, environmentId]);

	return (
		<Stack>
			{fromCustomLabel.length > 0 && (
				<FieldValueHandler
					field={{
						name: 'customLabel',
						label: 'Label',
						type: 'string',
						required: true,
						readonly: true,
					}}
					path={`${stepPath}.customLabel`}
					disabled
				/>
			)}
			{fromBlastRadius.length > 0 && (
				<PredicateQuery path={`${stepPath}.blastRadius.predicate`} environmentVariable={environmentVariable} />
			)}
			{fromParameters.map(([key]) => {
				const resolvedField = actionParametersResult.value?.find((field) => field.name === key);
				return (
					<FieldValueHandler
						key={key}
						field={resolvedField ? resolvedField : { name: key, label: key, type: 'string', required: true }}
						path={`${stepPath}.parameters['${key}']`}
						disabled
					/>
				);
			})}
		</Stack>
	);
}

function PredicateQuery({ path, environmentVariable }: { environmentVariable?: string; path: string }): ReactElement {
	const [{ value }, { error }] = useField<string>(path);
	const query = toPredicateString(value);

	return (
		<Stack size="xSmall">
			<Editor
				// the monaco API is wrong. It claims to accept a string only, but it actually accepts a RegExp as well
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				highlightTokensContainingString={environmentVariable ? EnvVarRegexHandlebar : TemplatePlaceholderRegexHandlebar}
				forceUpdateSignal={query}
				initialValue={query}
				disabled
			/>
			<Text variant="smallStrong" color="experimentWarning" data-formik-error>
				{error}
			</Text>
		</Stack>
	);
}
