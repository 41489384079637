/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, SearchObjectsVO, TargetTypeDescriptionVO } from 'ui-api';
import { createResult, DataStreamResult } from 'utils/hooks/stream/result';
import SearchBar from 'pages/components/SearchBar/SearchBar';
import { Box, Flex } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { IconSearch } from 'components/icons';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import useActions from '../useActions';

interface SearchInputProps {
	targetDefinitionsResult: DataStreamResult<TargetTypeDescriptionVO[]>;
	collapsed?: boolean;
	onExpand: () => void;
}

export default function SearchInput({ targetDefinitionsResult, collapsed, onExpand }: SearchInputProps): ReactElement {
	const { actions } = useActions();

	if (collapsed) {
		return <SearchInputPlaceholder onClick={onExpand} />;
	}

	return <SearchInputWithSearchObjects targetDefinitionsResult={targetDefinitionsResult} actions={actions} />;
}

function SearchInputWithSearchObjects({
	targetDefinitionsResult,
	actions,
}: {
	targetDefinitionsResult: DataStreamResult<TargetTypeDescriptionVO[]>;
	actions: ActionVO[];
}): ReactElement {
	const searchObjectsResult = usePromise(() => Services.templatesApi.getSearchObjects(), []);

	const sytheticSearchObjects: DataStreamResult<SearchObjectsVO> = createResult({
		targetTypes: merge(
			actions
				.filter((action) => 'target' in action)
				.map((action) => action.target.type)
				.filter((a) => a !== undefined) as string[],
			searchObjectsResult.value?.targetTypes || [],
		),
		actions: merge(
			actions.map((a) => a.id),
			searchObjectsResult.value?.actions || [],
		),
		actionKinds: merge(
			actions.map((a) => a.kind),
			searchObjectsResult.value?.actionKinds || [],
		),
		environmentIds: searchObjectsResult.value?.environmentIds || [],
		tags: merge(searchObjectsResult.value?.tags || [], actions.map((a) => a.hubTags).flat()),
		mostUsedTags: [],
	});

	return (
		<SearchBar
			targetDefinitionsResult={targetDefinitionsResult}
			searchObjectsResult={sytheticSearchObjects}
			allowActionDragAndDrop
			actions={actions}
			mode="templateList"
			pathname="/design"
			small
		/>
	);
}

function SearchInputPlaceholder({ onClick }: { onClick: () => void }): ReactElement {
	return (
		<Flex
			align="center"
			justify="center"
			style={{
				width: '-webkit-fill-available',
				height: 'fit-content',
				padding: '8px',
				backgroundColor: theme.colors.neutral100,
				borderRadius: 'xxSmall',
			}}
		>
			<Box
				style={{
					position: 'relative',
					width: '-webkit-fill-available',
					padding: '2px 4px 2px 38px',

					backgroundColor: theme.colors.neutral000,
					borderRadius: 'xxSmall',
					color: theme.colors.neutral600,
					border: '1px solid ' + theme.colors.neutral300,
					height: '34px',

					onHover: {
						cursor: 'pointer',
						border: '1px solid ' + theme.colors.slate,
						color: theme.colors.slate,
					},
				}}
				onClick={onClick}
			>
				<IconSearch
					variant="medium"
					sx={{
						position: 'absolute',
						top: '10px',
						left: '12px',

						minWidth: '16px',
						minHeight: '16px',
					}}
				/>
			</Box>
		</Flex>
	);
}

function merge(a1: string[], a2: string[]): string[] {
	return Array.from(new Set([...a1, ...a2]));
}
