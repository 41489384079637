/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import Action from 'pages/components/Action';
import { ReactElement } from 'react';
import { Text } from 'components';
import { ActionVO } from 'ui-api';

interface ActionsProps {
	actionDefinitions: ActionVO[];
	actions: string[];
	small?: boolean;
}

export default function Actions({ actions, actionDefinitions, small }: ActionsProps): ReactElement | null {
	if (!actions || actions.length === 0) {
		return null;
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateRows: small ? undefined : '24px 1fr',
				gridTemplateColumns: small ? '60px 1fr' : undefined,
				gap: '6px',
			}}
		>
			<Text variant="smallStrong" color="neutral600">
				Actions:
			</Text>

			<div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
				{actions.map((action, i) => {
					return <Action key={i} actionsData={actionDefinitions} action={action} small={small} />;
				})}
			</div>
		</div>
	);
}
