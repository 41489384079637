/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Button, ModalFooterV2, ModalHeaderV2, ModalOverlay, ModalV2, Snackbar, Stack } from 'components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ReactElement, useState } from 'react';
import { IconTrash } from 'components/icons';
import { Services } from 'services/services';

interface ScheduleExperimentModalProps {
	id: string;
	onClose: () => void;
}

export default function DeleteScheduleExperimentModal({ id, onClose }: ScheduleExperimentModalProps): ReactElement {
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 slick withFooter width={750} minHeight={10}>
					<ModalHeaderV2
						title="Delete Schedule for this Experiment"
						subTitle={
							<>
								Are you sure you want to delete this schedule? <br />
								It won’t be possible retrieve it again after the deletion.
							</>
						}
						onClose={close}
					/>
					<ModalFooterV2>
						<Stack size="small" direction="horizontal">
							<Button variant="secondary" onClick={close}>
								Cancel
							</Button>
							<Button
								variant="primary"
								disabled={isDeleting}
								onClick={async () => {
									setIsDeleting(true);

									try {
										await Services.experiments.deleteExperimentSchedule(id);
										close();
									} catch {
										Snackbar.error('Failed to delete schedule');
										setIsDeleting(false);
									}
								}}
							>
								{isDeleting ? <LoadingIndicator color="neutral000" sx={{ mr: 'xSmall' }} /> : <IconTrash mr="xSmall" />}
								Delete Schedule
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
