/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DeleteIcon } from 'pages/templates/FromTemplateModal/TemplateDetails/common';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { createIconFromDataUri } from 'components/icons';
import { TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement } from 'react';
import { Pill } from 'components';

interface TargetProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targetId: string;
	small?: boolean;
	onClick?: () => void;
	onDelete?: () => void;
}

export function Target({ targetDefinitions, targetId, small, onClick, onDelete }: TargetProps): ReactElement {
	const resolvedTargetDefinition = (targetDefinitions || []).find(
		(targetDefinition) => targetDefinition.id === targetId,
	);
	const Icon = createIconFromDataUri(resolvedTargetDefinition?.icon);

	return (
		<Pill
			backgroundColorOnHover="neutral300"
			backgroundColor="neutral200"
			color="neutral800"
			onClick={onClick}
			sx={{
				width: 'fit-content',
				height: small ? '16px' : '28px',
				fontSize: small ? '10px' : '12px',
				borderRadius: '14px',
			}}
		>
			{resolvedTargetDefinition?.icon ? <Icon variant={small ? 'xSmall' : 'small'} mr="xxSmall" /> : <></>}
			<span style={{ ...textEllipsis }}>{resolvedTargetDefinition?.label.one || targetId}</span>
			{onDelete ? <DeleteIcon color="neutral800" onClick={onDelete} /> : <></>}
		</Pill>
	);
}
