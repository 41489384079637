/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconWarningOpen } from 'components/icons';
import { ReactElement, useEffect } from 'react';
import { Container, Text } from 'components';
import { ampli } from 'ampli';

import { hardGroupsLimit, hardTargetsLimit } from '../config';

export default function ExploreTooManyTargetsView({
	numberOfTargets = -1,
}: {
	numberOfTargets?: number;
}): ReactElement {
	useEffect(() => {
		ampli.landscapeExplorerRenderingLimitReached({
			url: window.location.href,
			number_of_groups: -1,
			number_of_targets: numberOfTargets,
		});
	}, []);

	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '24px',
				height: '100%',
				width: '100%',
				bg: 'neutral700',
				color: 'purple300',
			}}
		>
			<IconWarningOpen variant="xxxLarge" color="purple300" />

			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '12px',
				}}
			>
				<Text variant="xLargeStrong">Explorer doesn&apos;t support so many targets</Text>

				<Text
					variant="medium"
					color="neutral000"
					maxWidth={600}
					sx={{
						textAlign: 'center',
					}}
				>
					The platform is currently limited to {hardTargetsLimit} targets in {hardGroupsLimit} groups.
					<br />
					To visualize your current configuration, you must filter down your view or select an environment with fewer{' '}
					targets.
				</Text>
			</Container>
		</Container>
	);
}
