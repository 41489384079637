/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DidNotSavePrompt } from 'pages/experiments/wizard/components/DidNotSavePrompt';
import { saveTemplate } from 'templates/components/formUtils';
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';

import { TemplateFormValues } from './types';

export default function UnsavedChangesPrompt(): ReactElement {
	const { values, touched } = useFormikContext<TemplateFormValues>();
	const dirty = Object.keys(touched).length > 0;

	return (
		<DidNotSavePrompt
			when={dirty}
			entityTitle="template"
			block={({ pathname }) => {
				return !pathname.startsWith('/settings/templates/design');
			}}
			onSaveCallback={async () => {
				await saveTemplate(values);
			}}
		/>
	);
}
