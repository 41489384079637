/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';

import { UrlState, selectedStepIdParam } from '../urlParams';
import { WorkspaceSettings } from '../useWorkspaceSettings';
import ZoomControls from './ZoomControls';
import Timeline from './Timeline';
import Lanes from './Lanes';
import './Workspace.css';

interface WorkspaceProps {
	height: number;
}

export default function Workspace({ height }: WorkspaceProps): ReactElement {
	const [, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);
	const [pxPerSecond, setPxPerSecond] = useState(10);
	const [workspaceWidth, setWorkspaceWidth] = useState(0);

	return (
		<div
			style={{
				position: 'relative',
				overflowX: 'hidden',
				width: '100%',
			}}
		>
			<div
				id="experiment.explorer.wrapper"
				className="experimentexplorerwrapper"
				style={{
					position: 'relative',

					display: 'flex',
					alignItems: 'center',

					height: `calc(100vh - ${height}px)`,
					width: '100%',

					backgroundColor: theme.colors.neutral200,
					overflowX: 'scroll',
				}}
				onClick={() => {
					updateUrlState({
						selectedStepId: undefined,
					});
				}}
			>
				<WorkspaceSettings.Provider value={{ workspaceWidth, pxPerSecond }}>
					<Timeline />
					<Lanes setWorkspaceWidth={setWorkspaceWidth} />
				</WorkspaceSettings.Provider>
			</div>

			<ZoomControls pxPerSecond={pxPerSecond} setPxPerSecond={setPxPerSecond} />
		</div>
	);
}
