/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ButtonIcon, Container, Heading, Stack, Text, TextField, Tooltip } from 'components';
import { KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { ExperimentError } from 'pages/experimentsV2/types';
import { ErrorMessage } from '@steadybit/ui-components-lib';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { IconDelete } from 'components/icons';

import { listVariables } from '../../../../utils/envVars';
import { ExperimentFormValues } from '../../experiment';

const ExperimentEnvironmentVariables: React.VFC = () => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue } = formik;
	const { lanes, variables } = formik.values;

	useEffect(() => {
		let variableReferences: string[] = lanes.flatMap((lane) => {
			return lane.steps.flatMap((step) => {
				const fromParams = Object.values(step.parameters).flatMap((p) => listVariables(p));
				const fromBlastRadius = 'blastRadius' in step ? listVariables(step.blastRadius?.predicate) : [];
				return [...fromParams, ...fromBlastRadius];
			});
		});
		// Remove duplicates
		variableReferences = [...new Set(variableReferences)];

		const unusedVariables = variables
			.filter((variable) => !variable.value && !variableReferences.includes(variable.key))
			.map((v) => v.key);
		const missingVariables = variableReferences.filter((key) => !variables.some((v) => v.key === key));

		if (unusedVariables.length > 0 || missingVariables.length > 0) {
			const updatedVariables = [
				...variables.filter((variable) => !unusedVariables.includes(variable.key)),
				...missingVariables.map((variable) => ({ key: variable, value: '' })),
			];
			setFieldValue('variables', updatedVariables);
		}
	}, [lanes, variables, setFieldValue]);

	return (
		<Container
			p={'small'}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<Heading variant={'small'} mb={'small'}>
				Environment Variables
			</Heading>
			<EnvironmentVariablesConfig />
			<Text as="span" variant="small" muted mt="xSmall">
				Changing values may affect other experiments using the same variables of this environment.
			</Text>
		</Container>
	);
};

export default ExperimentEnvironmentVariables;

function EnvironmentVariablesConfig(): ReactElement {
	const [field, meta, { setValue, setTouched }] = useField<KeyValuePair[]>('variables');
	const [keyOfNewVariable, setKeyOfNewVariable] = useState(Date.now());

	const error = meta.error as ExperimentError | undefined;

	return (
		<Stack size="xxSmall">
			{field.value.map((variable, index) => {
				return (
					<EnvironmentVariable
						key={variable.key}
						variable={variable}
						setVariable={(_variable) => {
							const copied = [...field.value];
							copied[index] = _variable;
							setValue(copied);
							setTouched(true);
						}}
						onDelete={() => {
							setValue(field.value.filter((v) => v.key !== variable.key));
							setTouched(true);
						}}
					/>
				);
			})}
			<EnvironmentVariable
				key={keyOfNewVariable}
				variable={{ key: '', value: '' }}
				setVariable={(_variable) => {
					const copied = [...field.value];
					copied[field.value.length] = _variable;

					setValue(copied);
					setTouched(true);
					setKeyOfNewVariable(Date.now());
				}}
				onDelete={() => {}}
			/>
			{error && (
				<ErrorMessage level={error.level} withIcon type="small">
					{error.message}
				</ErrorMessage>
			)}
		</Stack>
	);
}

interface EnvironmentVariableProps {
	variable: KeyValuePair;
	setVariable: (v: KeyValuePair) => void;
	onDelete: () => void;
}

function EnvironmentVariable({ variable, setVariable, onDelete }: EnvironmentVariableProps): ReactElement {
	const [key, setKey] = useState(variable.key);
	const [value, setValue] = useState(variable.value);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '2fr 3fr 40px',
				gap: '8px',
				alignItems: 'center',
			}}
		>
			<Tooltip content={variable.key} onlyShowOnEllipsis>
				<TextField
					value={key}
					type="text"
					placeholder="Key"
					height="40px"
					onChange={(e) => setKey(e.target.value)}
					onBlur={() => {
						if (value) {
							setVariable({ key, value });
						}
					}}
				/>
			</Tooltip>
			<Tooltip content={variable.value} onlyShowOnEllipsis>
				<TextField
					hasError={key && !value ? true : false}
					value={value}
					type="text"
					placeholder="Value"
					height="40px"
					onChange={(e) => setValue(e.target.value)}
					onBlur={() => setVariable({ key, value })}
				/>
			</Tooltip>
			<ButtonIcon variant="small" muted color="neutral600" onClick={onDelete} tooltip="Delete">
				<IconDelete />
			</ButtonIcon>
		</div>
	);
}
