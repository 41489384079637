/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	DragDropContext,
	Draggable,
	DraggableProvidedDragHandleProps,
	Droppable,
	DropResult,
} from 'react-beautiful-dnd';
import { IconAdd, IconHandle, IconInformationCircle, IconTrash, IconWarningCircle } from 'components/icons';
import Chunks from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/Chunks';
import { Button, Container, Stack, Text, TextField, Tooltip } from 'components';
import { BaseExperimentStepVOUnion, TemplatePlaceholderVO } from 'ui-api';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@steadybit/ui-components-lib';
import { createResult } from 'utils/hooks/stream/result';
import useActions from 'pages/experimentsV2/useActions';
import { listPlaceholders } from 'templates/utils';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { get } from 'lodash';

import { replaceLaneMarker, replaceStringMarker, wrapWithPlaceholderMarkers } from '../utils';
import DescriptionEditor from '../components/DescriptionEditor/DescriptionEditor';
import { extractUsedPlaceholderKeys } from './PlaceholderExtractionAndCleanupJob';
import PlaceholderMarker from '../components/PlaceholderMarker';
import { extractVariables } from '../UseTemplateModal/utils';
import PlaceholderPill from '../components/PlaceholderPill';
import { TemplateError, TemplateFormValues } from './types';
import Steps from '../components/Steps/Steps';

export default function TemplatePlaceholdersContent(): ReactElement {
	const { values, errors, setValues, setFieldValue, setFieldTouched } = useFormikContext<TemplateFormValues>();

	const { experimentName, hypothesis, placeholders, lanes } = values;

	const [, currentUsedPlaceholderKeys] = useMemo(() => extractUsedPlaceholderKeys(values), [values]);
	const [, extractedPlaceholders] = useMemo(() => extractVariables(lanes, placeholders), [placeholders]);
	const fromExperimentName = listPlaceholders(experimentName);
	const fromHypothesis = listPlaceholders(hypothesis);

	const [selectedIndex, selectIndex] = useState<number>(placeholders.length === 0 ? -1 : 0);
	const selectedPlaceholder = placeholders[selectedIndex];

	useEffect(() => {
		if (selectedIndex === -1 && placeholders.length > 0) {
			selectIndex(0);
		}
		if (selectedIndex >= placeholders.length) {
			selectIndex(placeholders.length - 1);
		}
	}, [selectedIndex, placeholders]);

	const onDragEnd = (_result: DropResult): void => {
		if (_result.destination) {
			const switchedPlaceholders = Array.from(placeholders);
			const [removed] = switchedPlaceholders.splice(_result.source.index, 1);
			switchedPlaceholders.splice(_result.destination.index, 0, removed);
			setFieldValue('placeholders', switchedPlaceholders);

			if (_result.source.index === selectedIndex) {
				selectIndex(_result.destination.index);
			}
		}
	};

	return (
		<Stack alignItems="center" py="large" overflowY="auto" height="calc( 100vh - 155px)">
			<Stack width="100%" maxWidth="1200px">
				<Text variant="largeStrong" color="neutral800">
					Template Placeholders
				</Text>

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '340px 1fr',
						border: '1px solid ' + theme.colors.neutral300,
					}}
				>
					<Stack
						size="none"
						sx={{
							borderRight: '1px solid ' + theme.colors.neutral300,
							p: 'small',
						}}
					>
						<Button
							variant="chromeless"
							color="slate"
							onClick={() => {
								setValues(
									{
										...values,
										placeholders: [...placeholders, { key: '', name: '', description: '', protected: true }],
									},
									false,
								);
								selectIndex(placeholders.length);
							}}
							sx={{
								width: 'fit-content',
								py: 'xSmall',
								pl: 'none',
								pr: 'xSmall',
								'&:hover': {
									color: theme.colors.neutral800,
								},
							}}
						>
							<IconAdd mr="xSmall" />
							<Text variant="mediumStrong">New placeholder</Text>
						</Button>

						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{placeholders.map((placeholder, index) => {
											return (
												<Draggable key={placeholder.key} index={index} draggableId={placeholder.key}>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															style={{
																...provided.draggableProps.style,
																marginTop: 16,
															}}
														>
															<Placeholder
																key={index}
																dragHandleProps={provided.dragHandleProps}
																isSelected={index === selectedIndex}
																placeholder={placeholder}
																onClick={() => selectIndex(index)}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</Stack>

					<Stack p="medium">
						{selectedPlaceholder && (
							<SelectedPlaceholder
								key={selectedPlaceholder.key}
								experimentName={fromExperimentName.includes(selectedPlaceholder.key) ? experimentName : undefined}
								hypothesis={fromHypothesis.includes(selectedPlaceholder.key) ? hypothesis : undefined}
								steps={extractedPlaceholders.get(selectedPlaceholder.key)?.steps || []}
								currentUsedPlaceholderKeys={currentUsedPlaceholderKeys}
								placeholder={selectedPlaceholder}
								placeholders={placeholders}
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								errors={get(errors, `placeholders[${selectedIndex}]`)}
								onDelete={() => {
									setValues(
										{ ...values, placeholders: placeholders.filter((_, index) => index !== selectedIndex) },
										false,
									);
									selectIndex(-1);
									setFieldTouched('placeholders', true);
								}}
								onApplyAndReplace={(title, name, description) => {
									const key = wrapWithPlaceholderMarkers(selectedPlaceholder.key);
									const value = wrapWithPlaceholderMarkers(title);
									setValues(
										{
											...values,
											experimentName: replaceStringMarker(values.experimentName, key, value),
											hypothesis: replaceStringMarker(values.hypothesis, key, value),
											lanes: replaceLaneMarker(values.lanes, key, value),
											placeholders: placeholders.map((p, index) =>
												index === selectedIndex ? { key: title, name, description, protected: true } : p,
											),
										},
										false,
									);
									setFieldTouched('placeholders', true);
								}}
								onApply={(title, name, description) => {
									setValues(
										{
											...values,
											placeholders: placeholders.map((p, index) =>
												index === selectedIndex ? { key: title, name, description, protected: true } : p,
											),
										},
										false,
									);
									setFieldTouched('placeholders', true);
								}}
							/>
						)}
					</Stack>
				</div>
			</Stack>
		</Stack>
	);
}

interface PlaceholderProps {
	dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
	placeholder: TemplatePlaceholderVO;
	isSelected: boolean;
	onClick: () => void;
}

function Placeholder({ placeholder, isSelected, dragHandleProps, onClick }: PlaceholderProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			sx={{
				alignItems: 'center',
				justifyContent: 'space-between',
				p: 'small',
				border: '1px solid ',
				borderColor: isSelected ? theme.colors.slate : theme.colors.neutral300,
				backgroundColor: isSelected ? theme.colors.neutral100 : theme.colors.neutral000,
				outline: isSelected ? '1px solid ' + theme.colors.slate : '1px solid transparent',
				borderRadius: '4px',

				'&:hover': {
					cursor: 'pointer',
					outline: '1px solid ' + theme.colors.slate,
					backgroundColor: theme.colors.neutral100,
					borderColor: theme.colors.slate,
				},
			}}
			onClick={onClick}
		>
			<PlaceholderPill placeholder={`[[${placeholder.key}]]`} />
			<Stack direction="horizontal" alignItems="center" size="xxSmall">
				{(!placeholder.description || !placeholder.name) && (
					<Tooltip content="This placeholder has no description">
						<div>
							<IconWarningCircle
								color="coral"
								sx={{
									minWidth: '24px',
									minHeight: '24px',
								}}
							/>
						</div>
					</Tooltip>
				)}
				<div {...dragHandleProps}>
					<IconHandle variant="small" color="neutral500" mx="xxSmall" />
				</div>
			</Stack>
		</Stack>
	);
}

interface SelectedPlaceholderProps {
	errors: { [index: string]: TemplateError } | undefined;
	placeholders: TemplatePlaceholderVO[];
	currentUsedPlaceholderKeys: string[];
	placeholder: TemplatePlaceholderVO;
	steps: BaseExperimentStepVOUnion[];
	experimentName: string | undefined;
	hypothesis: string | undefined;
	onApplyAndReplace: (title: string, name: string, description: string) => void;
	onApply: (title: string, name: string, description: string) => void;
	onDelete: () => void;
}

function SelectedPlaceholder({
	currentUsedPlaceholderKeys,
	experimentName,
	placeholders,
	placeholder,
	errors = {},
	hypothesis,
	steps,
	onApplyAndReplace,
	onDelete,
	onApply,
}: SelectedPlaceholderProps): ReactElement {
	const [title, setTitle] = useState(placeholder.key);
	const { actions: availableActions } = useActions();
	const canBeDeleted = !currentUsedPlaceholderKeys.includes(placeholder.key);

	const isUnique = !placeholders.filter((p) => p.key !== placeholder.key).some((p) => p.key === title);

	return (
		<Stack size="large">
			<Stack direction="horizontal" justifyContent="space-between">
				<PlaceholderPill placeholder={`[[${placeholder.key}]]`} />

				<Tooltip content={canBeDeleted ? undefined : 'This placeholder is currently used'}>
					<Container>
						<Button
							variant="chromeless"
							color="neutral600"
							onClick={onDelete}
							disabled={!canBeDeleted}
							sx={{
								'&:hover': {
									color: theme.colors.slate,
								},
							}}
						>
							<IconTrash mr="xSmall" />
							<Text variant="mediumStrong">Delete placeholder</Text>
						</Button>
					</Container>
				</Tooltip>
			</Stack>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					gap: '32px',
					padding: '16px',
					backgroundColor: theme.colors.neutral100,
					borderRadius: '4px',
				}}
			>
				<Stack size="xxSmall">
					<Stack size="xxSmall" direction={'horizontal'}>
						<Text variant="mediumStrong" color="neutral800">
							Placeholder Key*
						</Text>
						<Tooltip
							content={'The placeholder key needs to be unique and will never be shown to the user of the template'}
						>
							<div style={{ lineHeight: '16px' }}>
								<IconInformationCircle variant="small" color="neutral400" mt={2} />
							</div>
						</Tooltip>
					</Stack>
					<Text variant="small" color="neutral600" minHeight="58px">
						Provide the technical identifier by which you want to reference this placeholder in the Experiment tab of
						the template editor
					</Text>
					<Stack direction="horizontal" size="none">
						<PlaceholderMarker marker="[[" left />
						<TextField
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="placeholder-key"
							hasError={!isUnique || Boolean(errors.key)}
							sx={{
								borderRadius: 0,
								borderLeft: !isUnique || Boolean(errors.key) ? '1px solid ' + theme.colors.coral : 'none',
								borderRight: !isUnique || Boolean(errors.key) ? '1px solid ' + theme.colors.coral : 'none',
							}}
							onBlur={() => {
								if (isUnique) {
									onApplyAndReplace(title, placeholder.name, placeholder.description);
								}
							}}
						/>
						<PlaceholderMarker marker="]]" />
					</Stack>
					{!isUnique && (
						<ErrorMessage type="xSmall" withIcon>
							The placeholder with that name already exists
						</ErrorMessage>
					)}
					{errors.key && (
						<ErrorMessage type="xSmall" level={errors.key.level} withIcon>
							{errors.key.message}
						</ErrorMessage>
					)}
				</Stack>

				<Stack size="xxSmall">
					<Stack size="xxSmall" direction={'horizontal'}>
						<Text variant="mediumStrong" color="neutral800">
							Displayed Name*
						</Text>
						<Tooltip
							content={
								'This will be used whenever this placeholder is referenced in the dialogue that users see when creating an experiment based off this template'
							}
							bindWidth={{ target: 'reference', offset: 500 }}
						>
							<div style={{ lineHeight: '16px' }}>
								<IconInformationCircle variant="small" color="neutral400" mt={2} />
							</div>
						</Tooltip>
					</Stack>

					<Text variant="small" color="neutral600" minHeight="58px">
						Provide a human-readable name for this placeholder
					</Text>
					<Stack direction="horizontal" size="none">
						<TextField
							value={placeholder.name}
							onChange={(e) => onApply(title, e.target.value, placeholder.description)}
							placeholder="A placeholder"
							hasError={Boolean(errors.name)}
						/>
					</Stack>
					{errors.name && (
						<ErrorMessage type="xSmall" level={errors.name.level} withIcon>
							{errors.name.message}
						</ErrorMessage>
					)}
				</Stack>

				<Stack size="xxSmall" sx={{ gridColumn: 'span 2' }}>
					<Text variant="mediumStrong" color="neutral800">
						Description*
					</Text>
					<Text variant="small" color="neutral600">
						Describe the placeholder to make the intention clear for a user of your template. It helps to pose this as a
						question, e.g., &apos;What is the URL of the load-balanced HTTP endpoint served by the system under
						test?&apos;
					</Text>
					<DescriptionEditor
						value={placeholder.description}
						hasError={Boolean(errors.description)}
						onChange={(_description) => onApply(title, placeholder.name, _description)}
					/>

					{errors.description && (
						<ErrorMessage type="xSmall" level={errors.description.level} withIcon>
							{errors.description.message}
						</ErrorMessage>
					)}
				</Stack>
			</div>
			{experimentName && (
				<Stack size="xSmall">
					<Text as="span" variant="medium" color="neutral800">
						This placeholder is used in the{' '}
						<Text as="span" variant="mediumStrong" color="neutral800">
							Experiment name:
						</Text>
					</Text>
					<TextWithHighlighting text={experimentName} />
				</Stack>
			)}
			{hypothesis && (
				<Stack size="xSmall">
					<Text as="span" variant="medium" color="neutral800">
						This placeholder is used in the{' '}
						<Text as="span" variant="mediumStrong" color="neutral800">
							Experiment hypothesis:
						</Text>
					</Text>
					<TextWithHighlighting text={hypothesis} />
				</Stack>
			)}
			{steps.length > 0 && (
				<>
					<div
						style={{
							height: '1px',
							width: '100%',
							backgroundColor: theme.colors.neutral300,
						}}
					/>
					<Stack size="xSmall">
						<Text variant="largeStrong" color="neutral800">
							This placeholder is used in the following actions:
						</Text>
						<Steps
							placeholder={placeholder.key}
							steps={steps}
							actionsResult={createResult(availableActions)}
							errorneousSteps={new Set()}
						/>
					</Stack>
				</>
			)}
		</Stack>
	);
}

function TextWithHighlighting({ text }: { text: string }): ReactElement {
	return (
		<div
			style={{
				background: theme.colors.neutral150,
				gap: '8px',
				padding: '8px 20px',
				borderRadius: 'xxSmall',
				width: 'calc(100% - 40px)',
				overflow: 'auto',
			}}
		>
			<Chunks value={text} />
		</div>
	);
}
