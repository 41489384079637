/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconArrowDropDown, IconArrowDropUp } from 'components/icons';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, RefObject } from 'react';
import { ButtonProps } from 'components/Button';
import { Button, Stack } from 'components';
import { Text } from 'components/Text';

import { GeneralDropdownProps, renderChildren } from './types';
import Dropdown from './Dropdown';

interface DropdownButtonProps<T> extends GeneralDropdownProps<T>, Omit<ButtonProps, 'children'> {
	customContent?: ReactElement;
	children: renderChildren<T>;
	hideChevrons?: boolean;
	icon?: ReactElement;
}

export default function DropDownButton<T = string>(props: DropdownButtonProps<T>): ReactElement {
	const {
		icon,
		variant = 'primary',
		onValueChanged,
		customContent,
		hasError,
		disabled,
		width,
		hideChevrons = false,
		...rest
	} = props;

	return (
		<Dropdown<T>
			{...props}
			onValueChanged={onValueChanged}
			renderComponent={(_props) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { setShowMenu, showMenu, value, ref, onValueChanged, ..._rest } = _props;
				return (
					<Button
						{...rest}
						{..._rest}
						variant={variant}
						width={width}
						disabled={disabled}
						onClick={() => setShowMenu(!showMenu)}
						ref={ref as RefObject<HTMLButtonElement>}
						bg={hasError ? 'coral' : undefined}
					>
						<Stack
							direction="horizontal"
							size="xxSmall"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							{customContent ? (
								customContent
							) : (
								<Stack direction="horizontal" size="xSmall" alignItems="center">
									{icon}
									<Text sx={{ ...textEllipsis }}>{value}</Text>
								</Stack>
							)}

							{!hideChevrons && <Chevrons showMenu={showMenu} />}
						</Stack>
					</Button>
				);
			}}
		/>
	);
}

function Chevrons({ showMenu }: { showMenu: boolean }): ReactElement {
	if (showMenu) {
		return <IconArrowDropUp minWidth={16} minHeight={16} variant={'small'} />;
	}
	return <IconArrowDropDown minWidth={16} minHeight={16} variant={'small'} />;
}
