/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, FormikTextField, Label, OptionTypeBase, Select, Stack, Text } from 'components';
import { WebhookScope, WebhookTypes } from 'services/webhooksApi';
import { useAutoFocus } from 'utils/hooks/useAutoFocus';
import { TeamCombobox } from 'hocs/TeamCombobox';
import { useTenant } from 'tenancy/useTenant';
import { CreateWebhookRequest } from 'ui-api';
import { useFormikContext } from 'formik';
import React from 'react';

import { WebhookEventsInput } from './webhooksEventsInput';
import { WebhookParameters } from './webhookParameters';

export type WebhookFormValues = Omit<CreateWebhookRequest, 'type'>;

interface WebhookFormProps {
	disabled?: string[] | boolean;
	type: string;
}

export const WebhookForm: React.VFC<WebhookFormProps> = ({ type, disabled }) => {
	const isDisabled = (name: string): boolean => (Array.isArray(disabled) ? disabled.includes(name) : Boolean(disabled));
	const autoFocusRef = useAutoFocus();

	return (
		<Stack size={'medium'}>
			<FormWebhookScope disabled={isDisabled('scope')} />

			<FormikTextField
				label={'Name'}
				name={'name'}
				maxLength={255}
				ref={autoFocusRef}
				required
				autoComplete={'off'}
				disabled={isDisabled('name')}
			/>

			<FormikTextField
				label={'Webhook URL'}
				name={'url'}
				maxLength={1024}
				required
				autoComplete={'off'}
				disabled={isDisabled('url')}
			/>

			<FormWebhookParameters disabled={isDisabled('parameters')} type={type} />

			<FormWebhookEventsInput type={type} disabled={isDisabled('events')} />
		</Stack>
	);
};

const FormWebhookScope: React.VFC<{ disabled?: boolean }> = ({ disabled }) => {
	const formik = useFormikContext<WebhookFormValues>();
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';

	const handleScopeChange = (option?: OptionTypeBase | null): void => {
		if (option && 'value' in option && option.value !== formik.values.scope) {
			formik.setFieldValue('scope', option?.value);
			formik.setFieldTouched('scope');
		}
		if (option?.value !== WebhookScope.TEAM.value) {
			formik.setFieldValue('team', '');
			formik.setFieldTouched('team');
		}
	};

	const hasError = !!formik.errors.team;

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gridGap: 'small',
				alignItems: 'flex-start',
			}}
		>
			<Label>
				Scope
				<Select
					mt={'xSmall'}
					value={WebhookScope[formik.values.scope]}
					options={isAdmin ? [WebhookScope.GLOBAL, WebhookScope.TEAM] : [WebhookScope.TEAM]}
					disabled={disabled}
					onChange={handleScopeChange}
				/>
			</Label>
			<Stack size="xSmall">
				{formik.values.scope === 'TEAM' ? (
					<Label>
						Team
						<TeamCombobox
							mt={'xSmall'}
							value={formik.values.team}
							name={'team'}
							onChange={(v) => {
								formik.setFieldValue('team', v);
								formik.setFieldTouched('team');
							}}
							required
							disabled={disabled}
							clearable={isAdmin}
							myTeamsOnly={!isAdmin}
							hasError={hasError}
						/>
					</Label>
				) : null}
				{hasError ? (
					<Text mt={'xxSmall'} variant={'smallStrong'} color={'coral'} data-formik-error>
						{formik.errors.team}
					</Text>
				) : null}
			</Stack>
		</Container>
	);
};

const FormWebhookEventsInput: React.VFC<{ type: string; disabled?: boolean }> = ({ type, disabled }) => {
	const formik = useFormikContext<WebhookFormValues>();
	return (
		<WebhookEventsInput
			value={formik.values.events}
			name={'events'}
			onChange={(v) => formik.setFieldValue('events', v)}
			type={type}
			disabled={disabled}
		/>
	);
};

const FormWebhookParameters: React.VFC<{ disabled?: boolean; type: string }> = ({ disabled, type }) => {
	const selectedType = WebhookTypes.find((w) => w.type === type);
	if (!selectedType) {
		return null;
	}
	return <WebhookParameters type={selectedType} disabled={disabled} />;
};
