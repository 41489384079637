/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useActionHierarchy, { ActionCategory as ActionCategoryA, Grouping } from 'experiment/actions/useActionHierarchy';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import { Box, Flex, Text } from '@steadybit/ui-components-lib';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { getGlobal, setGlobal } from 'utils/localStorage';
import { ReactElement, useEffect, useState } from 'react';
import { IconAdvanced } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { ActionVO } from 'ui-api';
import { ampli } from 'ampli';

import { ActionCategory, ActionCategoryItem } from './types';
import ActionGroup from './ActionGroup';

interface ActionsContentProps {
	selectedActionId?: string;
	isSearchDefined: boolean;
	collapsed?: boolean;
	actions: ActionVO[];
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function ActionsContent({
	selectedActionId,
	isSearchDefined,
	collapsed,
	actions,
	onActionClick,
}: ActionsContentProps): ReactElement {
	const [selectedGrouping, setSelectedGrouping] = useState<Grouping>(
		getGlobal('experimentActionGrouping') === 'targetType' ? 'targetType' : 'technology',
	);
	const actionCategories = useActionHierarchy({ actions, grouping: selectedGrouping });

	useEffect(() => {
		ampli.experimentSidebarGrouped({
			group_by: selectedGrouping === 'targetType' ? 'legacy' : 'technology',
		});
	}, [selectedGrouping]);

	return (
		<Flex
			className="experiment-collapsed-sidebar"
			style={{
				overflowY: 'auto',
				py: 'xSmall',
				px: collapsed ? 'none' : 'xSmall',
				width: collapsed ? 'calc(100% + 16px)' : 'calc(100% - 16px)',
				paddingLeft: collapsed ? '0px' : 'xSmall',
			}}
		>
			{actions.length === 0 ? (
				<Box style={{ p: 'small' }}>
					<Text type="medium" style={{ color: theme.colors.neutral500 }}>
						{isSearchDefined ? 'No actions matched your query.' : 'No actions found.'}
					</Text>
				</Box>
			) : (
				<Flex style={{ width: '100%' }}>
					{!collapsed && (
						<DropDownButton
							variant="chromeless"
							value={selectedGrouping === 'targetType' ? 'Target Type' : 'Technology'}
							onValueChanged={(v) => {
								setSelectedGrouping(v as Grouping);
								setGlobal('experimentActionGrouping', v);
								ampli.experimentSidebarGrouped({
									group_by: v === 'targetType' ? 'legacy' : 'technology',
								});
							}}
							width="100%"
							icon={<IconAdvanced variant="small" ml="4px" />}
							sx={{
								cursor: 'pointer',
								border: '1px solid ' + theme.colors.neutral200,
								p: 'xSmall',
								marginBottom: '12px',
							}}
						>
							{({ width, selectItem }) => (
								<DropdownContentFrame>
									<Labels
										selectedId={selectedGrouping}
										type="loose"
										onSelect={({ id }) => selectItem(id)}
										labels={[
											{
												id: 'technology',
												label: 'Technology',
											},
											{
												id: 'targetType',
												label: 'Target Type',
											},
										]}
										width={width}
										withoutTooltip
									/>
								</DropdownContentFrame>
							)}
						</DropDownButton>
					)}

					{actionCategories.map((category) => (
						<ActionGroup
							selectedActionId={selectedActionId}
							group={mapCategory(category)}
							collapsed={collapsed}
							key={category.label}
							onActionClick={onActionClick}
						/>
					))}
				</Flex>
			)}
		</Flex>
	);
}

function mapCategory(category: ActionCategoryA): ActionCategory {
	return {
		...category,
		actions: category.actions
			? category.actions.map((a) => ({
					id: a.action.id,
					label: a.label,
				}))
			: undefined,
		subCategories: category.subCategories?.map((subCategory) => ({
			...subCategory,
			actions: subCategory.actions.map((a) => ({
				id: a.action.id,
				label: a.label,
			})),
		})),
	};
}
