/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { CompositeTargetPredicateVO, TargetPredicateVO } from 'ui-api';

import QueryLanguageParserVisitor from './generated/QueryLanguageParserVisitor';

type ReturnType = TargetPredicateVO | undefined;

export class QueryLanguageToTargetPredicateVisitor extends QueryLanguageParserVisitor {
	visitTopLevelQuery(ctx: any): ReturnType {
		const query = ctx.query();
		if (query != null) {
			return ctx.query().accept(this);
		}
	}

	visitQuery(ctx: any): ReturnType {
		const disjQuery = ctx.disjQuery();
		if (disjQuery != null) {
			return disjQuery.accept(this);
		}
	}

	visitDisjQuery(ctx: any): ReturnType {
		let predicate: TargetPredicateVO | undefined;

		for (const conjQuery of ctx.conjQuery()) {
			const nextPredicate = conjQuery.accept(this);
			if (nextPredicate != null) {
				predicate = or(predicate, nextPredicate);
			}
		}

		return predicate;
	}

	visitConjQuery(ctx: any): ReturnType {
		let predicate: TargetPredicateVO | undefined;

		for (const modClauseCtx of ctx.modClause()) {
			const nextPredicate = modClauseCtx.accept(this);
			if (nextPredicate != null) {
				predicate = and(predicate, nextPredicate);
			}
		}

		return predicate;
	}

	visitModClause(ctx: any): ReturnType {
		const predicate = ctx.clause().accept(this);
		if (ctx.modifier() != null && predicate != null) {
			return {
				not: predicate,
			};
		}
		return predicate;
	}

	visitModifier(): void {
		throw new Error('Unexpected modifier');
	}

	visitClause(ctx: any): ReturnType {
		const groupingExpr = ctx.groupingExpr();
		if (groupingExpr != null) {
			return groupingExpr.accept(this);
		}

		const key = getContent(ctx.fieldName().TERM(), ctx.fieldName().quotedTerm());

		const is = ctx.IS() != null;
		const not = ctx.NOT() != null;
		const present = ctx.PRESENT() != null;

		if (is && not && present) {
			return { key, presenceOperator: 'NOT_PRESENT' };
		} else if (is && present) {
			return { key, presenceOperator: 'PRESENT' };
		}

		const value = getContent(ctx.term().TERM(), ctx.term().quotedTerm());

		const count = ctx.COUNT() != null;
		if (count) {
			let valueCountOperator = null;
			if (ctx.OP_EQUAL() != null) {
				valueCountOperator = 'EQUAL';
			} else if (ctx.OP_NOT_EQUAL() != null) {
				valueCountOperator = 'NOT_EQUAL';
			} else if (ctx.OP_GREATER_THAN() != null) {
				valueCountOperator = 'GREATER_THAN';
			} else if (ctx.OP_LESS_THAN() != null) {
				valueCountOperator = 'LESS_THAN';
			} else if (ctx.OP_GREATER_THAN_EQUAL() != null) {
				valueCountOperator = 'GREATER_THAN_OR_EQUAL';
			} else if (ctx.OP_LESS_THAN_EQUAL() != null) {
				valueCountOperator = 'LESS_THAN_OR_EQUAL';
			} else {
				throw new Error('Unsupported clause operator.');
			}

			return {
				key,
				valueCountOperator,
				values: [value],
			};
		}

		let operator = null;
		if (ctx.OP_EQUAL() != null) {
			operator = 'EQUALS';
		} else if (ctx.OP_NOT_EQUAL() != null) {
			operator = 'NOT_EQUALS';
		} else if (ctx.OP_TILDE() != null) {
			operator = 'CONTAINS';
		} else if (ctx.OP_NOT_TILDE() != null) {
			operator = 'NOT_CONTAINS';
		} else if (ctx.OP_EQUAL_IGNORE_CASE() != null) {
			operator = 'EQUALS_IGNORE_CASE';
		} else if (ctx.OP_NOT_EQUAL_IGNORE_CASE() != null) {
			operator = 'NOT_EQUALS_IGNORE_CASE';
		} else if (ctx.OP_TILDE_IGNORE_CASE() != null) {
			operator = 'CONTAINS_IGNORE_CASE';
		} else if (ctx.OP_NOT_TILDE_IGNORE_CASE() != null) {
			operator = 'NOT_CONTAINS_IGNORE_CASE';
		} else {
			throw new Error('Unsupported clause operator.');
		}

		return {
			key,
			operator,
			values: [value],
		};
	}

	visitGroupingExpr(ctx: any): ReturnType {
		const query = ctx.query();
		if (query != null) {
			return query.accept(this);
		}
	}

	visitFieldName(): ReturnType {
		throw new Error('Unexpected fieldName');
	}

	visitTerm(): ReturnType {
		throw new Error('Unexpected term');
	}

	visitQuotedTerm(): ReturnType {
		throw new Error('Unexpected quotedTerm');
	}
}

function or(existing: ReturnType, add: ReturnType): ReturnType {
	return addCompound('OR', existing, add);
}

function and(existing: ReturnType, add: ReturnType): ReturnType {
	return addCompound('AND', existing, add);
}

function addCompound(operator: string, existing: ReturnType, add: ReturnType): ReturnType {
	if (existing == null) {
		return add;
	}

	if (add == null) {
		return existing;
	}

	if (isCompositeTargetPredicateVO(existing) && existing.operator === operator) {
		return {
			operator,
			predicates: [...existing.predicates, add],
		};
	}

	return {
		operator,
		predicates: [existing, add],
	};
}

export function isCompositeTargetPredicateVO(predicate: any): predicate is CompositeTargetPredicateVO {
	return typeof predicate.operator === 'string' && predicate.predicates instanceof Array;
}

function unescapeValue(value: string): string {
	value = value.replaceAll(/\\"/g, '"');
	value = value.replaceAll(/\\\\/g, '\\');
	return value;
}

function getContent(term: any, quotedTerm: any): string {
	let content: string;
	if (term != null) {
		content = term.getText();
	} else if (quotedTerm != null) {
		content = quotedTerm.QUOTED().getText();
		// strip leading and trailing " that is part of the QUOTED string
		content = content.substring(1, content.length - 1);
	} else {
		throw new Error('No content defined.');
	}
	return unescapeValue(content);
}
