/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Divider, Link, Stack, Text, TextField, userConfirmV2 } from 'components';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import EnvVariableInputs from 'components/EnvVariables/EnvVariableInputs';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import EnvVariables from 'components/EnvVariables/EnvVariables';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import useFeatureFlag from 'services/useFeatureFlag';
import { IconAdd } from 'components/icons';
import { VariableVO } from 'ui-api';
import { useField } from 'formik';

interface EnvironmentAndExperimentVariablesProps {
	width: string | number | undefined;
	allowAddNew?: boolean;
	onVariableSelected: (variable: string) => void;
}

export default function EnvironmentAndExperimentVariables({
	allowAddNew = true,
	width,
	onVariableSelected,
}: EnvironmentAndExperimentVariablesProps): ReactElement | null {
	const [{ value: environmentId }] = useField<string>('environmentId');
	const [{ value: variables = [] }] = useField<VariableVO[]>('variables');
	const [{ value: experimentVariables = [] }, , { setValue, setTouched }] =
		useField<VariableVO[]>('experimentVariables');
	const environmentAndExperimentVariables = [...experimentVariables, ...variables];

	const experimentVariablesEnabled = useFeatureFlag('experimentVariablesEnabled');
	const { mode: editorMode } = useEditorSettings();

	return (
		<Stack size="none">
			<div style={{ width, flexGrow: 1 }}>
				<EnvVariables
					variables={environmentAndExperimentVariables}
					onVariableClick={(key) => onVariableSelected(`{{${key}}}`)}
				/>
			</div>
			<Divider />
			{allowAddNew && (
				<div style={{ display: 'flex' }}>
					<Button
						variant="chromelessSmall"
						onClick={async () => {
							if (experimentVariablesEnabled) {
								return await userConfirmV2({
									title: 'Add new experiment variable',
									message: ({ setDisabled }) => (
										<AddExperimentVariableContent
											variables={experimentVariables}
											environmentId={environmentId}
											setDisabled={setDisabled}
										/>
									),
									actions: [
										{
											value: 'confirm',
											label: 'Add',
											variant: 'primary',
											dataCy: 'create-env-var',
											action: async () => {
												const key = (document.getElementById('env-variable-key') as HTMLInputElement)?.value;
												const value = (document.getElementById('env-variable-value') as HTMLInputElement)?.value;

												if (key && value) {
													if (editorMode === 'experiment') {
														setValue([...experimentVariables, { key, value }]);
														setTouched(true);
													}
													onVariableSelected(`{{${key}}}`);
												}
											},
										},
									],
									secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
									width: '860px',
								});
							}

							await userConfirmV2({
								title: 'Add Environment Variable',
								message: ({ setDisabled }) => <AddEnvVarContent setDisabled={setDisabled} />,
								actions: [
									{
										value: 'confirm',
										label: 'Add',
										variant: 'primary',
										dataCy: 'create-env-var',
										action: async () => {
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											const newEnvVar = document.getElementById('new-env-var-value')?.value;
											if (newEnvVar) {
												onVariableSelected(`{{${newEnvVar}}}`);
											}
										},
									},
								],
								secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
								width: '700px',
							});
						}}
						data-cy="add-env-var"
					>
						<IconAdd variant="small" mr="xxSmall" />
						<span style={{ ...textEllipsis }}>
							{experimentVariablesEnabled ? 'Add experiment variable' : 'Add environment variable'}
						</span>
					</Button>
				</div>
			)}
		</Stack>
	);
}

function AddExperimentVariableContent({
	environmentId,
	variables,
	setDisabled,
}: {
	variables: VariableVO[];
	environmentId: string;
	setDisabled: (disabled: boolean) => void;
}): ReactElement {
	const [key, setKey] = useState('');
	const [value, setValue] = useState('');

	useEffect(() => {
		setDisabled(!key || !value || variables.some((v) => v.key === key));
	}, [key, value, setDisabled]);

	return (
		<Stack size="small">
			<Text as="span">
				You can use this variable within the scope of this{' '}
				<Text variant="mediumStrong" as="span">
					experiment
				</Text>
				.
			</Text>
			<EnvVariableInputs variableKey={key} variableValue={value} setVariableKey={setKey} setVariableValue={setValue} />

			<Text as="span" variant="medium" color="neutral600">
				Do you need to create an environment variable? You can create one directly from the
				<Link href={`/settings/environments/${environmentId}/variables`} ml="xxSmall" external dontResolve>
					<Text as="span" variant="mediumStrong" color="slate">
						environment settings
					</Text>
				</Link>
				.
			</Text>
		</Stack>
	);
}

function AddEnvVarContent({ setDisabled }: { setDisabled: (disabled: boolean) => void }): ReactElement {
	const [value, setValue] = useState<string>('');
	useEffect(() => {
		setDisabled(!value);
	}, [value]);

	return (
		<Stack size="xxSmall">
			<Text as="span" variant="mediumStrong" color="neutral800">
				Name
			</Text>
			<Stack direction="horizontal" size="none">
				<PlaceholderMarker marker="{{" left />
				<TextField
					id="new-env-var-value"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder="ENVIRONMENT VARIABLE"
					sx={{
						borderRadius: 0,
						borderLeft: 'none',
						borderRight: 'none',
					}}
				/>
				<PlaceholderMarker marker="}}" />
			</Stack>
		</Stack>
	);
}
