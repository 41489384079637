/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, ExperimentStepActionVO, ExperimentStepRadiusVO, ExperimentStepWaitVO, ParameterVO } from 'ui-api';
import { v4 as uuidv4 } from 'uuid';

export function instantiateWait(): ExperimentStepWaitVO {
	return {
		id: uuidv4(),
		type: 'wait',
		parameters: {
			duration: '10s',
		},
		metricQueries: [],
		metricChecks: [],
		ignoreFailure: false,
	};
}

export function instantiateAction(actionId: string, action: ActionVO): ExperimentStepActionVO {
	return {
		id: uuidv4(),
		type: 'action',
		actionId,
		ignoreFailure: false,
		blastRadius: initBlastRadius(action),
		parameters: getInitialFieldValues(action.parameters),
		metricQueries: [],
		metricChecks: [],
	};
}

function initBlastRadius(action: ActionVO): ExperimentStepRadiusVO {
	const radius = {
		targetType: action.target.type,
		predicate: undefined,
		percentage: undefined,
		maximum: undefined,
	};
	if (action.quantityRestriction === 'NONE') {
		return {
			...radius,
			percentage: 100,
		};
	}
	return radius;
}

function getInitialFieldValues(parameters: ParameterVO[]): Record<string, string | number> {
	const initialValueEntries = parameters.map(({ type, name, required, defaultValue }) => {
		if (type === 'string[]' || type === 'key-value') {
			return [name, defaultValue ? parseStringArrayDefaultValue(defaultValue) : []];
		} else if (type === 'integer' || type === 'percentage' || type === 'stressng-workers') {
			return [name, parseInt(defaultValue || '') ?? ''];
		} else if (type === 'boolean') {
			return [name, defaultValue === 'true'];
		}
		return [name, defaultValue ?? (required ? '' : undefined)];
	});
	return Object.fromEntries(initialValueEntries);
}

function parseStringArrayDefaultValue(value: string): string[] {
	try {
		return JSON.parse(value);
	} catch {
		return [value];
	}
}
