/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';

interface SectionProps {
	title: string | ReactNode;
	children: ReactElement | ReactElement[];
}

export default function Section({ title, children }: SectionProps): ReactElement {
	return (
		<Flex style={{ p: 'small' }}>
			<Text
				type="mediumStrong"
				style={{
					color: Colors.neutral800,
					height: '36px',
				}}
			>
				{title}
			</Text>
			{children}
		</Flex>
	);
}
