/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import React from 'react';

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		this.props.log?.(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div
					style={{
						padding: '12px',
						backgroundColor: theme.colors.coral100,
					}}
				>
					<ErrorMessage withIcon>There is an unexpected error in the component.</ErrorMessage>
				</div>
			);
		}

		return this.props.children;
	}
}
