/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { Stack } from 'components';
import { useField } from 'formik';

import StepConfigurationSidebar from './StepConfigurationSidebar/StepConfigurationSidebar';
import MissingEnvironmentBlurOverlay from './MissingEnvironmentBlurOverlay';
import { ActionVO, ExperimentExecutionVO } from '../../ui-api';
import { EditorSettingsContext } from './useEditorSettings';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import ExperimentSubHeader from './ExperimentSubHeader';
import StepsSidebar from './StepsSidebar/StepsSidebar';
import DragAndDropHandler from './DragAndDropHandler';
import ExperimentHeader from './ExperimentHeader';
import { TemplatesContext } from './useTemplates';
import Workspace from './Workspace/Workspace';
import { ActionsContext } from './useActions';

interface ExperimentEditorProps {
	experimentExecution?: ExperimentExecutionVO;
}

export default function ExperimentEditor({ experimentExecution }: ExperimentEditorProps): ReactElement {
	const [{ value: teamId }] = useField<string>('teamId');

	const templatesResult = useRefreshingTemplates({
		searchContext: 'NEW_EXPERIMENT',
		pageSize: 1_000_000,
		pathname: '/not-used',
		teamId,
	});

	const [actions, allActions] = useActions(teamId);

	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = applicationHeaderHeight + 48 + 80; // experiment header and subheader

	return (
		<EditorSettingsContext.Provider value={{ mode: 'experiment' }}>
			<ActionsContext.Provider value={{ actions, allActions }}>
				<TemplatesContext.Provider value={{ templates: templatesResult.value?.content || [] }}>
					<UnsavedChangesPrompt />
					<Stack size="none">
						<ExperimentHeader section="design" experimentExecution={experimentExecution} />
						<ExperimentSubHeader />

						{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
						<DragAndDropHandler>
							<Stack direction="horizontal" size="none">
								<StepsSidebar height={height} />
								<Workspace height={height} />
							</Stack>
						</DragAndDropHandler>

						<MissingEnvironmentBlurOverlay />

						<StepConfigurationSidebar />
					</Stack>
				</TemplatesContext.Provider>
			</ActionsContext.Provider>
		</EditorSettingsContext.Provider>
	);
}

function useActions(teamId: string): [ActionVO[], ActionVO[]] {
	const allActionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const allActions = allActionsResult.value || [];

	const allowedActionIdsResult = usePromise(() => Services.experiments.fetchActionIdsForTeam(teamId), []);
	const allowedActionIds = allowedActionIdsResult.value ?? [];

	return [allActions.filter((a) => allowedActionIds.includes(a.id)), allActions];
}
