/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Text, Tooltip } from 'components';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { getColorForState, getIconForState, isCanceled, isNotEnded, isSucceeded } from './utils';
import { ExperimentExecutionLogLabelLine } from './experimentExecutionLog';
import ExperimentExecutionLogLine from './ExperimentExecutionLogLine';

export default function ExperimentExecutionLogLabel({
	time,
	label,
	state,
}: ExperimentExecutionLogLabelLine): ReactElement {
	const succeeded = isSucceeded(state);
	const canceled = isCanceled(state);
	const running = isNotEnded(state);

	const isBold = !succeeded && !canceled;
	const Icon = getIconForState(state);
	const color = succeeded || canceled ? 'neutral700' : getColorForState(state);

	return (
		<ExperimentExecutionLogLine
			StateComponent={<Icon variant="small" sx={{ color, ml: 'xSmall' }} />}
			TimeComponent={
				<Text variant={isBold ? 'smallStrong' : 'small'} color={color} sx={{ fontVariantNumeric: 'tabular-nums' }}>
					{formatTime(new Date(time))}
				</Text>
			}
			sx={
				running
					? {
							border: '2px dashed ' + theme.colors.purple700,
							backgroundColor: 'purple100',
						}
					: {}
			}
		>
			<Tooltip content={<span data-private>{label}</span>}>
				<Text variant={isBold ? 'smallStrong' : 'small'} color={color} sx={{ ...textEllipsis }} data-private>
					{label}
				</Text>
			</Tooltip>
		</ExperimentExecutionLogLine>
	);
}
