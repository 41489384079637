/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	FieldVO,
	GetActionsOnAgentInfoResponse,
	GetActionsResponse,
	GetActionsSummaryResponse,
} from 'ui-api';
import { has, sortBy, startCase, uniq } from 'lodash';
import { filter } from 'rxjs/operators';
import cached from 'utils/cached';
import axios from 'axios';

import { EventsApi } from './eventsApi';
import { Services } from './services';

export function toParameterLabelValues(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	values: Record<string, any>,
	descriptors: FieldVO[],
): { name: string; label: string; value: string }[] {
	const names = uniq([...descriptors.map((d) => d.name), ...Object.keys(values)]);
	const fields = new Map(descriptors.map((field) => [field.name, field]));

	return names.map((name) => ({
		name,
		label: fields.get(name)?.label ?? name,
		value: getValueString(values[name], fields.get(name)),
	}));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValueString(value: any, field?: FieldVO): string {
	if (value === undefined) {
		return '';
	} else if (Array.isArray(value)) {
		if (!value.length) {
			return '';
		} else if (value.every((entry) => has(entry, 'key') && has(entry, 'value'))) {
			return value.map((entry) => entry['key'] + '=' + entry['value']).join(' | ');
		}
	}
	if (typeof value === 'string' && value.startsWith('file::')) {
		return '';
	}
	if (field?.options?.length) {
		if (Array.isArray(value)) {
			return value.map((v) => getOptionLabel(v, field)).join(', ');
		}
		const option = field.options.find((option) => option.value === value);
		return option ? option.label : value;
	}
	return value;
}

export const ActionEvents = ['action.added', 'action.updated', 'action.deleted'];

const CATEGORY_ORDER = ['resource', 'state', 'network', 'application'];

function sortActionsByCategory(actions: ActionVO[]): ActionVO[] {
	return sortBy(actions, (action) => {
		const idx = CATEGORY_ORDER.indexOf(action.category ?? 'uncategorized');
		return idx >= 0 ? idx : Number.MAX_SAFE_INTEGER;
	});
}

export class ActionsApi {
	constructor(events: EventsApi) {
		//invalidate the cache when an action changes
		events.events$
			.pipe(
				filter((event) => {
					return ActionEvents.includes(event.type);
				}),
			)
			.subscribe(() => {
				this.getNames.invalidateCaches();
				this.fetchActionAsMap.invalidateCaches();
			});
	}

	async fetchActionIds(includeDeleted?: boolean): Promise<string[]> {
		return this.fetchActions(includeDeleted).then((action) => action.map((a) => a.id));
	}
	async fetchActions(includeDeleted?: boolean): Promise<ActionVO[]> {
		const actions = Array.from((await this.fetchActionAsMap()).values())
			.slice()
			.filter((a) => includeDeleted || !a.deleted);

		return sortActionsByCategory(actions);
	}

	async fetchActionSummaries(): Promise<GetActionsSummaryResponse> {
		return (await axios.get<GetActionsSummaryResponse>('/ui/actions/summaries')).data;
	}

	async fetchActionOnAgentInfo(id: string): Promise<GetActionsOnAgentInfoResponse> {
		return (await axios.get<GetActionsOnAgentInfoResponse>('/ui/actions/summaries/' + id)).data;
	}

	async findAction(id: string): Promise<ActionVO | undefined> {
		return (await this.fetchActionAsMap()).get(id);
	}

	async findActionNameWithTargetTypeIfNotUnique(id: string): Promise<string | undefined> {
		const names = await this.getNames();
		return names[id];
	}

	async getActionNamesWithTargetTypeIfNotUnique(ids: string[]): Promise<{ [index: string]: string }> {
		const names = await this.getNames();
		return ids.reduce((obj, id) => ({ ...obj, [id]: names[id] }), {});
	}

	getNames = cached(this.getNamesInternal.bind(this));
	private async getNamesInternal(): Promise<{ [index: string]: string }> {
		const actions = await this.fetchActions(true);
		const targetTypeDefinitions = await Services.targets.getTargetDefinitions();
		return actions
			.map((a1) => {
				if (actions.filter((a2) => a2.name === a1.name && a2.target?.type !== a1.target?.type).length > 0) {
					const definition = targetTypeDefinitions.find((d) => d.id === a1.target.type);
					const targetTypeName = definition ? definition.label.one : startCase(a1.target.type);
					return { ...a1, name: `${targetTypeName} ${a1.name}` };
				} else {
					return a1;
				}
			})
			.reduce((obj, item) => ({ ...obj, [item.id]: item.name }), {});
	}

	fetchActionAsMap = cached(this.fetchActionAsMapInternal.bind(this));
	async fetchActionAsMapInternal(): Promise<Map<string, ActionVO>> {
		const actions = (await axios.get<GetActionsResponse>('/ui/actions')).data.content;
		const result = new Map();
		for (const action of actions) {
			result.set(action.id, action);
		}
		return result;
	}

	async deleteAction(id: string): Promise<void> {
		await axios.delete(`/ui/actions/${id}`);
	}
}
function getOptionLabel(v: string, field: FieldVO): string {
	if (!field?.options) {
		return v;
	}
	const option = field.options.find((o) => o.value === v);
	return option ? option.label : v;
}
