/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { ReactElement, Ref } from 'react';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface IntegerProps {
	hasErrors?: boolean;
	disabled: boolean;
	min?: number;
	max?: number;
	value: string | number;
	setValue: (value: string | number) => void;
}

export default function Integer({ value, min, max, hasErrors, disabled, setValue }: IntegerProps): ReactElement {
	return (
		<Input
			value={value}
			onChange={(_v) => {
				if (typeof _v === 'string') {
					setValue(_v);
					return;
				}
				setValue(_v >= 0 ? _v : 0);
			}}
			hasErrors={hasErrors}
			disabled={disabled}
			min={min}
			max={max}
		/>
	);
}

interface InputProps {
	onChange: (value: number | string) => void;
	value: number | string;
	hasErrors?: boolean;
	withUnit?: boolean;
	disabled: boolean;
	min?: number;
	max?: number;
}

export function Input({
	value,
	onChange,
	min = 0,
	max = Number.MAX_SAFE_INTEGER,
	hasErrors,
	disabled,
	withUnit,
}: InputProps): ReactElement {
	return (
		<Dropdown<string>
			onValueChanged={(value) => onChange(value)}
			renderComponent={({ showMenu, setShowMenu, ref }) => {
				let component;
				if (!showMenu) {
					component = (
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							disabled={disabled}
							onClick={() => setShowMenu(true)}
							withUnit={withUnit}
						/>
					);
				} else {
					let valueAsNumber = Number(value);
					if (isNaN(valueAsNumber)) {
						valueAsNumber = 0;
					}
					const hasError = hasErrors || valueAsNumber < min || valueAsNumber > max;

					component = (
						<Container
							ref={ref}
							as="input"
							type="number"
							min={min}
							max={max}
							// removes leading zeros e.g. 0001 -> 1
							value={valueAsNumber.toString()}
							onChange={(e) => {
								let value = e.target.valueAsNumber;
								// e.target.valueAsNumber is NaN if the input is empty
								if (isNaN(value)) {
									value = 0;
								}
								onChange(value);
							}}
							disabled={disabled}
							autoFocus
							sx={{
								height: '40px',
								px: 'small',
								py: 'xSmall',
								color: 'neutral800',
								fontSize: '15px',
								borderRadius: withUnit ? '4px 0 0 4px' : '4px',
								border: '1px solid',
								borderRight: withUnit ? 'none' : '1px solid',
								borderColor: hasError ? 'coral' : 'neutral300',
								width: '100%',

								':focus': {
									outline: 'none',
									borderColor: hasError ? 'coral' : 'slate',
									boxShadow: `inset 0 0 0 1px ${hasError ? theme.colors.coral : theme.colors.slate}`,
								},
								':disabled': {
									bg: 'neutral100',
									borderColor: hasError ? 'coral' : 'neutral200',
								},
							}}
						/>
					);
				}

				return (
					<FocussableWrapper
						ref={ref as Ref<HTMLDivElement>}
						onFocus={() => setShowMenu(true)}
						onBlur={() => setShowMenu(false)}
						disabled={disabled}
					>
						{component}
					</FocussableWrapper>
				);
			}}
		>
			{({ selectItem, width }) => {
				return (
					<DropdownContentFrame>
						<VariablesAndPlaceholders width={width} selectItem={selectItem} />
					</DropdownContentFrame>
				);
			}}
		</Dropdown>
	);
}
