/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import useFeatureFlag from 'services/useFeatureFlag';
import { Stack, TutorialTooltip } from 'components';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { IconFunction } from 'components/icons';
import { Services } from 'services/services';
import { useUpdateEffect } from 'react-use';
import { TUTORIALS } from 'tutorials';

import ExperimentAndEnvironmentVariables from './ExperimentAndEnvironmentVariables';
import ExperimentEnvironmentVariables from './experimentEnvironmentVariables';
import { ExperimentFormValues } from '../../experiment';

export const ExperimentSubHeaderEnvironments: React.VFC<{
	disabled: boolean;
}> = ({ disabled }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { teamId, environmentId, variables } = formik.values;
	const { setFieldValue, setFieldTouched } = formik;

	useUpdateEffect(
		//when teamId changes reset environmentId
		() => {
			setFieldValue('environmentId', '');
		},
		[teamId, setFieldValue],
	);

	const environmentVariablesResult = Services.environments.getEnvironmentVariables$(environmentId);
	const environmentVariables = environmentVariablesResult.value;

	useEffect(
		//when environmentId changes reset variables
		() => {
			(async () => {
				if (!environmentVariables) {
					return;
				}

				const { variables: usedVariables } = await Services.experiments.getExperimentMetadata(formik.values);
				const newVariables = usedVariables
					.filter((key) => environmentVariables.every((_v) => _v.key !== key))
					.map((key) => {
						// it can happen that non saved variables are already in the form, so dont clear the value
						const value = variables.find((v) => v.key === key)?.value || '';
						return { key, value };
					});

				setFieldValue('variables', [...environmentVariables, ...newVariables]);
				setFieldTouched('variables', true);
			})();
		},
		[environmentVariables, setFieldValue],
	);

	const [, variablesMeta] = useField('variables');
	const [, environmentIdMeta] = useField('environmentId');
	const hasEnvironmentError = Boolean(environmentIdMeta.error);
	const hasVariablesError = !hasEnvironmentError && Boolean(variablesMeta.error);

	const experimentVariablesEnabled = useFeatureFlag('experimentVariablesEnabled');

	const [isDeletingVariable, setIsDeletingVariable] = useState(false);

	return (
		<Stack flexShrink={0} direction={'horizontal'}>
			<Stack direction="horizontal" size="none">
				<EnvironmentSelector
					selectedEnvironmentId={environmentId}
					hasError={hasEnvironmentError}
					disabled={disabled}
					teamId={teamId}
					selectEnvironmentId={(_environmentId) => formik.setFieldValue('environmentId', _environmentId)}
				/>

				{!environmentId && (
					<TutorialTooltip
						light
						hideIcon
						hideSkip
						showAlways
						placement="right"
						{...TUTORIALS.experiments.noEnvironmentSelected}
					/>
				)}
			</Stack>

			{experimentVariablesEnabled ? (
				<DropdownPill
					id="environmentVariablesDropDownButton"
					variant={hasVariablesError ? 'error' : variables.length > 0 ? 'highlighted' : 'default'}
					disableCloseOnClickAway={isDeletingVariable}
					disabled={disabled}
					Icon={IconFunction}
					placement="bottom-start"
					value={
						variables.length + formik.values.experimentVariables.length === 0
							? 'No variables'
							: `Variables (${(environmentVariables?.length || 0) + formik.values.experimentVariables.length})`
					}
				>
					{() => (
						<DropdownContentFrame sx={{ borderRadius: '4px' }}>
							<ExperimentAndEnvironmentVariables
								environmentVariables={environmentVariables || []}
								setIsDeletingVariable={setIsDeletingVariable}
							/>
						</DropdownContentFrame>
					)}
				</DropdownPill>
			) : (
				<DropdownPill
					id="environmentVariablesDropDownButton"
					variant={hasVariablesError ? 'error' : variables.length > 0 ? 'highlighted' : 'default'}
					disabled={disabled || hasEnvironmentError}
					Icon={IconFunction}
					value={variables.length === 0 ? 'No Environment variables' : `${variables.length} Environment variables`}
				>
					{() => (
						<DropdownContentFrame sx={{ borderRadius: '4px' }}>
							<ExperimentEnvironmentVariables />
						</DropdownContentFrame>
					)}
				</DropdownPill>
			)}
		</Stack>
	);
};
