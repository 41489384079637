/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2 } from 'components';
import { EditorSettingsContext } from 'pages/experimentsV2/useEditorSettings';
import { TemplateVO, TrackingCreationMethodVO } from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { useTeam } from 'services/useTeam';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { useField } from 'formik';
import { ampli } from 'ampli';

import NavigationWithContent from './pages/NavigationWithContent';
import { createExperimentRequestFromTemplate } from './utils';
import ExperimentNameField from './ExperimentNameField';
import UseTemplateForm from './UseTemplateForm';

interface UseTemplateModalProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	templatePreviewId: string;
	template?: TemplateVO;
	onClose: () => void;
}

export default function UseTemplateModal({
	experimentCreationMethod,
	templatePreviewId,
	newExperimentTags,
	template,
	onClose,
}: UseTemplateModalProps): ReactElement {
	const history = useHistory();
	const team = useTeam();

	return (
		<ModalOverlay open onClose={onClose}>
			<UseTemplateForm
				newExperimentTags={newExperimentTags}
				templateId={templatePreviewId}
				template={template}
				onClose={onClose}
				onSubmit={(values) => {
					ampli.experimentTemplateUsedWizardStepCompleted({
						experiment_template_name: values.templateTitle,
						experiment_template_step: values.placeholders.length + 1,
						experiment_template_last_step: true,
						experiment_template_step_placeholder: values.placeholders[values.placeholders.length - 1]?.name,
						experiment_template_total_steps: values.placeholders.length + 1,
					});
					history.push('/experiments/edit/<new>/design', {
						preparedFormData: {
							actions: ['save', 'run'],
							initialValues: createExperimentRequestFromTemplate({
								formData: values,
								teamId: team.id,
								experimentCreationMethod,
								tags: newExperimentTags,
								capNameAndHypothesis: true,
							}),
							initialErrors: {},
						},
					});
				}}
			>
				<ModalContentWithFormContext onClose={onClose} />
			</UseTemplateForm>
		</ModalOverlay>
	);
}

function ModalContentWithFormContext({ onClose }: { onClose: () => void }): ReactElement {
	const [{ value: environmentId }] = useField<string>('environmentId');
	const environments = useEnvironments();
	const selectedEnvironment =
		environments.environmentsOfCurrentTeam.find((env) => env.id === environmentId) || environments.globalEnvironment;

	return (
		<EditorSettingsContext.Provider value={{ mode: 'templateUsage' }}>
			<ModalV2 slick withFooter width="90vw" maxWidth="1650px">
				<ModalHeaderV2
					title="Create New Experiment From Template"
					subTitle={<ExperimentNameField />}
					onClose={onClose}
				/>
				<ModalContentV2>
					<NavigationWithContent
						selectedEnvironment={selectedEnvironment}
						environments={environments.environmentsOfCurrentTeam}
						submitLabel="Create Experiment 🚀"
					/>
				</ModalContentV2>
			</ModalV2>
		</EditorSettingsContext.Provider>
	);
}
