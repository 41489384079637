/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container } from 'components/Container';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

export function OrDivider(): ReactElement {
	return (
		<Container
			sx={{
				position: 'relative',
				width: '100%',
				borderBottom: '1px solid ' + theme.colors.neutral300,
				borderLeft: '1px solid ' + theme.colors.neutral300,
				height: '16px',
				marginBottom: '16px',
			}}
		>
			<span
				style={{
					position: 'absolute',
					top: '4px',
					left: 'calc(50% - 13px)',
					backgroundColor: theme.colors.neutral000,
					padding: '0 5px',
					fontSize: '11px',
					color: theme.colors.neutral600,
				}}
			>
				OR
			</span>
		</Container>
	);
}

export function AndDivider(): ReactElement {
	return (
		<Container
			sx={{
				position: 'relative',
				width: '100%',
				borderBottom: '1px solid ' + theme.colors.neutral300,
				height: '16px',
				marginBottom: '11px',
			}}
		>
			<span
				style={{
					position: 'absolute',
					top: '4px',
					left: '0',
					backgroundColor: theme.colors.neutral000,
					padding: '0 5px',
					fontSize: '11px',
					color: theme.colors.neutral600,
				}}
			>
				AND
			</span>
		</Container>
	);
}
