/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { FormikErrors, useFormikContext } from 'formik';
import { Services } from 'services/services';
import { useEffect, useState } from 'react';
import { debounce, set } from 'lodash';

import { TemplateFormValues } from './types';

/**
 * Why a custom validation handler and not formiks validation handler?
 * Because formik has 2 issue with validation:
 * - it cannot debound the validation
 * - validation is always called before submitting the form. Submit is NOT called if the validation fails.
 * which is horrible because one must be able to save an invalid template form.
 */
export default function ValidationHandler(): null {
	const { values, setErrors } = useFormikContext<TemplateFormValues>();

	const [debouncedValidate] = useState(() =>
		debounce(
			async (v) => {
				const errors = await validate(v);
				setErrors(errors);
			},
			500,
			{ leading: true },
		),
	);

	useEffect(() => {
		debouncedValidate(values);
	}, [values, debouncedValidate]);

	return null;
}

export async function validate(values: TemplateFormValues): Promise<FormikErrors<TemplateFormValues>> {
	const errors = {};
	try {
		const violations = await Services.templatesApi.validateTemplate({ ...values, id: 'not needed for validation' });
		violations.forEach(({ field, message }) => {
			if (message === 'There are no targets matching your query.') {
				set(errors, field, { message, level: 'info' });
			} else {
				set(errors, field, { message, level: 'error' });
			}
		});
	} catch {
		console.error('Could not validate template');
	}
	return errors;
}
