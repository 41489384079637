/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage, Spacings } from '@steadybit/ui-components-lib';
import { useField } from 'formik';
import React from 'react';

import { hasError } from './utils';

type FormikError = {
	message: string;
	level: 'info' | 'error' | 'warning';
};

export type FormikErrorProps = {
	name: string;
};

export const FormikError: React.VFC<FormikErrorProps> = ({ name }) => {
	const [, meta] = useField(name);

	return hasError(meta) ? (
		<ErrorMessage
			type="small"
			style={{
				marginTop: Spacings.xxSmall,
			}}
		>
			{isFormikErrorObject(meta.error) ? meta.error.message : meta.error || 'Error'}
		</ErrorMessage>
	) : null;
};

FormikError.displayName = 'FormikError';

function isFormikErrorObject(error: FormikError | string | undefined): error is FormikError {
	return typeof error === 'object' && error !== null && 'message' in error;
}
