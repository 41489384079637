/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	BaseExperimentStepExecutionVO,
	ExperimentExecutionVO,
	ExperimentStepExecutionActionVO,
	LineChartWidgetVO,
	PredefinedWidgetVO,
	WidgetVO,
} from 'ui-api';
import PredefinedWidget from 'pages/experiments/components/widgets/PredefinedWidget/PredefinedWidget';
import LogCard from 'pages/experiments/components/widgets/LogCard/LogCard';
import { usePromise } from 'utils/hooks/usePromise';
import { ReactElement, useMemo } from 'react';
import { Services } from 'services/services';
import { getHash } from 'utils/hash';
import { uniqBy } from 'lodash';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import StateOverTimeCard from './StateOverTimeCard/StateOverTimeCard';
import LineChartWidget from './LineChartWidget/LineChartWidget';
import MarkdownWidget from './MarkdownWidget/MarkdownWidget';
import UnknownWidget from './UnknownWidget';
import { Widget } from './types';

const supportedWidgetComponents: Record<
	| 'com.steadybit.widget.line_chart'
	| 'com.steadybit.widget.log'
	| 'com.steadybit.widget.markdown'
	| 'com.steadybit.widget.predefined'
	| 'com.steadybit.widget.state_over_time'
	| 'unknown',
	Widget
> = {
	['com.steadybit.widget.state_over_time']: StateOverTimeCard,
	['com.steadybit.widget.predefined']: PredefinedWidget,
	['com.steadybit.widget.markdown']: MarkdownWidget,
	['com.steadybit.widget.log']: LogCard,
	['com.steadybit.widget.line_chart']: LineChartWidget,
	['unknown']: UnknownWidget,
};

const httpResponsesViaLineChartWidget: LineChartWidgetVO = {
	type: 'com.steadybit.widget.line_chart',
	title: 'HTTP Responses',
	identity: {
		metricName: 'response_time',
		from: 'url',
		mode: 'com.steadybit.widget.line_chart.identity_mode.widget-per-value',
	},
	grouping: {
		showSummary: true,
		groups: [
			{
				title: 'Successful',
				color: 'success',
				matcher: {
					type: 'com.steadybit.widget.line_chart.group_matcher_fallback',
				},
			},
			{
				title: 'Failure',
				color: 'danger',
				matcher: {
					type: 'com.steadybit.widget.line_chart.group_matcher_not_empty',
					key: 'error',
				},
			},
			{
				title: 'Unexpected Status',
				color: 'warn',
				matcher: {
					type: 'com.steadybit.widget.line_chart.group_matcher_key_equals_value',
					key: 'expected_http_status',
					value: 'false',
				},
			},
			{
				title: 'Body Constraint Violated',
				color: 'warn',
				matcher: {
					type: 'com.steadybit.widget.line_chart.group_matcher_key_equals_value',
					key: 'response_constraints_fulfilled',
					value: 'false',
				},
			},
			{
				title: 'Response Time Constraint Violated',
				color: 'warn',
				matcher: {
					type: 'com.steadybit.widget.line_chart.group_matcher_key_equals_value',
					key: 'response_time_constraints_fulfilled',
					value: 'false',
				},
			},
		],
	},
	tooltip: {
		metricValueUnit: 'ms',
		metricValueTitle: 'Response-Time',
		additionalContent: [
			{
				from: 'error',
				title: 'Error',
			},
			{
				from: 'http_status',
				title: 'HTTP Status',
			},
		],
	},
};

type WidgetsProps = Pick<
	Omit<ExperimentRunLogsAndMetricsProps, 'selectedStepId' | 'setSelectedStepId'>,
	'duration' | 'start' | 'position' | 'experimentExecution' | 'onPositionSelect'
>;

export default function Widgets({
	experimentExecution,
	duration,
	start,
	position,
	onPositionSelect,
}: WidgetsProps): ReactElement | null {
	const actions = usePromise(() => Services.actions.fetchActions(), []);

	const widgets = useMemo(() => {
		if (actions.value) {
			return findAllRelevantWidgets(experimentExecution, actions.value);
		}
		return [];
	}, [experimentExecution, actions.value]);

	if (!actions.value) {
		return null;
	}

	return (
		<>
			{widgets
				.sort((a, b) => a.type.localeCompare(b.type))
				.map((widget) => {
					let Component = null;
					//Can be removed in Q2/25 when all customers are using the updated extension-http
					if (
						widget.type === 'com.steadybit.widget.predefined' &&
						(widget as PredefinedWidgetVO).predefinedWidgetId === 'com.steadybit.widget.predefined.HttpCheck'
					) {
						Component = LineChartWidget;
						widget = httpResponsesViaLineChartWidget;
					} else {
						Component = supportedWidgetComponents[widget.type];
					}

					if (Component == null) {
						return null;
					}

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const title = (widget as any)['title'] || '';

					return (
						<Component
							experimentExecution={experimentExecution}
							position={position}
							duration={duration}
							key={widget.type + title}
							widget={widget}
							start={start}
							onPositionSelect={onPositionSelect}
						/>
					);
				})
				.filter(Boolean)}
		</>
	);
}

function findAllRelevantWidgets(experimentExecution: ExperimentExecutionVO, actions: ActionVO[]): WidgetVO[] {
	const result: WidgetVO[] = [];

	for (const lane of experimentExecution.lanes) {
		for (const step of lane.steps) {
			if (isExperimentStepExecutionActionVO(step)) {
				const action = actions.find((a) => a.id === step.actionId);
				if (action) {
					for (const widget of action.widgets) {
						result.push(widget);
					}
				}
			}
		}
	}

	return uniqBy(result, getHash);
}

function isExperimentStepExecutionActionVO(
	step: BaseExperimentStepExecutionVO,
): step is ExperimentStepExecutionActionVO {
	return step.type === 'action';
}
