/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { Text } from 'components';

import { ActionCategoryItem, ActionSubCategory } from './types';
import Actions from './Actions';

interface ActionSubGroupProps {
	subGroup: ActionSubCategory;
	selectedActionId?: string;
	collapsed?: boolean;
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function ActionSubGroup({
	selectedActionId,
	collapsed,
	subGroup,
	onActionClick,
}: ActionSubGroupProps): ReactElement {
	return (
		<Flex spacing="xxSmall">
			{!collapsed && (
				<Text variant="small" color="neutral700">
					{subGroup.label}
				</Text>
			)}
			<Actions
				selectedActionId={selectedActionId}
				actions={subGroup.actions}
				collapsed={collapsed}
				onActionClick={onActionClick}
			/>
		</Flex>
	);
}
