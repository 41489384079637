/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentSubHeaderHypthesis } from 'pages/experiments/components/header/experimentSubHeaderHypthesis';
import { IconExperiment, IconFunction, IconTemplate, IconWarningCircle } from 'components/icons';
import { selectedStepIdParam } from 'pages/experimentsV2/urlParams';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { Stack, Text, Tooltip } from 'components';
import { useUrlState } from 'url/useUrlState';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { UrlState, selectedViewParam } from './urlParams';
import { TemplateFormValues } from './types';

export default function TemplateHeader(): ReactElement {
	const [{ selectedView }, , updateUrlState] = useUrlState<UrlState>([selectedViewParam, selectedStepIdParam]);
	const { values, errors, setFieldValue, setFieldTouched } = useFormikContext<TemplateFormValues>();

	return (
		<Stack
			size="none"
			sx={{
				backgroundColor: theme.colors.neutral000,
				boxShadow: selectedView === 'editor' ? 'applicationSmall' : undefined,
				zIndex: 3,
			}}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div
					style={{
						borderBottom: '1px solid',
						borderColor: selectedView === 'editor' ? theme.colors.neutral000 : theme.colors.neutral300,
					}}
				>
					<Tab isSelected={selectedView === 'editor'} onClick={() => updateUrlState({ selectedView: 'editor' })}>
						<IconExperiment variant="large" color={errors.lanes ? 'coral' : 'neutral800'} />
						<Text variant="mediumStrong" color={errors.lanes ? 'coral' : 'neutral800'}>
							Experiment
						</Text>
						{errors.lanes ? (
							<Tooltip content="There are errors in the experiment design">
								<span>
									<IconWarningCircle
										color="coral"
										sx={{
											minWidth: '24px',
											minHeight: '24px',
										}}
									/>
								</span>
							</Tooltip>
						) : (
							<></>
						)}
					</Tab>
				</div>
				<div
					style={{
						borderLeft: '1px solid ' + theme.colors.neutral300,
						borderRight: '1px solid ' + theme.colors.neutral300,
						borderBottom:
							selectedView === 'placeholders'
								? '1px solid ' + theme.colors.neutral000
								: '1px solid ' + theme.colors.neutral300,
					}}
				>
					<Tab
						isSelected={selectedView === 'placeholders'}
						onClick={() => updateUrlState({ selectedStepId: null, selectedView: 'placeholders' })}
					>
						<IconFunction variant="large" color={errors.placeholders ? 'coral' : 'neutral800'} />
						<Text variant="mediumStrong" color={errors.placeholders ? 'coral' : 'neutral800'}>
							Template Placeholders
						</Text>
						{errors.placeholders ? (
							<Tooltip content="This placeholder has no description">
								<div>
									<IconWarningCircle
										color="coral"
										sx={{
											minWidth: '24px',
											minHeight: '24px',
										}}
									/>
								</div>
							</Tooltip>
						) : (
							<></>
						)}
					</Tab>
				</div>
				<div
					style={{
						borderBottom: '1px solid',
						borderColor: selectedView === 'description' ? theme.colors.neutral000 : theme.colors.neutral300,
						flexGrow: 1,
					}}
				>
					<Tab
						isSelected={selectedView === 'description'}
						onClick={() => updateUrlState({ selectedStepId: null, selectedView: 'description' })}
					>
						<IconTemplate
							variant="large"
							color={errors.templateDescription || errors.templateTitle ? 'coral' : 'neutral800'}
						/>
						<Text
							variant="mediumStrong"
							color={errors.templateDescription || errors.templateTitle ? 'coral' : 'neutral800'}
						>
							Template Description
						</Text>
						{errors.templateDescription || errors.templateTitle ? (
							<Tooltip content="This placeholder has no description">
								<div>
									<IconWarningCircle
										color="coral"
										sx={{
											minWidth: '24px',
											minHeight: '24px',
										}}
									/>
								</div>
							</Tooltip>
						) : (
							<></>
						)}
					</Tab>
				</div>
			</div>
			{selectedView === 'editor' && (
				<Stack direction="horizontal" size="xxSmall" style={{ alignItems: 'center', padding: '12px 24px' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '28px',
							height: '28px',
							backgroundColor: theme.colors.slate,
							borderRadius: '4px',
						}}
					>
						<IconExperiment variant="small" color="neutral000" />
					</div>
					<EditableLabel
						value={values.experimentName}
						placeholder="Untitled experiment"
						onChange={(value) => {
							setFieldValue('experimentName', value);
							setFieldTouched('experimentName', true);
						}}
						minWidth={100}
						width="fit-content"
						maxWidth={400}
						theme="slate"
					/>
					<div style={{ width: '1px', height: '24px', backgroundColor: theme.colors.purple300, margin: '0px 12px' }} />
					<div style={{ width: 'fit-content' }}>
						<ExperimentSubHeaderHypthesis disabled={false} />
					</div>
				</Stack>
			)}
		</Stack>
	);
}

interface TabProps {
	children: ReactElement[];
	isSelected: boolean;
	onClick: () => void;
}

function Tab({ isSelected, children, onClick }: TabProps): ReactElement {
	return (
		<div
			onClick={onClick}
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '4px',
				height: '20px',

				backgroundColor: isSelected ? theme.colors.neutral000 : theme.colors.neutral100,
				color: isSelected ? theme.colors.neutral800 : theme.colors.slate,

				padding: '16px 24px',
				cursor: 'pointer',
			}}
		>
			{children}
		</div>
	);
}
