/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import { Flex, Grid, Text } from '@steadybit/ui-components-lib';
import { TextField } from 'components/TextField';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface EnvVariableInputsProps {
	variableValue: string;
	variableKey: string;
	setVariableValue: (value: string) => void;
	setVariableKey: (key: string) => void;
}

export default function EnvVariableInputs({
	variableValue,
	variableKey,
	setVariableValue,
	setVariableKey,
}: EnvVariableInputsProps): ReactElement {
	return (
		<Grid
			cols="2fr 3fr"
			align="end"
			spacing="medium"
			style={{
				padding: '20px',
				width: '-webkit-fill-available',
				backgroundColor: theme.colors.neutral100,
				borderRadius: 'xSmall',
			}}
		>
			<Flex spacing="xxSmall">
				<Text type="mediumStrong" style={{ color: theme.colors.neutral800 }}>
					Key
				</Text>

				<Flex direction="horizontal" style={{ width: '100%' }}>
					<PlaceholderMarker marker="{{" left />
					<TextField
						id="env-variable-key"
						placeholder="Key"
						value={variableKey}
						onChange={(e) => setVariableKey(e.target.value)}
						sx={{
							borderRadius: 0,
							borderLeft: 'none',
							borderRight: 'none',
						}}
					/>
					<PlaceholderMarker marker="}}" />
				</Flex>
			</Flex>
			<Flex spacing="xxSmall">
				<Text type="mediumStrong" style={{ color: theme.colors.neutral800 }}>
					Value
				</Text>
				<TextField
					id="env-variable-value"
					placeholder="Value"
					value={variableValue}
					onChange={(e) => setVariableValue(e.target.value)}
				/>
			</Flex>
		</Grid>
	);
}
