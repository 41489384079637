/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import { IconComponent, IconSearch } from 'components/icons';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { ReactElement, ReactNode, useState } from 'react';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';

interface DropdownSearchInputProps {
	children: ReactNode | ReactNode[];
	disableInput?: boolean;
	placeholder?: string;
	icon?: IconComponent;
	cypressTag?: string;
	small?: boolean;
	renderDropdownContent: (props: {
		value: string;
		width: string | number | undefined;
		setValue: (value: string) => void;
		closeMenu: () => void;
	}) => ReactElement;
}

export default function DropdownSearchInput({
	renderDropdownContent,
	disableInput,
	placeholder,
	cypressTag,
	children,
	small,
	icon,
}: DropdownSearchInputProps): ReactElement {
	const [query, setQuery] = useState<string>('');
	const Icon = icon || IconSearch;

	return (
		<Dropdown<string>
			placement="bottom-start"
			value={query}
			renderComponent={({ setShowMenu, showMenu, value, ref }) => {
				return (
					<Container
						ref={ref}
						sx={{
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							gap: '6px',

							width: '100%',
							pr: '8px',
							pl: '38px',
							py: small ? '3px' : '8px',
							height: 'fit-content',

							backgroundColor: 'neutral000',
							color: 'neutral800',
							borderRadius: 4,
							border: '1px solid',

							outline: '1px solid ' + (showMenu ? theme.colors.slate : 'transparent'),
							borderColor: showMenu ? 'slate' : 'neutral300',
						}}
					>
						<Icon
							variant="medium"
							sx={{
								position: 'absolute',
								top: small ? '10px' : '14px',
								left: '12px',

								color: 'neutral600',
								minWidth: '20px',
								minHeight: '20px',
							}}
						/>
						{children}
						{!disableInput && (
							<Container
								data-cy={cypressTag}
								as="input"
								value={value}
								placeholder={placeholder || 'Type to search'}
								onChange={(e) => setQuery(e.target.value)}
								onFocus={() => setShowMenu(true)}
								sx={{
									flexGrow: 1,
									height: '32px',
									...textEllipsis,
									outline: 'none',
									border: 'none',
									minWidth: '200px',
								}}
							/>
						)}
					</Container>
				);
			}}
		>
			{({ width, closeMenu }) => (
				<DropdownContentFrame maxWidth={width} maxHeight={700}>
					{renderDropdownContent({ value: query, width, setValue: setQuery, closeMenu })}
				</DropdownContentFrame>
			)}
		</Dropdown>
	);
}
