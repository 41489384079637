/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { AxiosResponseTransformer } from 'axios';

export const withReviver = (reviver: (key: string, value: unknown) => unknown): AxiosResponseTransformer => {
	return (data, headers) => {
		if (!headers['content-type']?.match(/application\/(.+\+)?json/)) {
			return data;
		}

		return data ? JSON.parse(data, reviver) : data;
	};
};

export const DateReviver =
	(dateFields: string[]) =>
	(key: string, value: unknown): unknown | Date => {
		if (dateFields.includes(key) && value && (typeof value === 'number' || typeof value === 'string')) {
			return new Date(value);
		}
		return value;
	};
