/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Container,
	RouterLink,
	Skeletons,
	Stack,
	Text,
	TutorialTooltip,
	userConfirm,
} from 'components';
import { IconArrowLeft, IconEnvironment, IconSaveDisc, IconTrash } from 'components/icons';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { useField, useFormikContext } from 'formik';
import { ReactElement, ReactNode } from 'react';
import { theme } from 'styles.v2/theme';

interface EditEnvironmentHeaderProps {
	isGlobalEnvironment?: boolean;
	isNewEnvironment?: boolean;
	onDelete: () => void;
}

export default function EditEnvironmentHeader({
	isGlobalEnvironment = false,
	isNewEnvironment = false,
	onDelete,
}: EditEnvironmentHeaderProps): ReactElement {
	const [field, , { setValue, setTouched }] = useField<string>('name');
	const { submitForm, isSubmitting } = useFormikContext();

	return (
		<Wrapper>
			<Stack direction="horizontal" size="xSmall" alignItems="center">
				<Logo />
				<Stack size="none">
					<BackLink />

					<Stack direction="horizontal" alignItems="center">
						{isGlobalEnvironment && (
							<Text variant="small" color="neutral000" minWidth={12}>
								&nbsp;
							</Text>
						)}
						<EditableLabel
							disabled={isGlobalEnvironment}
							theme="light"
							value={field.value}
							placeholder="Environment name"
							onChange={(newLabel) => {
								setValue(newLabel);
								setTouched(true);
							}}
						/>

						{isNewEnvironment && (
							<TutorialTooltip placement="bottom" showAlways light hideIcon hideSkip id="please_name_your_environment">
								Name your Environment in order to create one
							</TutorialTooltip>
						)}
					</Stack>
				</Stack>
			</Stack>

			<Stack direction="horizontal" size="small" alignItems="center">
				{!isNewEnvironment && !isGlobalEnvironment && (
					<ButtonIcon
						tooltip={isNewEnvironment ? 'Discard new Environment' : 'Delete Environment'}
						onClick={async () => {
							const choice = await userConfirm({
								title: 'Delete Environment',
								message:
									'Are you sure you want to delete this Environment? It won’t be possible retrieve it again after the deletion.',
								actions: [
									{ value: 'cancel', label: 'Cancel' },
									{ value: 'confirm', label: 'Delete Environment', variant: 'primary' },
								],
							});
							if (choice !== 'confirm') {
								return;
							}
							onDelete();
						}}
						sx={{
							color: 'neutral400',
							':hover': {
								color: 'neutral000',
								bg: 'transparent',
								textDecoration: 'none',
								border: '1px solid ' + theme.colors.neutral300,
							},
						}}
					>
						<IconTrash />
					</ButtonIcon>
				)}
				<Button variant="primaryRun" onClick={submitForm} width="210px" disabled={isSubmitting}>
					<IconSaveDisc variant="small" mr="xSmall" />
					{isSubmitting ? 'Saving...' : isNewEnvironment ? 'Create Environment' : 'Save Environment'}
				</Button>
			</Stack>
		</Wrapper>
	);
}

export function LoadingEditEnvironmentHeader(): ReactElement {
	return (
		<Wrapper>
			<Stack direction="horizontal" size="xSmall" alignItems="center">
				<Logo />
				<Stack size="xSmall">
					<BackLink />
					<Skeletons height={24} widths={[175]} ml="xSmall" />
				</Stack>
			</Stack>
		</Wrapper>
	);
}

function Wrapper({ children }: { children: ReactNode }): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				minHeight: 80,
				maxHeight: 80,
				bg: 'neutral700',
				px: 22,
			}}
		>
			{children}
		</Container>
	);
}

function Logo(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: 8,
				bg: 'neutral600',
				height: 48,
				width: 48,
			}}
		>
			<IconEnvironment variant="large" color="neutral000" />
		</Container>
	);
}

function BackLink(): ReactElement {
	return (
		<RouterLink to="/settings/environments" ml="xSmall">
			<Stack direction="horizontal" size="xxSmall" alignItems="center" color="purple300">
				<IconArrowLeft variant="small" />
				<Text variant="smallStrong">Back to Enviroment list</Text>
			</Stack>
		</RouterLink>
	);
}
