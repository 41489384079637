/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	BaseExperimentStepVOUnion,
	CreateExperimentRequest,
	ExperimentLaneVO,
	ExperimentStepActionVO,
	TemplatePlaceholderVO,
	TrackingCreationMethodVO,
} from 'ui-api';
import { listPlaceholders } from 'templates/utils';
import { listVariables } from 'utils/envVars';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

import {
	replaceLaneMarker,
	replaceStringMarker,
	wrapWithEnvironmentVariableMarkers,
	wrapWithPlaceholderMarkers,
} from '../utils';
import { EnvironmentVariable, TemplatePlaceholder } from './types';
import { UseTemplateFormData } from './UseTemplateForm';

export function extractVariables(
	lanes: ExperimentLaneVO[],
	placeholders: TemplatePlaceholderVO[],
): [Map<string, EnvironmentVariable>, Map<string, TemplatePlaceholder>] {
	const environmentVariables: Map<string, EnvironmentVariable> = new Map();
	const templatePlaceholders: Map<string, TemplatePlaceholder> = new Map();

	for (let iL = 0; iL < lanes.length; iL++) {
		const lane = lanes[iL];
		for (let iS = 0; iS < lane.steps.length; iS++) {
			const step = lane.steps[iS];
			extractEnvironmentVariables(step, environmentVariables);
			extractPlaceholders(step, placeholders, templatePlaceholders);
		}
	}

	return [environmentVariables, templatePlaceholders];
}

export function extractPlaceholders(
	step: BaseExperimentStepVOUnion,
	templatePlaceholders: TemplatePlaceholderVO[],
	templatePlaceholderValues: Map<string, TemplatePlaceholder>,
): void {
	const fromCustomLabel = step.customLabel ? listPlaceholders(step.customLabel) : [];
	const fromBlastRadius = 'blastRadius' in step ? listPlaceholders(step.blastRadius?.predicate) : [];
	const fromParameters = Object.values(step.parameters).flatMap((p) => listPlaceholders(p));
	const uniquePlaceholders = [...new Set([...fromBlastRadius, ...fromParameters, ...fromCustomLabel])];
	for (let i = 0; i < uniquePlaceholders.length; i++) {
		const placeholder = uniquePlaceholders[i];
		const matchingTemplatePlaceholder = templatePlaceholders.find((p) => p.key === placeholder);

		let description = '';
		if (matchingTemplatePlaceholder) {
			description = matchingTemplatePlaceholder.description;
		}

		if (templatePlaceholderValues.has(placeholder)) {
			templatePlaceholderValues.get(placeholder)?.steps.push(step);
		} else {
			templatePlaceholderValues.set(placeholder, {
				value: '',
				description,
				steps: [step],
			});
		}
	}
}

export function extractEnvironmentVariables(
	step: BaseExperimentStepVOUnion,
	environmentVariables: Map<string, EnvironmentVariable>,
): void {
	const fromBlastRadius = 'blastRadius' in step ? listVariables(step.blastRadius?.predicate) : [];
	const fromParameters = Object.values(step.parameters).flatMap((p) => listVariables(p));
	const uniqueVariables = [...new Set([...fromBlastRadius, ...fromParameters])];

	for (let i = 0; i < uniqueVariables.length; i++) {
		const envVar = uniqueVariables[i];
		if (environmentVariables.has(envVar)) {
			environmentVariables.get(envVar)?.steps.push(step);
		} else {
			environmentVariables.set(envVar, { steps: [step], value: '' });
		}
	}
}

interface ReplaceMarkersProps {
	formData: UseTemplateFormData;
	replaceEmptyValues: boolean;
	replacePlaceholders: boolean;
	replaceVariables: boolean;
}

export function replaceMarkers({
	formData,
	replaceEmptyValues,
	replacePlaceholders,
	replaceVariables,
}: ReplaceMarkersProps): UseTemplateFormData {
	const { __originalExperimentName, __originalHypothesis, placeholderValuesMap, variableValuesMap } = formData;
	let lanes = cloneDeep(formData.__originalLanes);
	let experimentName = __originalExperimentName;
	let hypothesis = __originalHypothesis;

	if (replacePlaceholders) {
		for (const [key, value] of placeholderValuesMap.entries()) {
			if (!replaceEmptyValues && !value) {
				continue;
			}
			let valueToUse = value;
			if (!value) {
				valueToUse = '';
			}

			lanes = replaceLaneMarker(lanes, wrapWithPlaceholderMarkers(key), valueToUse);
			experimentName = replaceStringMarker(experimentName, wrapWithPlaceholderMarkers(key), valueToUse);
			hypothesis = replaceStringMarker(hypothesis, wrapWithPlaceholderMarkers(key), valueToUse);
		}
	}
	if (replaceVariables) {
		for (const [key, value] of variableValuesMap.entries()) {
			if (replaceEmptyValues && !value) {
				continue;
			}
			lanes = replaceLaneMarker(lanes, wrapWithEnvironmentVariableMarkers(key), value);
			experimentName = replaceStringMarker(experimentName, wrapWithPlaceholderMarkers(key), value);
			hypothesis = replaceStringMarker(hypothesis, wrapWithPlaceholderMarkers(key), value);
		}
	}

	return {
		...formData,
		lanes,
		experimentName,
		hypothesis,
	};
}

export function createExperimentRequestFromTemplate({
	capNameAndHypothesis = false,
	experimentCreationMethod,
	formData,
	teamId,
	tags,
}: {
	experimentCreationMethod?: TrackingCreationMethodVO;
	capNameAndHypothesis?: boolean;
	formData: UseTemplateFormData;
	tags?: string[];
	teamId: string;
}): CreateExperimentRequest {
	formData = replaceMarkers({ formData, replaceEmptyValues: true, replacePlaceholders: true, replaceVariables: false });

	reassignNewStepIds(formData.lanes);

	const experimentName = formData.experimentName || '';
	const hypothesis = formData.hypothesis || '';

	const variables = Array.from(formData.variableValuesMap.entries())
		.map(([key, value]) => ({
			key,
			value,
		}))
		.concat(formData.variables.filter((v) => !formData.variableValuesMap.has(v.key)));

	return {
		name: capNameAndHypothesis ? experimentName.substring(0, 255) : experimentName,
		hypothesis: capNameAndHypothesis ? hypothesis.substring(0, 20_000) : hypothesis,
		teamId,
		environmentId: formData.environmentId,
		templateId: formData.id,
		templateTitle: formData.templateTitle,
		externalId: '',
		lanes: formData.lanes,
		webhookIds: [],
		tags: tags ? tags : [],
		variables,
		experimentVariables: [],
		creationMethod: experimentCreationMethod || 'UI_TEMPLATE',
	};
}

export function reassignNewStepIds(lanes: ExperimentLaneVO[]): void {
	for (let i = 0; i < lanes.length; i++) {
		const lane = lanes[i];
		for (let j = 0; j < lane.steps.length; j++) {
			const step = lane.steps[j];
			step.id = uuidv4();
		}
	}
}

// exported for testing
export function getUniqueTargetTypeIfPossible(
	selectedStepIds: Set<string>,
	lanes: ExperimentLaneVO[],
): string | undefined {
	const involvedTargetTypes: Set<string> = new Set(
		Array.from(selectedStepIds)
			.map((stepId) => {
				const steps = lanes.flatMap((lane) => lane.steps);
				const step = steps.find((step) => step.id === stepId);
				if (step && step.type === 'action') {
					const action = step as ExperimentStepActionVO;
					return action.blastRadius?.targetType;
				}
			})
			.filter((type) => type !== undefined) as string[],
	);

	return involvedTargetTypes.size === 1 ? involvedTargetTypes.values().next().value : undefined;
}
