/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import React, { ReactElement, useEffect, useState } from 'react';

import ButtonRound, { ButtonRoundProps, variants } from './ButtonRound';
import ProgressIndicator from './ProgressIndicator';

export interface ButtonRoundLongClickProps extends ButtonRoundProps {
	onClick: () => void;
	onLongClickReleased?: (fired: boolean, progress: number) => void;
}

const clickDelay = 1000;

const clickingVariants: Record<variants, variants> = {
	primary: 'primaryClicking',
	secondary: 'primaryClicking',
	danger: 'danger',
	primaryClicking: 'primaryClicking',
};

const ButtonRoundLongClick = React.forwardRef<HTMLButtonElement, ButtonRoundLongClickProps>(
	({ onClick, onLongClickReleased, children, variant = 'primary', ...props }, ref) => {
		const [clickingStartTime, setClickingStartTime] = useState<Date | undefined>(undefined);
		const start: () => void = () => setClickingStartTime(new Date());
		const abort: () => void = () => {
			if (clickingStartTime != undefined) {
				onLongClickReleased?.(false, ((new Date().getTime() - clickingStartTime.getTime()) / clickDelay) * 100);
			}
			setClickingStartTime(undefined);
		};

		return (
			<ButtonRound
				{...props}
				variant={clickingStartTime != undefined ? clickingVariants[variant] : variant}
				ref={ref}
				onMouseDown={start}
				onTouchStart={start}
				onMouseUp={abort}
				onMouseLeave={abort}
				onTouchEnd={abort}
				onClick={noop}
			>
				{clickingStartTime != undefined && (
					<ProgressHandler
						{...props}
						onTimeoutEnded={() => {
							onLongClickReleased?.(true, ((new Date().getTime() - clickingStartTime.getTime()) / clickDelay) * 100);
							setClickingStartTime(undefined);
							onClick?.();
						}}
					/>
				)}
				{children}
			</ButtonRound>
		);
	},
);
ButtonRoundLongClick.displayName = 'ButtonRoundLongClick';
export default ButtonRoundLongClick;

interface ProgressHandlerProps extends ButtonRoundProps {
	onTimeoutEnded: () => void;
}

function ProgressHandler({ onTimeoutEnded, ...props }: ProgressHandlerProps): ReactElement {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const timeout: number = window.setTimeout(() => setProgress(1), 1);
		return () => window.clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const timeout: number = window.setTimeout(onTimeoutEnded, clickDelay);
		return () => window.clearTimeout(timeout);
	}, [onTimeoutEnded]);

	return <ProgressIndicator {...props} progress={progress} inversed />;
}

function noop(): void {}
