/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { EnvironmentSummaryVO } from 'ui-api';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';

import EnvironmentDropdown from './EnvironmentDropdown';
import { UseTemplateFormData } from './UseTemplateForm';

interface EnvironmentSelectionProps {
	selectedEnvironment: EnvironmentSummaryVO | undefined;
	environments: EnvironmentSummaryVO[];
}

export default function EnvironmentSelection({
	selectedEnvironment,
	environments,
}: EnvironmentSelectionProps): ReactElement {
	const formik = useFormikContext<UseTemplateFormData>();

	return (
		<Stack size="xSmall" sx={{ borderRadius: '4px' }}>
			<Text variant="mediumStrong" color="neutral800">
				In which environment will you run the experiment?
			</Text>
			<Text variant="medium" color="neutral600">
				The environment determines which targets are available for the experiment. Any environment variables used in the
				experiment will resolve to the values defined in the chosen environment.
			</Text>
			<EnvironmentDropdown
				selectedEnvironment={selectedEnvironment}
				environments={environments}
				selectEnvironmentId={(id) => {
					formik.setFieldValue('environmentId', id);
					formik.setFieldValue('variableValuesMap', new Map());
				}}
			/>
		</Stack>
	);
}
