/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { Text } from 'components';

interface PlaceholderMarkerProps {
	small?: boolean;
	left?: boolean;
	marker: string;
}

export default function PlaceholderMarker({ left, marker, small }: PlaceholderMarkerProps): ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: small ? '38px' : '46px',
				height: small ? '30px' : '40px',

				background: 'linear-gradient(180deg, #FFFFFF 0%, #F4F7FB 100%)',

				border: '1px solid ' + theme.colors.neutral300,
				borderRadius: left ? '4px 0 0 4px' : '0 4px 4px 0',
			}}
		>
			<Text variant="smallStrong" color="neutral700">
				{marker}
			</Text>
		</div>
	);
}
