/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { CurrentUserVO, TenantVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { useUser } from 'services/usersApi';
import { useEffect } from 'react';

import { isSteadybitOrPartnerUser } from './ignoredEmailAddresses';
import invokePromise from '../utils/ignorePromise';

declare global {
	interface Window {
		INJECTED_LOGROCKET_ID?: string;
	}
}

async function initLogRocket(user: CurrentUserVO, tenant: TenantVO): Promise<void> {
	if (!window.INJECTED_LOGROCKET_ID) {
		return;
	}
	const LogRocket = (await import('logrocket')).default;
	LogRocket.init(window.INJECTED_LOGROCKET_ID, {
		console: {
			isEnabled: false,
		},
		network: {
			isEnabled: false,
		},
		browser: {
			// no browser URL tracking
			urlSanitizer: () => {
				return '';
			},
		},
	});
	LogRocket.identify(`${user.username}@${tenant.key}`, {
		tenant: tenant.key,
		username: user.username,
		amplitudeUserId: user.trackingIdentifier,
	});
}

export function genLogRocketUrl(user: CurrentUserVO): string | undefined {
	if (!window.INJECTED_LOGROCKET_ID) {
		return;
	}
	const devUrl = 'qykstv/dev-platform-sdxoi';
	const prodUrl = 'qykstv/prod-platform-uk8xc';
	const encodedFilter = encodeURIComponent(
		JSON.stringify([
			{
				type: 'userTrait',
				operator: { name: 'is', type: 'IS', hasStrings: true, autocompleteEnabled: true },
				strings: [`amplitudeUserId:${user.trackingIdentifier}`],
			},
		]),
	);
	if (window.location.hostname === 'platform.steadybit.com') {
		return `https://app.logrocket.com/${prodUrl}?filters=${encodedFilter}`;
	} else {
		return `https://app.logrocket.com/${devUrl}?filters=${encodedFilter}`;
	}
}

export default function useLogRocket(): void {
	const tenant = useTenant();
	const user = useUser();

	const isLogRocketDisabled = (): boolean => {
		return tenant.enabledFeatures.includes('disable_log_rocket');
	};

	useEffect(() => {
		if (
			tenant.key &&
			tenant.key !== 'demo' &&
			tenant.key !== 'demo-2' &&
			tenant.key !== 'steadybit' &&
			!isSteadybitOrPartnerUser(user) &&
			!isLogRocketDisabled()
		) {
			invokePromise(() => initLogRocket(user, tenant));
		}
	}, [user, tenant]);
}
