/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { FormikErrors, useFormikContext } from 'formik';
import { findStep } from 'pages/experimentsV2/utils';
import { Services } from 'services/services';
import { useEffect, useState } from 'react';
import { useTeam } from 'services/useTeam';
import { debounce, set } from 'lodash';

import { createExperimentRequestFromTemplate } from './utils';
import { UseTemplateFormData } from './UseTemplateForm';
import { getStepId } from '../components/Occurances';

/**
 * Why a custom validation handler and not formiks validation handler?
 * Because formik has 2 issue with validation:
 * - it cannot debound the validation
 * - validation is always called before submitting the form. Submit is NOT called if the validation fails.
 * which is horrible because one must be able to save an invalid template form.
 */
export default function ValidationHandler(): null {
	const team = useTeam();
	const { values, setErrors } = useFormikContext<UseTemplateFormData>();

	const [debouncedValidate] = useState(() =>
		debounce(
			async (v: UseTemplateFormData) => {
				const errors = await validate(v, team.id);
				const ignoredStepPaths = getIgnoredStepPaths(v);

				if (errors.lanes) {
					for (let iL = 0; iL < errors.lanes.length; iL++) {
						const lane = errors.lanes[iL];
						const steps = lane && typeof lane !== 'string' ? (lane.steps ?? []) : [];
						for (let iS = 0; iS < steps.length; iS++) {
							const stepPath = `lanes[${iL}].steps[${iS}]`;
							if (ignoredStepPaths.has(stepPath)) {
								set(errors, stepPath, undefined);
							}
						}
					}
				}
				setErrors(errors);
			},
			500,
			{ leading: true },
		),
	);

	useEffect(() => {
		debouncedValidate(values);
	}, [values, debouncedValidate]);

	return null;
}

async function validate(values: UseTemplateFormData, teamId: string): Promise<FormikErrors<UseTemplateFormData>> {
	const errors = {};
	try {
		const violations = await Services.experiments.validateExperiment(
			createExperimentRequestFromTemplate({ formData: values, teamId }),
		);
		violations.forEach(({ field, message }) => {
			if (message === 'There are no targets matching your query.') {
				set(errors, field, { message, level: 'info' });
			} else {
				set(errors, field, { message, level: 'error' });
			}
		});
	} catch {
		console.error('Could not validate template');
	}
	return errors;
}

// We want to ignore steps that have placeholders but no values yet
function getIgnoredStepPaths(values: UseTemplateFormData): Set<string> {
	const ignoredStepPaths = new Set<string>();
	values.placeholdersMap.forEach((occurances, placeholderKey) => {
		const stepIds = occurances.map(getStepId);
		stepIds.forEach((stepId) => {
			if (!stepId) {
				return;
			}

			// The step has a placeholder value, so we don't ignore it
			const placeholderValue = values.placeholderValuesMap.get(placeholderKey);
			if (placeholderValue) {
				return;
			}

			const [, path] = findStep(values.__originalLanes, stepId);
			if (!path) {
				return;
			}

			ignoredStepPaths.add(path);
		});
	});
	return ignoredStepPaths;
}
