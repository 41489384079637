/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	ContextualMenuLink,
	RouterLink,
	Snackbar,
	Stack,
	StateBadge,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Toggle,
	Tooltip,
	TutorialTooltip,
	UserIcon,
} from 'components';
import {
	IconCalendarClock,
	IconCalendarEdit,
	IconCalendarRecurrent,
	IconNavigationMenuHorizontal,
	IconTarget,
	IconTrash,
} from 'components/icons';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import { ReactElement, RefObject, useEffect, useState } from 'react';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { PageLocation, usePage } from 'utils/hooks/usePage';
import ListHeader from 'components/List/presets/ListHeader';
import DropDown from 'components/Select/Dropdown/Dropdown';
import ViewWrapper from 'pages/components/ViewWrapper';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { ExperimentScheduleVO } from 'ui-api';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { TUTORIALS } from 'tutorials';

import DeleteScheduleExperimentModal from './components/Schedule/DeleteScheduleExperimentModal';
import EditExperimentScheduleModal from './components/Schedule/EditExperimentScheduleModal';
import useRefreshingSchedules from './hooks/useRefreshingSchedules';

export function SchedulesOnceList(): ReactElement {
	const schedulesPage = usePage('/scheduledOnce', { sort: [['experimentKey', 'asc']] });
	return <SchedulesList page={schedulesPage} type="once" withCronColumn={false} />;
}

export function SchedulesRecurrentList(): ReactElement {
	const schedulesPage = usePage('/scheduledRecurrent', { sort: [['experimentKey', 'asc']] });
	return <SchedulesList page={schedulesPage} type="recurrent" withCronColumn />;
}

interface SchedulesListProps {
	withCronColumn: boolean;
	page: PageLocation;
	type: string;
}

function SchedulesList({ withCronColumn, page, type }: SchedulesListProps): ReactElement {
	const schedules = useRefreshingSchedules(page, type);

	const [scheduleToEdit, setScheduleToEdit] = useState<ExperimentScheduleVO | undefined>(undefined);
	const [scheduleIdToDelete, setScheduleIdToDelete] = useState<string | undefined>(undefined);

	if (schedules && schedules.length === 0) {
		return (
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<Container mt={-160}>
					<AstroidScreen
						title={
							<Text variant="xLargeStrong" color="slate">
								There are no scheduled Experiments.
							</Text>
						}
						icon={<IconTarget variant="xxLarge" color="slate" />}
						description={
							<Text variant="large" color="neutral600" textAlign="center">
								You can schedule an Experiment directly within the Experiment Designer or using the “Create New
								Schedule” option in the{' '}
								<RouterLink to="/experiments" sx={{ textDecoration: 'underline' }}>
									Experiment List
								</RouterLink>
								.
							</Text>
						}
					/>
				</Container>
			</Container>
		);
	}

	return (
		<ViewWrapper>
			<>
				{scheduleToEdit && (
					<EditExperimentScheduleModal
						schedule={scheduleToEdit}
						onClose={() => setScheduleToEdit(undefined)}
						disabled={false} //menu not clickable if not permitted
					/>
				)}
				{scheduleIdToDelete && (
					<DeleteScheduleExperimentModal id={scheduleIdToDelete} onClose={() => setScheduleIdToDelete(undefined)} />
				)}
			</>

			<Stack px="xxLarge" py="small" flexGrow={1} size="medium">
				<ListHeader
					left={
						<ListHeaderTitle
							title={withCronColumn ? 'Recurrent' : 'Once'}
							Icon={withCronColumn ? IconCalendarRecurrent : IconCalendarClock}
						>
							<TutorialTooltip {...TUTORIALS.experiments.gettingStarted} />
						</ListHeaderTitle>
					}
				/>

				<Table width="100">
					<TableHead>
						<TableRow>
							<TableHeadCell>Name</TableHeadCell>
							<TableHeadCell>Scheduled by</TableHeadCell>
							<TableHeadCell>Latest Run / State</TableHeadCell>
							<TableHeadCell width={180}>Next Run</TableHeadCell>
							{withCronColumn && <TableHeadCell>Crontab</TableHeadCell>}
							<TableHeadCell>Activate Schedule</TableHeadCell>
							<TableHeadCell width={40} />
						</TableRow>
					</TableHead>
					<TableBody>
						{!schedules && (
							<>
								<TableLoadingRow numColumns={5} />
								<TableLoadingRow numColumns={5} />
								<TableLoadingRow numColumns={5} />
							</>
						)}
						{schedules?.map((schedule) => (
							<ScheduleRow
								key={schedule.id}
								schedule={schedule}
								withCronColumn={withCronColumn}
								setScheduleToEdit={setScheduleToEdit}
								setScheduleIdToDelete={setScheduleIdToDelete}
							/>
						))}
					</TableBody>
				</Table>
			</Stack>
		</ViewWrapper>
	);
}

interface ScheduleRowProps {
	setScheduleToEdit: (schedule: ExperimentScheduleVO) => void;
	setScheduleIdToDelete: (id: string) => void;
	schedule: ExperimentScheduleVO;
	withCronColumn: boolean;
}

function ScheduleRow({
	setScheduleToEdit,
	setScheduleIdToDelete,
	schedule,
	withCronColumn,
}: ScheduleRowProps): ReactElement {
	const [optimisticEnabled, setOptimisticEnabled] = useState(schedule.enabled);
	const [isSaving, setIsSaving] = useState(false);
	const { createHref } = useHistory();

	const experiment = usePromise(
		() => Services.experiments.fetchExperiment(schedule.experimentKey),
		[schedule.experimentKey],
	);
	const isEditPermitted: boolean = experiment.value?._actions?.includes('edit') ?? false;
	const isDeletePermitted: boolean = experiment.value?._actions?.includes('delete-schedule') ?? false;

	useEffect(() => {
		setOptimisticEnabled(schedule.enabled);
	}, [schedule.enabled]);

	return (
		<TableRow>
			<TableDataCell maxWidth={450}>
				<RouterLink
					to={createHref((location) => {
						location.pathname = `/experiments/edit/${schedule.experimentKey}`;
					})}
					sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: 'neutral800' }}
				>
					<Text variant="mediumStrong" as="span">
						{schedule.experimentKey}
					</Text>
					&nbsp;
					<Text variant="medium" as="span">
						{schedule.experimentName}
					</Text>
				</RouterLink>
			</TableDataCell>
			<TableDataCell>
				<Container display="flex" flexDirection="row" alignItems="center" data-private>
					<UserIcon variant="medium" src={schedule.editedBy.pictureUrl} mr={5} flexShrink={0} />
					<Text variant="small" sx={{ color: 'neutral600', overflow: 'hidden', textOverflow: 'ellipsis' }} noWrap>
						{schedule.editedBy.name}
					</Text>
				</Container>
			</TableDataCell>
			<TableDataCell>
				{schedule.lastExperimentExecution && (
					<Container display="flex" flexDirection="column">
						<Container maxWidth={150}>
							<StateBadge as="state" value={schedule.lastExperimentExecution.state} />
						</Container>

						<RouterLink mr="xSmall" to={`/experiments/edit/${schedule.experimentKey}/executions`}>
							<Text
								variant="small"
								sx={{
									color: 'neutral600',
									fontVariantNumeric: 'tabular-nums',
									':hover': { color: 'slate' },
								}}
							>
								{`${formatDateWithTime(new Date(schedule.lastExperimentExecution.created))} `}
							</Text>
						</RouterLink>
					</Container>
				)}
			</TableDataCell>
			<TableDataCell>
				{schedule.nextExecution && (
					<Text
						variant="small"
						sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums', ':hover': { color: 'slate' } }}
					>
						{`${formatDateWithTime(new Date(schedule.nextExecution))} `}
					</Text>
				)}
			</TableDataCell>
			{withCronColumn && (
				<TableDataCell>
					<Text variant="small" as="span" color="neutral600">
						{schedule.cron}
					</Text>
				</TableDataCell>
			)}
			<TableDataCell>
				<Toggle
					type="radio"
					checked={optimisticEnabled}
					disabled={!isEditPermitted}
					onChange={async (e) => {
						if (isSaving) {
							return;
						}
						setIsSaving(true);

						setOptimisticEnabled(e.target.checked);
						const enabled = e.target.checked;
						const scheduleToSave = { ...schedule, enabled };

						try {
							await Services.experiments.updateExperimentSchedule(scheduleToSave);
						} catch {
							Snackbar.error('Failed to schedule experiment');
						}

						setIsSaving(false);
					}}
				/>
			</TableDataCell>

			<TableDataCell justifyContent="flex-end">
				<DropDown
					renderComponent={({ setShowMenu, showMenu, ref }) => {
						return (
							<ButtonIcon
								onClick={() => setShowMenu(!showMenu)}
								ref={ref as RefObject<HTMLButtonElement>}
								data-cy="experimentActions"
							>
								<IconNavigationMenuHorizontal />
							</ButtonIcon>
						);
					}}
				>
					{({ selectItem }) => (
						<DropdownContentFrame
							sx={{
								py: 'small',
								borderRadius: '4px',
							}}
						>
							<Stack size="none">
								<Tooltip content={isEditPermitted ? '' : `You don't have permissions to edit the schedule${''}`}>
									<Container>
										<ContextualMenuLink
											disabled={!isEditPermitted}
											onClick={async () => {
												setScheduleToEdit(schedule);
												selectItem(undefined);
											}}
										>
											<IconCalendarEdit /> Edit Schedule
										</ContextualMenuLink>
									</Container>
								</Tooltip>

								<Tooltip content={isDeletePermitted ? '' : `You don't have permissions to delete the schedule${''}`}>
									<Container>
										<ContextualMenuLink
											disabled={!isDeletePermitted}
											onClick={async () => {
												setScheduleIdToDelete(schedule.id);
												selectItem(undefined);
											}}
										>
											<IconTrash /> Delete Schedule
										</ContextualMenuLink>
									</Container>
								</Tooltip>
							</Stack>
						</DropdownContentFrame>
					)}
				</DropDown>
			</TableDataCell>
		</TableRow>
	);
}
