/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconComponent,
	IconDatabase,
	IconDebug,
	IconHelp,
	IconNewTab,
	IconReport,
	IconSaveFile,
} from 'components/icons';
import { LinkButton, Snackbar, Stack, Text, Toggle } from 'components';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import ListHeader from 'components/List/presets/ListHeader';
import { useTenant } from 'tenancy/useTenant';
import { ReactElement } from 'react';

import { useEventEffect } from '../../../utils/hooks/useEventEffect';
import { useAsyncState } from '../../../utils/hooks/useAsyncState';
import ContentWrapper from '../components/ContentWrapper';
import { Services } from '../../../services/services';
import { isOnpremise } from '../../../utils/onprem';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';

export default function Troubleshooting(): ReactElement {
	const tenant = useTenant();
	const isAdmin = tenant.user?.role === 'ADMIN';

	const [dbExportUrl] = useAsyncState(() => Services.exportApi.getDbExportUrl());
	const [metricExportUrl] = useAsyncState(() => Services.exportApi.getMetricExportUrl());
	const [amplitudeExportUrl] = useAsyncState(() => Services.exportApi.getAmplitudeExportUrl());
	const [supportEnabled, reloadSupportEnabled] = useAsyncState(async () => {
		if (isOnpremise()) {
			return false;
		}
		return Services.settingsApi.isSupportAccessEnabled();
	});

	useEventEffect(reloadSupportEnabled, ['tenant.settings.updated'], () => {}, [reloadSupportEnabled]);

	const hasDbExport = !dbExportUrl.loading && dbExportUrl.value;
	const hasMetricExport = !metricExportUrl.loading && metricExportUrl.value;
	const hasAmplitudeExport = !amplitudeExportUrl.loading && amplitudeExportUrl.value;

	return (
		<>
			<ContentWrapper>
				<ListHeader left={<ListHeaderTitle title="Troubleshooting" Icon={IconHelp} />} />
				<Stack direction="horizontal" justifyContent="space-between" alignItems="center" mb="small">
					<HelpText>
						If you need help finding and resolving problems within Steadybit, you can look into your tenant database and
						metrics or enable our technical support team to assist you.
					</HelpText>
				</Stack>

				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 320px',
						gap: '42px',
						paddingTop: '60px',
					}}
				>
					{(hasDbExport || hasMetricExport) && (
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: '1fr 1fr',
								gap: '24px',
							}}
						>
							{hasDbExport && (
								<DownloadBox
									Icon={IconDatabase}
									title="Database export"
									description="Download a copy of your Steadybit tenant database so you can analyze it using a third-party application"
								>
									<LinkButton
										id="save"
										variant="chromelessSmall"
										href={dbExportUrl.value}
										onClick={() => {
											ampli.platformDataExported({ platform_export_type: 'database' });
										}}
										sx={{
											color: 'neutral600',
											width: 'fit-content',
											ml: '-small',
										}}
									>
										<IconSaveFile variant="large" mr="xSmall" />
										Database export (.zip)
									</LinkButton>
								</DownloadBox>
							)}

							{hasMetricExport && (
								<DownloadBox
									Icon={IconReport}
									title="Metrics export"
									description="Download a copy of the system discovery and usage metrics so you can analyze those statistics with a third-party application"
								>
									<LinkButton
										id="save"
										variant="chromelessSmall"
										href={metricExportUrl.value}
										onClick={() => {
											ampli.platformDataExported({ platform_export_type: 'metrics' });
										}}
										sx={{
											color: 'neutral600',
											width: 'fit-content',
											ml: '-small',
										}}
									>
										<IconSaveFile variant="large" mr="xSmall" />
										Metrics export (.zip)
									</LinkButton>
								</DownloadBox>
							)}

							{hasAmplitudeExport && (
								<DownloadBox
									Icon={IconReport}
									title="User Analytics export"
									description="Download user analytics data and share it with Steadybit, e.g., via mail or Slack, to help improve the product. The analytics export will not contain any user- or system-related information"
								>
									<LinkButton
										id="save"
										variant="chromelessSmall"
										href={amplitudeExportUrl.value}
										onClick={() => {
											ampli.platformDataExported({ platform_export_type: 'user_analytics' });
										}}
										sx={{
											color: 'neutral600',
											width: 'fit-content',
											ml: '-small',
										}}
									>
										<IconSaveFile variant="large" mr="xSmall" />
										User Analytics export (.zip)
									</LinkButton>
								</DownloadBox>
							)}

							<div style={{ gridColumn: '1 / span 2' }}>
								<DownloadBox
									Icon={IconDebug}
									title="Technical support by Steadybit"
									description="Allow Steadybit's technical support team to log in to your tenant account to investigate and fix problems you might experience within the platform. You can withdraw the authorization at any time. Meanwhile, the audit log records any activity of Steadybit's support team in your account."
								>
									<Stack
										direction="horizontal"
										size="xSmall"
										sx={{
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '350px',
											p: 'small',
											backgroundColor: 'neutral100',
											borderRadius: '8px',
										}}
									>
										<Text variant="smallStrong" color="neutral800">
											Let Steadybit troubleshoot your tenant
										</Text>
										<Toggle
											disabled={!isAdmin}
											checked={supportEnabled.value}
											onChange={async (e) => {
												try {
													await Services.settingsApi.setSupportAccessEnabled(e.target.checked);
												} catch {
													Snackbar.error('Failed to toggle support access');
												}
											}}
										/>
									</Stack>
								</DownloadBox>
							</div>
						</div>
					)}

					<Stack
						size="small"
						sx={{
							backgroundColor: 'neutral100',
							borderRadius: '8px',
							height: 'fit-content',
							px: 'small',
							pt: 'small',
							pb: 'medium',
						}}
					>
						<Text variant="largeStrong" px="xSmall">
							You might need help with...
						</Text>
						<LinkButton
							variant="chromelessSmall"
							href="https://docs.steadybit.com/troubleshooting/extension"
							color="neutral600"
							width="fit-content"
							external
						>
							<IconNewTab mr="xSmall" />
							Extension
						</LinkButton>
						<LinkButton
							variant="chromelessSmall"
							href="https://docs.steadybit.com/troubleshooting/agent"
							color="neutral600"
							width="fit-content"
							external
						>
							<IconNewTab mr="xSmall" />
							Agent
						</LinkButton>
						<LinkButton
							variant="chromelessSmall"
							href="https://docs.steadybit.com/troubleshooting/on-prem-platform"
							color="neutral600"
							width="fit-content"
							external
						>
							<IconNewTab mr="xSmall" />
							On-prem platform
						</LinkButton>
					</Stack>
				</div>
			</ContentWrapper>
		</>
	);
}

interface DownloadBoxProps {
	children: ReactElement;
	description: string;
	Icon: IconComponent;
	title: string;
}

function DownloadBox({ Icon, title, description, children }: DownloadBoxProps): ReactElement {
	return (
		<Stack
			size="small"
			sx={{
				justifyContent: 'space-between',
				border: '1px solid',
				borderColor: 'neutral300',
				borderRadius: '8px',
				p: 'small',
			}}
		>
			<Stack size="small">
				<Stack direction="horizontal" size="xSmall" alignItems="center">
					<Icon variant="large" color="slate" />
					<Text variant="mediumStrong">{title}</Text>
				</Stack>
				<Text variant="medium" color="neutral600">
					{description}
				</Text>
			</Stack>

			{children}
		</Stack>
	);
}
