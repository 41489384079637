/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, Stack, Text } from 'components';
import { IconComponent } from 'components/icons';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface CreateBoxProps {
	icon: IconComponent;
	iconColor?: string;
	background?: string;
	height?: number;
	width?: number;
	header: ReactElement | string;
	text: string;
	highlighted?: boolean;
	children: React.ReactNode;
	mt?: number;
}

export default function CreateBox({
	icon,
	iconColor,
	header,
	text,
	height,
	width,
	background,
	mt,
	children,
	highlighted,
}: CreateBoxProps): ReactElement {
	const Icon = icon;

	const borderStyle = highlighted
		? {
				border: '1px solid',
				borderColor: 'slate',
				boxShadow: `1px 1px 8px 0px ${theme.colors.slate}`,
			}
		: {};

	return (
		<Container
			display="flex"
			flexDirection="column"
			size={'small'}
			width={width ?? 432}
			height={height ?? 228}
			bg={background ?? 'neutral000'}
			sx={{
				borderRadius: 4,
				...borderStyle,
				transition: 'height 0.4s ease-in-out',
				alignItems: 'space-between',
			}}
			p={'medium'}
			mt={mt ?? 32}
			justifyContent={'space-between'}
		>
			<Stack direction={'vertical'} size={'xSmall'}>
				<Text>
					<Icon width={24} height={24} color={iconColor} />
				</Text>
				<Text fontSize={16} fontWeight={'bold'} lineHeight={'150%'} color={'neutral800'}>
					{header}
				</Text>
				<Text fontSize={13} fontWeight={'normal'} lineHeight={'150%'} color={'neutral700'}>
					{text}
				</Text>
			</Stack>
			{children}
		</Container>
	);
}
