/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { EnvironmentVO, TargetAttributeDescriptionVO, TargetTypeDescriptionVO } from 'ui-api';
import { Container, LoadingIndicator, Pill, RouterLink, Stack, Text } from 'components';
import { useAttributeDefinitions } from 'attributes/useAttributeDefinitions';
import { TargetId, emptyTargetDefinition, toTargetId } from 'targets/util';
import TargetDetailsModal from 'targets/TargetDetails/TargetDetailsModal';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { TargetViewTable } from 'hocs/targets/TargetViewTable';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { UrlParam, useUrlState } from 'url/useUrlState';
import { localeCompareIgnoreCase } from 'utils/string';
import { Flex } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { IconTarget } from 'components/icons';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import useAgentReport from '../../../../utils/hooks/useAgentReport';
import { EnvironmentConfig } from './types';

interface UrlState {
	targetId: TargetId;
}

interface Label {
	highlighted?: boolean;
	count: number;
	label: string;
	value: string;
}
interface Group {
	highlighted?: boolean;
	options: Label[];
	count: number;
	label: string;
}

export default function ResultView(): ReactElement {
	const formik = useFormikContext<EnvironmentConfig>();
	const environment: EnvironmentConfig = formik.values;

	const [{ targetId }, , updateUrlWithState] = useUrlState<UrlState>([
		getTargetIdParam(isEnvironmentVO(environment) ? environment.id : undefined),
	]);

	const attributeDefinitionsResult = useAttributeDefinitions();
	const attributeDefinitions = attributeDefinitionsResult.value || [];
	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value;

	const [targetCount, setTargetCount] = useState<Record<string, number> | null>(null);
	const targetCountsResult = usePromise(
		() => Services.environments.countTargets(environment.predicate),
		[environment.predicate],
	);
	useEffect(() => {
		if (targetCountsResult.value) {
			setTargetCount(targetCountsResult.value);
		}
	}, [targetCountsResult.value]);

	const isLoading = attributeDefinitionsResult.loading || targetDefinitionsResult.loading || targetCount === null;

	if (isLoading) {
		return <LoadingContent />;
	}

	const hasSomeTargets = targetCount.total !== undefined && targetCount.total > 0;

	return (
		<Container
			sx={{
				width: '100%',
				height: '100%',
				background: 'neutral100',
				p: 'medium',
				minHeight: '460px',
				overflowY: 'auto',
			}}
		>
			{isLoading ? (
				<LoadingContent />
			) : (
				<>
					{!hasSomeTargets && <EmptyTargetsContent />}

					{hasSomeTargets && targetId != null && (
						<TargetDetailsModal
							environmentId={isEnvironmentVO(environment) ? environment.id : null}
							onClose={() => updateUrlWithState({ targetId: undefined })}
							targetId={targetId}
							withAdvice={false}
						/>
					)}
				</>
			)}
			{targetDefinitions &&
				hasSomeTargets &&
				(!isLoading || targetCount.total === undefined) &&
				!attributeDefinitionsResult.loading &&
				!targetDefinitionsResult.loading && (
					<Table
						targetCount={targetCount}
						environment={environment}
						targetDefinitions={targetDefinitions.filter((target) => targetCount[target.id] > 0)}
						attributeDefinitions={attributeDefinitions}
					/>
				)}
		</Container>
	);
}

interface TableProps {
	attributeDefinitions: TargetAttributeDescriptionVO[];
	targetDefinitions: TargetTypeDescriptionVO[];
	targetCount: Record<string, number>;
	environment: EnvironmentConfig;
}

function Table({ environment, targetCount, targetDefinitions, attributeDefinitions }: TableProps): ReactElement {
	const [, getUrlWithState] = useUrlState<UrlState>([
		getTargetIdParam(isEnvironmentVO(environment) ? environment.id : undefined),
	]);

	const categories = Array.from(new Set(targetDefinitions.map((target) => target.category || ''))).sort((a, b) => {
		if (a === '') {
			return 1;
		}
		return localeCompareIgnoreCase(a, b);
	});
	const unknownTargetIds = getUnknownTargetIds(targetCount, targetDefinitions);
	if (unknownTargetIds.length > 0 && !categories.includes('Unknown')) {
		categories.push('Unknown');
	}
	const [selectedCategory, setSelectedCategory] = useState<string | undefined>(categories[0]);
	const [selectedTargetId, setSelectedTargetId] = useState<string>(
		targetDefinitions.find((target) => target.category === selectedCategory)?.id || targetDefinitions[0]?.id || '',
	);

	useEffect(() => {
		if (targetDefinitions.length > 0 && !selectedTargetId) {
			setSelectedTargetId(targetDefinitions[0]?.id || '');
		}
	}, [targetDefinitions]);

	// this effect controls if the current selected category and subcategory are still avaliable and reset them if not
	useEffect(() => {
		if (selectedCategory === 'Unknown') {
			if (!unknownTargetIds.includes(selectedTargetId)) {
				setSelectedTargetId(unknownTargetIds[0]);
			}
		} else if (categories.length > 0 && categories.find((c) => c === selectedCategory) === undefined) {
			setSelectedCategory(targetDefinitions[0].category);
		} else if (
			targetDefinitions.length > 0 &&
			selectedTargetId &&
			targetDefinitions.find((t) => t.id === selectedTargetId) === undefined
		) {
			setSelectedTargetId(
				targetDefinitions.find((target) => target.category === selectedCategory)?.id || targetDefinitions[0].id,
			);
		}
	}, [categories, selectedCategory]);

	const targetDefinition: TargetTypeDescriptionVO =
		targetDefinitions.find((target) => target.id === selectedTargetId) || emptyTargetDefinition(selectedTargetId);

	const groups = getGroups({
		targetDefinitions,
		selectedTargetId,
		targetCount,
		categories,
	});

	const selectedGroup = getSelectedGroup(selectedTargetId, groups);

	return (
		<Flex spacing="small" style={{ pt: 'xSmall' }}>
			<Flex direction="horizontal" spacing="xSmall" align="center">
				<Text variant="mediumStrong">Included Targets</Text>
				<Pill backgroundColor="cyan200" color="cyan800">
					{targetCount.total}
				</Pill>
			</Flex>

			<Flex style={{ width: '100%' }}>
				<Categories selectedGroup={selectedGroup} groups={groups} setSelectedTargetId={setSelectedTargetId} />

				{selectedTargetId && targetDefinition && (
					<Container overflowY="auto" width="100%" sx={{ borderTop: '1px solid ' + theme.colors.neutral300 }}>
						<TargetViewTable
							query=""
							framed
							framePadding="none"
							getTargetDetailHref={(target) => getUrlWithState({ targetId: toTargetId(target) })}
							attributeDefinitions={attributeDefinitions}
							targetDefinition={targetDefinition}
							predicate={environment.predicate}
							targetsPerPage={15}
						/>
					</Container>
				)}
			</Flex>
		</Flex>
	);
}

interface CategoriesProps {
	selectedGroup: Group | undefined;
	groups: Group[];
	setSelectedTargetId: (id: string) => void;
}

function Categories({ selectedGroup, groups, setSelectedTargetId }: CategoriesProps): ReactElement {
	return (
		<>
			<Flex
				direction="horizontal"
				style={{
					width: 'calc(100% - 1px)',
					borderTop: '1px solid ' + theme.colors.neutral300,
					borderLeft: '1px solid ' + theme.colors.neutral300,
					overflow: 'hidden',
				}}
				wrap
			>
				{groups.map((group) => {
					return (
						<Flex
							key={group.label}
							direction="horizontal"
							align="center"
							spacing="xSmall"
							style={{
								flexGrow: 1,
								padding: '8px 12px',
								backgroundColor: group.highlighted ? theme.colors.neutral000 : theme.colors.neutral150,
								borderRight: '1px solid ' + theme.colors.neutral300,
								borderBottom: '1px solid ' + theme.colors.neutral300,

								onHover: {
									backgroundColor: theme.colors.neutral050,
								},
							}}
							onClick={() => setSelectedTargetId(group.options[0].value)}
						>
							<Text variant="mediumStrong" color={group.highlighted ? 'neutral800' : 'slate'} sx={{ ...textEllipsis }}>
								{group.label}
							</Text>
							<Pill backgroundColor="neutral300" color="neutral700">
								{group.count}
							</Pill>
						</Flex>
					);
				})}
			</Flex>

			{selectedGroup && (
				<Flex
					direction="horizontal"
					style={{
						width: 'calc(100% - 2px)',
						overflow: 'hidden',
						backgroundColor: theme.colors.neutral000,
						borderLeft: '1px solid ' + theme.colors.neutral300,
						borderRight: '1px solid ' + theme.colors.neutral300,
						padding: '8px 0',
					}}
					wrap
				>
					{selectedGroup.options.map((label) => {
						return (
							<Flex
								key={label.value}
								direction="horizontal"
								align="center"
								spacing="xSmall"
								style={{
									padding: '4px 8px',
									margin: '4px 6px',
									borderBottom: '2px solid ' + (label.highlighted ? theme.colors.slate : theme.colors.neutral000),
									onHover: {
										borderBottom: '2px solid ' + (label.highlighted ? theme.colors.slate : theme.colors.neutral400),
									},
								}}
								onClick={() => setSelectedTargetId(label.value)}
							>
								<Text variant="mediumStrong" color={label.highlighted ? 'neutral800' : 'slate'}>
									{label.label}
								</Text>
								<Pill backgroundColor="neutral300" color="neutral700">
									{label.count}
								</Pill>
							</Flex>
						);
					})}
				</Flex>
			)}
		</>
	);
}

function LoadingContent(): ReactElement {
	return (
		<Container display="flex" alignItems="center" justifyContent="center" height="300px" width="100%">
			<LoadingIndicator variant="xxLarge" color="slate" />
		</Container>
	);
}

function EmptyTargetsContent(): ReactElement {
	const { someAgentHasReportedInThePast } = useAgentReport();
	return (
		<Container display="flex" alignItems="center" justifyContent="center" height="calc(100% - 200px)">
			<AstroidScreen
				title={
					<Text variant="xLargeStrong" color="slate">
						{someAgentHasReportedInThePast
							? 'No targets discovered matching your scope'
							: 'No targets discovered to be assigned to this environment'}
					</Text>
				}
				icon={<IconTarget variant="xxxLarge" color="neutral600" />}
				description={
					<Stack direction="horizontal" size="xSmall">
						<Text variant="medium" color="neutral600" textAlign="center">
							{someAgentHasReportedInThePast ? (
								<>
									Please adjust your environment scope to assign discovered targets to this environment,
									<br />
									or <RouterLink to={'/settings/agents/setup'}>install additional agents and extensions</RouterLink> to
									discover more targets.
								</>
							) : (
								<>
									You must <RouterLink to={'/onboarding'}>continue with the agent setup</RouterLink>, to discover
									targets that can be assigned to this environment.
								</>
							)}
						</Text>
					</Stack>
				}
			/>
		</Container>
	);
}

function getUnknownTargetIds(
	targetCount: Record<string, number>,
	targetDefinitions: TargetTypeDescriptionVO[],
): string[] {
	return Object.keys(targetCount)
		.filter((id) => id != 'total')
		.filter((id) => targetDefinitions.find((t) => t.id === id) === undefined);
}

function isEnvironmentVO(environment: EnvironmentConfig): environment is EnvironmentVO {
	return environment !== undefined && 'id' in environment;
}

function getTargetIdParam(envId?: string): UrlParam<string | undefined> {
	return {
		pathSegment: `/${envId ? envId : '<new>'}`,
		name: 'targetId',
		defaultValue: undefined,
	};
}

function getGroups({
	targetDefinitions,
	selectedTargetId,
	targetCount,
	categories,
}: {
	targetDefinitions: TargetTypeDescriptionVO[];
	categories: Array<string | undefined>;
	targetCount: Record<string, number>;
	selectedTargetId: string | undefined;
}): Group[] {
	return categories.map((category) => {
		if (category === 'Unknown') {
			const aggregatedCount = getUnknownTargetIds(targetCount, targetDefinitions).reduce(
				(acc, targetId) => acc + (targetCount[targetId] || 0),
				0,
			);

			const targetIds = Object.keys(targetCount)
				.filter((id) => id != 'total')
				.filter((id) => targetDefinitions.find((t) => t.id === id) === undefined);

			return {
				highlighted: Boolean(selectedTargetId && targetIds.includes(selectedTargetId)),
				label: category,
				count: aggregatedCount,
				options: targetIds.map((targetId) => {
					const count = targetCount[targetId] || 0;
					return {
						highlighted: targetId === selectedTargetId,
						value: targetId,
						label: targetId,
						count,
					};
				}),
			};
		}

		const aggregatedCount = targetDefinitions
			.filter((target) => (target.category || '') === category)
			.map((target) => target.id)
			.reduce((acc, targetId) => acc + (targetCount[targetId] || 0), 0);

		const categoryTargetDefinitions = targetDefinitions.filter((target) => (target.category || '') === category);

		return {
			highlighted: Boolean(selectedTargetId && categoryTargetDefinitions.map((t) => t.id).includes(selectedTargetId)),
			label: category || 'Other',
			count: aggregatedCount,
			options: categoryTargetDefinitions.map((target) => {
				const count = targetCount[target.id] || 0;
				return {
					highlighted: target.id === selectedTargetId,
					label: target.label.other,
					value: target.id,
					count,
				};
			}),
		};
	});
}

function getSelectedGroup(targetId: string | undefined, groups: Group[]): Group | undefined {
	for (let iG = 0; iG < groups.length; iG++) {
		const options = groups[iG].options;
		for (let iO = 0; iO < options.length; iO++) {
			const label = options[iO];
			if (targetId === label.value) {
				return groups[iG];
			}
		}
	}
	return undefined;
}
