/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { VariableVO } from '../ui-api';
import { isNotBlank } from './string';

export const EnvVarRegexHandlebar = /\{\{([\sA-Za-z0-9-_.]+?)\}\}/g;
const EvnVarRegexHandlebarEscape = /(\\)({{[\sA-Za-z0-9-_.]+?}})/g;

export function containsUnknownVariable(value: string, variables: VariableVO[]): boolean {
	const varNames = listVariables(value);
	for (const varName of varNames) {
		const matchingEnvVar = variables.find((envVar) => envVar.key === varName);
		if (!matchingEnvVar || !matchingEnvVar?.value) {
			return true;
		}
	}
	return false;
}

export function listVariables(value: unknown): string[] {
	// as Safari does not support lookbehind, we need to use a workaround replacing the escaped pattern
	if (!value) {
		return [];
	} else if (typeof value === 'string') {
		return Array.from(value.replaceAll('\\{{', '\\#WORKAROUND#').matchAll(EnvVarRegexHandlebar))
			.map((match) => match[1].trim())
			.filter(isNotBlank);
	} else if (Array.isArray(value)) {
		return value.flatMap((v) => listVariables(v));
	} else if (typeof value === 'object') {
		return Object.values(value).flatMap((v) => listVariables(v));
	} else {
		return listVariables(JSON.stringify(value));
	}
}

export function listVariableKeyValuePairs(
	value: { [index: string]: unknown },
	variable: string,
): Array<[string, string]> {
	const result: Map<string, string> = new Map();
	Object.keys(value).forEach((key) => {
		listVariables(value[key])
			.filter((_variable) => variable === _variable)
			.forEach((_variable) => {
				result.set(key, _variable);
			});
	});
	return Array.from(result);
}

export function interpolateVariables(value: string, variables: VariableVO[]): string {
	let interpolated = value;
	for (const variable of variables) {
		interpolated = interpolated
			// as Safari does not support lookbehind, we need to use a workaround replacing the escaped pattern
			.replaceAll('\\{{', '\\#WORKAROUND#')
			.replaceAll(`{{${variable.key}}}`, variable.value)
			.replaceAll('\\#WORKAROUND#', '\\{{');
	}
	return interpolated.replaceAll(EvnVarRegexHandlebarEscape, '$2');
}

export function quoteForVariables(value: string): string {
	return value.replaceAll(EnvVarRegexHandlebar, '\\$&');
}
