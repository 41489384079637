/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Checkbox,
	Container,
	ModalContentV2,
	ModalOverlay,
	ModalV2,
	Pagination,
	Stack,
	Text,
	TextField,
} from 'components';
import {
	ExperimentStepExecutionActionTargetVO,
	ExperimentStepExecutionActionVO,
	ExperimentStepExecutionWaitVO,
} from 'ui-api';
import { IconClose, IconSearch, IconTarget } from 'components/icons';
import EmptyListContent from 'components/List/EmptyListContent';
import { toParameterLabelValues } from 'services/actionsApi';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { ReactElement, useEffect, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { ActionIcon } from 'hocs/ActionIcon';
import { Services } from 'services/services';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { startCase } from 'lodash';

import { ExperimentExecutionLogStepLine, isExperimentStepExecutionActionVO } from '../experimentExecutionLog';
import { getColors, isErrored, isCanceled, isSkipped, isFailed } from '../utils';
import LogLineWaiting from './LogLineWaiting';
import CanceledBadge from './CanceledBadge';
import ErroredBadge from './ErroredBadge';
import FailedBadge from './FailedBadge';
import KeyChildren from './KeyChildren';
import KeyValue from './KeyValue';
import LogLine from './LogLine';

interface ExperimentExecutionLogStepModalProps {
	stepLog: ExperimentExecutionLogStepLine;
	experimentExecutionId: number;
	onClose: () => void;
}

export default function ExperimentExecutionLogStepModal({
	experimentExecutionId,
	stepLog,
	onClose,
}: ExperimentExecutionLogStepModalProps): ReactElement {
	const { step } = stepLog;
	const isAttack = isExperimentStepExecutionActionVO(step);

	const [filterErrors, setFilterErrors] = useState(false);
	const [filterCanceled, setFilterCanceled] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');

	const [action] = useAsyncState(
		() => (isAttack ? Services.actions.findAction(step.actionId) : Promise.resolve(undefined)),
		[isAttack ? step.actionId : ''],
	);
	const parameters = usePromise(
		async () => (action.value?.id ? await Services.experiments.fetchActionParameters(action.value.id) : []),
		[action.value?.id],
	);

	const colors = isExperimentStepExecutionActionVO(step)
		? getColors(step.kind)
		: {
				backgroundColor: '#bec6da',
				backgroundImage: 'repeating-linear-gradient(120deg,transparent,transparent 4px,#aebad1 4px,#aebad1 8px)',
				color: 'neutral700',
			};

	const allTargetExecutions = isAttack ? (step as ExperimentStepExecutionActionVO).targetExecutions : [];
	const queryFilteredTargetExecutions = allTargetExecutions.filter((t) => {
		const query = searchQuery.toLowerCase();
		if (t.targetName && t.targetName.toLowerCase().includes(query)) {
			return true;
		}
		if (t.agentHostname && t.agentHostname.toLowerCase().includes(query)) {
			return true;
		}
		if (t.reason && t.reason.toLowerCase().includes(query)) {
			return true;
		}
		return false;
	});
	const erroredTargetExecutions = isAttack ? queryFilteredTargetExecutions.filter((t) => isErrored(t.state)) : [];
	const canceledTargetExecutions = isAttack ? queryFilteredTargetExecutions.filter((t) => isCanceled(t.state)) : [];
	const skippedTargetExecutions = isAttack ? queryFilteredTargetExecutions.filter((t) => isSkipped(t.state)) : [];

	const targetExecutions = queryFilteredTargetExecutions
		.filter((t) => {
			if (isSkipped(t.state)) {
				return false;
			}
			// if no filter is set, just show everything
			if (!filterErrors && !filterCanceled) {
				return true;
			}
			return (filterErrors && isErrored(t.state)) || (filterCanceled && isCanceled(t.state));
		})
		.sort((t1, t2) => (t2.started ? t2.started.getTime() : 0) - (t1.started ? t1.started.getTime() : 0));
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2
					frameless
					width="90vw"
					sx={{
						borderRadius: 4,
						overflow: 'hidden',
					}}
				>
					<ModalContentV2>
						<div style={{ position: 'relative' }}>
							<Container
								sx={{
									position: 'sticky',
									top: 0,
									pt: '36px',
									pb: '16px',
									px: '32px',
									backgroundColor: 'neutral200',
									borderBottom: '1px solid ' + theme.colors.neutral300,
									zIndex: 1,
								}}
							>
								<ButtonIcon
									variant="medium"
									onClick={close}
									sx={{
										position: 'absolute',
										top: '0px',
										right: '0px',
									}}
								>
									<IconClose />
								</ButtonIcon>

								<Container display="flex" justifyContent="space-between" alignItems="center" mb="small">
									<Stack
										direction="horizontal"
										alignItems="center"
										size="xSmall"
										sx={{
											width: 'fit-content',
											p: 8,
											borderRadius: 4,
											backgroundColor: 'white',
										}}
									>
										{action.value && (
											<Container
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													width: 32,
													height: 32,
													backgroundColor: colors.backgroundColor,
													borderRadius: 4,
												}}
											>
												<ActionIcon id={action.value.id} color={colors.color} />
											</Container>
										)}

										<Stack size="none">
											{action.value && (
												<Text variant="xSmall" color="neutral700">
													{action.value.category ? `${startCase(action.value.category)} / ` : ''}
													{action.value.name}
												</Text>
											)}
											<Text variant="smallStrong" sx={{ ...textEllipsis }}>
												{step.customLabel || step.name}
											</Text>
										</Stack>
									</Stack>

									<Stack direction="horizontal">
										{isAttack && (
											<ErroredBadge numberErrored={step.targetExecutions.filter((t) => isErrored(t.state)).length} />
										)}
										{isAttack && (
											<FailedBadge numberFailed={step.targetExecutions.filter((t) => isFailed(t.state)).length} />
										)}
										{
											<CanceledBadge
												numberCanceled={
													isExperimentStepExecutionActionVO(step)
														? step.targetExecutions.filter((t) => isCanceled(t.state)).length
														: isCanceled(step.state)
															? 1
															: 0
												}
											/>
										}
									</Stack>
								</Container>

								<Text variant="smallStrong" mb="xSmall">
									Settings
								</Text>
								<Container
									sx={{
										display: 'grid',
										gridTemplateColumns: '1fr 1fr 1fr 1fr',
										gap: 12,
									}}
								>
									{isAttack && (
										<KeyValue
											k="Targets"
											v={`${step.targetExecutions.length} of ${
												(step as ExperimentStepExecutionActionVO).totalTargetCount
											}${
												(step as ExperimentStepExecutionActionVO).totalTargetCount > 0
													? ` (Ratio: ${
															Math.round(
																100_000 *
																	(step.targetExecutions.length /
																		(step as ExperimentStepExecutionActionVO).totalTargetCount),
															) / 1_000
														}%)`
													: ''
											}`}
										/>
									)}
									<KeyValue k="Continue on any failures / errors" v={step.ignoreFailure ? 'Yes' : 'No'} />

									{parameters.value &&
										toParameterLabelValues(step.parameters, parameters.value).map(({ name, value }) =>
											value ? <KeyValue key={name} k={startCase(name)} v={value} withTooltip /> : null,
										)}
								</Container>
								<Stack
									direction="horizontal"
									sx={{
										mt: 8,
										pt: 8,
										borderTop: '1px solid ' + theme.colors.neutral400,
									}}
								>
									<KeyValue
										k="Preparation time"
										v={`${formatTime(step.estimatedStart)} - ${step.started ? formatTime(step.started) : ''}`}
									/>
									<Container
										sx={{
											borderLeft: '1px solid ' + theme.colors.neutral400,
										}}
									/>
									<KeyValue
										k="Execution time"
										v={`${step.started ? formatTime(step.started) : ''} - ${step.ended ? formatTime(step.ended) : ''}`}
									/>
								</Stack>
							</Container>

							<Stack
								size="none"
								style={{
									overflowY: 'auto',
								}}
							>
								<Stack direction="horizontal" alignItems="center" justifyContent="space-between" pt="16px" px="32px">
									{(erroredTargetExecutions.length > 0 &&
										erroredTargetExecutions.length !== allTargetExecutions.length) ||
									(canceledTargetExecutions.length > 0 &&
										canceledTargetExecutions.length !== allTargetExecutions.length) ? (
										<Container sx={{ flexGrow: 1 }}>
											<Text variant="smallStrong" mb="xSmall">
												Filter by:
											</Text>
											<Stack direction="horizontal" alignItems="center" size="large">
												{erroredTargetExecutions.length > 0 && (
													<Stack direction="horizontal" alignItems="center" size="xSmall">
														<Checkbox
															onChange={(e) => setFilterErrors(Boolean(e.target.checked))}
															checked={filterErrors}
														/>
														<Text variant="small" color="neutral600">
															Errors
														</Text>
													</Stack>
												)}
												{canceledTargetExecutions.length > 0 && (
													<Stack direction="horizontal" alignItems="center" size="xSmall">
														<Checkbox
															onChange={(e) => setFilterCanceled(Boolean(e.target.checked))}
															checked={filterCanceled}
														/>
														<Text variant="small" color="neutral600">
															Canceled
														</Text>
													</Stack>
												)}
											</Stack>
										</Container>
									) : (
										<div />
									)}

									<TextField
										iconLeft={IconSearch}
										placeholder="Search affected targets, error reasons and agents"
										value={searchQuery}
										wrapperSx={{
											width: '450px',
										}}
										onChange={(e) => setSearchQuery(e.target.value)}
									/>
								</Stack>

								<Container px={32} py={16}>
									{isAttack ? (
										<Stack size="large">
											<KeyChildren
												k={
													step.kind === 'ATTACK'
														? queryFilteredTargetExecutions.length === 0 && allTargetExecutions.length > 0
															? ''
															: 'Targets affected'
														: ''
												}
											>
												<Stack size="xxSmall">
													<TargetExecutionLines
														experimentExecutionId={experimentExecutionId}
														targetExecutions={targetExecutions}
														step={step}
													/>
													{queryFilteredTargetExecutions.length === 0 && allTargetExecutions.length > 0 && (
														<EmptyListContent
															icon={<IconTarget variant="xxLarge" color="purple700" />}
															title="No targets matched your filter found"
														/>
													)}

													{allTargetExecutions.length === 0 && (
														<LogLine
															key={'no-targets'}
															targetExecution={getDummyTargetExecutionForMissingTargetExecutions(step)}
															step={step}
															experimentExecutionId={experimentExecutionId}
															backgroundColor={'neutral000'}
														/>
													)}
												</Stack>
											</KeyChildren>

											{skippedTargetExecutions.length > 0 && (
												<KeyChildren k="Targets skipped">
													<Stack size="xxSmall">
														{skippedTargetExecutions.map((targetExecution, i) => (
															<LogLine
																key={targetExecution.id}
																backgroundColor={i % 2 === 0 ? 'neutral000' : 'neutral100'}
																experimentExecutionId={experimentExecutionId}
																step={step as ExperimentStepExecutionActionVO}
																targetExecution={targetExecution}
															/>
														))}
													</Stack>
												</KeyChildren>
											)}
										</Stack>
									) : step.started ? (
										<LogLineWaiting started={step.started} state={step.state} duration={step.duration} />
									) : null}
								</Container>
							</Stack>
						</div>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function TargetExecutionLines({
	experimentExecutionId,
	targetExecutions,
	step,
}: {
	targetExecutions: ExperimentStepExecutionActionTargetVO[];
	step: ExperimentStepExecutionActionVO;
	experimentExecutionId: number;
}): ReactElement {
	const [page, setPage] = useState(0);
	const pageSize = 15;
	const totalElements = targetExecutions.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	useEffect(() => {
		setPage(0);
	}, [targetExecutions.length]);

	return (
		<>
			{targetExecutions.slice(page * pageSize, (page + 1) * pageSize).map((targetExecution, i) => (
				<LogLine
					key={targetExecution.id}
					backgroundColor={i % 2 === 0 ? 'neutral000' : 'neutral100'}
					experimentExecutionId={experimentExecutionId}
					step={step as ExperimentStepExecutionActionVO}
					targetExecution={targetExecution}
				/>
			))}

			{totalPages > 1 && (
				<div
					style={{
						height: '1px',
						backgroundColor: theme.colors.neutral200,
						margin: '16px -32px 8px -32px',
					}}
				/>
			)}
			<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
		</>
	);
}

function getDummyTargetExecutionForMissingTargetExecutions(
	step: ExperimentStepExecutionActionVO | ExperimentStepExecutionWaitVO,
): ExperimentStepExecutionActionTargetVO {
	return {
		id: 'no-targets',
		state: step.state,
		reason: step.reason || '',
		reasonDetails: '',
		started: step.started || new Date(),
		ended: step.ended || new Date(),
		instance: '',
		agentId: '',
		agentHostname: '',
		artifacts: [],
	};
}
