/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ExperimentError } from 'pages/experimentsV2/types';
import { FieldTypeVO, FieldVO } from 'ui-api';
import { Separator } from 'hocs/Separator';
import { Header } from 'hocs/Header';
import { ReactElement } from 'react';
import { useField } from 'formik';

import StressNgWorker from './Controls/StressNgWorker';
import StringOptions from './Controls/StringOptions';
import MultiSelect from './Controls/MultiSelect';
import Percentage from './Controls/Percentage';
import TextArray from './Controls/TextArray';
import Duration from './Controls/Duration';
import KeyValue from './Controls/KeyValue';
import TextArea from './Controls/TextArea';
import FieldWrapper from './FieldWrapper';
import Integer from './Controls/Integer';
import Bitrate from './Controls/Bitrate';
import Boolean from './Controls/Boolean';
import Text from './Controls/Text';
import File from './Controls/File';
import Uri from './Controls/Uri';

interface FieldValueHandlerProps {
	disabled: boolean;
	field: FieldVO;
	path: string;
}

export default function FieldValueHandler({ disabled, field, path }: FieldValueHandlerProps): ReactElement | null {
	const [{ value }, meta, { setValue, setTouched }] = useField<unknown>(path);
	const { mode } = useEditorSettings();

	const error: ExperimentError | undefined = meta.error as ExperimentError | undefined;
	const stepErrors = error ? [error] : [];

	return (
		<Field
			field={field}
			stepErrors={stepErrors.map((error) => ({ ...error, level: mode === 'templateUsage' ? 'warning' : 'error' }))}
			disabled={disabled}
			value={value}
			setValue={(_value) => {
				setValue(_value);
				setTouched(true);
			}}
			path={path}
		/>
	);
}

interface FieldProps {
	stepErrors: ExperimentError[];
	disabled: boolean;
	value: unknown;
	field: FieldVO;
	path: string;
	setValue: (value: unknown) => void;
}

function Field({ field, disabled, value, setValue, stepErrors }: FieldProps): ReactElement | null {
	if (field.deprecated && !value) {
		return null;
	}

	if (field.readonly) {
		return <FieldWrapper field={field}>{readOnlyContent(field, value as string)}</FieldWrapper>;
	}

	if (field.type === 'header') {
		return <Header variant="small" mb={'small'} label={field.label} />;
	}

	if (field.type === 'separator') {
		return <Separator />;
	}

	return (
		<FieldWrapper field={field} errors={stepErrors}>
			<FieldInput
				field={field}
				disabled={disabled}
				hasErrors={stepErrors.length > 0}
				hasWarnings={false}
				value={value}
				setValue={setValue}
			/>
		</FieldWrapper>
	);
}

interface FieldInputProps {
	hasWarnings: boolean;
	hasErrors: boolean;
	disabled: boolean;
	field: FieldVO;
	value: unknown;
	setValue: (value: unknown) => void;
}

export function FieldInput({
	hasWarnings,
	hasErrors,
	disabled,
	value,
	field,
	setValue,
}: FieldInputProps): ReactElement {
	const { type, min, max, acceptedFileTypes, optionsOnly } = field;
	let options = field.options;

	if (options) {
		options = options.slice().sort((a, b) => a.label.localeCompare(b.label));
	}

	const common = {
		hasWarnings,
		hasErrors,
		disabled,
		setValue,
	};

	if (type === 'bitrate') {
		return <Bitrate {...common} value={value as string} />;
	}
	if (type === 'boolean') {
		return <Boolean {...common} value={value as boolean} />;
	}
	if (type === 'duration') {
		return <Duration {...common} value={value as string} />;
	}
	if (type === 'file') {
		return <File {...common} accept={acceptedFileTypes?.join(',') || ''} value={value as string} />;
	}
	if (type === 'integer') {
		return <Integer {...common} min={min} max={max} value={value as number} />;
	}
	if (type === 'key-value') {
		return <KeyValue {...common} value={value as KeyValuePair[]} />;
	}
	if (type === 'percentage') {
		return <Percentage {...common} value={value as string} />;
	}
	if (type === 'stressng-workers') {
		return <StressNgWorker {...common} min={min} max={max} value={value as string} />;
	}
	if (type === 'string') {
		if (options?.length) {
			return (
				<StringOptions
					{...common}
					options={options}
					value={value as string}
					optionsOnly={optionsOnly === undefined ? true : optionsOnly}
				/>
			);
		}
		return <Text {...common} value={value as string} />;
	}
	if (type === 'string[]') {
		if (options?.length) {
			return <MultiSelect {...common} options={options} value={value as string[]} />;
		}
		return <TextArray {...common} value={value as string[]} />;
	}
	if (type === 'textarea') {
		return <TextArea {...common} value={value as string} />;
	}
	if (type === 'regex') {
		return <Text {...common} value={value as string} />;
	}
	if (type === 'url') {
		return <Uri {...common} placeholder="https://example.com" value={value as string} />;
	}

	if (options?.length) {
		return (
			<StringOptions
				{...common}
				options={options}
				value={value as string}
				optionsOnly={optionsOnly === undefined ? true : optionsOnly}
			/>
		);
	}
	return <Text {...common} value={value as string} />;
}

function readOnlyContent(field: FieldVO, value: string): string {
	if (field.type === 'boolean') {
		return value ? 'true' : 'false';
	}

	if (field.type === 'string[]' && field.options?.length) {
		const selectedOptions = field.options
			.filter((option) => value.includes(option.value))
			.map((option) => option.label)
			.join(', ');
		return selectedOptions || value;
	}

	if (field.type === 'string' && field.options?.length) {
		return field.options.find((option) => value === option.value)?.label || value;
	}

	return value;
}

export function getDefaultValue(type: FieldTypeVO): unknown {
	if (
		type === 'bitrate' ||
		type === 'duration' ||
		type === 'percentage' ||
		type === 'integer' ||
		type === 'stressng-workers'
	) {
		return 0;
	}
	if (type === 'boolean') {
		return false;
	}
	return '';
}
