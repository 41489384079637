/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import IncludePermittedToggle from 'pages/templates/FromTemplateModal/IncludePermittedToggle';
import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { TargetTypeDescriptionVO, TemplatePreviewVO } from 'ui-api';
import { Box, Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';

import Templates, { TemplateSkeleton } from './Templates';
import './CollapsedScrollbar.css';

interface TemplatesContentProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	templates: TemplatePreviewVO[] | undefined;
	selectedTemplateId?: string;
	isSearchDefined: boolean;
	collapsed?: boolean;
	onTemplateClick: (templateId: string) => void;
}

export default function TemplatesContent({
	selectedTemplateId,
	targetDefinitions,
	isSearchDefined,
	templates,
	collapsed,
	onTemplateClick,
}: TemplatesContentProps): ReactElement {
	const [{ includeNonPermittedParam }] = useState(() => createFilterParams('/design'));
	const [{ includeNonPermitted }, , updateUrlState] = useUrlState<UrlState>([includeNonPermittedParam]);

	return (
		<Flex
			className="experiment-collapsed-sidebar"
			style={{
				overflowY: 'auto',
				py: 'xSmall',
				px: collapsed ? 'none' : 'xSmall',
				paddingLeft: collapsed ? '0px' : 'xSmall',
				width: collapsed ? 'calc(100% + 16px)' : 'calc(100% - 16px)',
				height: '100%',
			}}
			spacing="xSmall"
		>
			{!collapsed && (
				<IncludePermittedToggle
					checked={includeNonPermitted}
					small
					onChange={(checked) => updateUrlState({ includeNonPermitted: checked })}
				/>
			)}

			{!templates ? (
				<Flex spacing="xSmall" style={{ width: 'calc(100% - 18px)' }}>
					<TemplateSkeleton collapsed={collapsed} />
					<TemplateSkeleton collapsed={collapsed} />
					<TemplateSkeleton collapsed={collapsed} />
				</Flex>
			) : templates.length === 0 ? (
				<Box style={{ p: 'small' }}>
					<Text type="medium" style={{ color: theme.colors.neutral500 }}>
						{isSearchDefined ? 'No templates matched your query.' : 'No templates found.'}
					</Text>
				</Box>
			) : (
				<Templates
					selectedTemplateId={selectedTemplateId}
					targetDefinitions={targetDefinitions}
					templates={templates}
					collapsed={collapsed}
					onTemplateClick={onTemplateClick}
				/>
			)}
		</Flex>
	);
}
