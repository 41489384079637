/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Stack, Text, Toggle, Tooltip } from 'components';
import { IconInformation } from 'components/icons';
import { ReactElement } from 'react';

interface IncludePermittedToggleProps {
	small?: boolean;
	checked: boolean;
	onChange: (checked: boolean) => void;
}

export default function IncludePermittedToggle({
	small = false,
	checked,
	onChange,
}: IncludePermittedToggleProps): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="large"
			alignItems="center"
			justifyContent="space-between"
			width={small ? '100%' : undefined}
			py="xSmall"
		>
			<Stack direction="horizontal" size="xSmall" alignItems="center">
				<Text variant="mediumStrong">{small ? 'Show all templates' : 'Show templates with missing permissions'}</Text>
				<Tooltip
					content={
						<>
							<div>Show templates that include actions that your team misses permissions.</div>
							<div>You can remove these actions in the created experiment.</div>
						</>
					}
				>
					<div>
						<IconInformation variant="small" color="neutral600" />
					</div>
				</Tooltip>
			</Stack>
			<Toggle checked={checked} onChange={() => onChange(!checked)} />
		</Stack>
	);
}
