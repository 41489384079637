/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { CSSProperties } from 'react';

const snippet: CSSProperties = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'normal',
	display: '-webkit-box !important',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	whiteSpace: 'break-spaces',
};

export default snippet;
