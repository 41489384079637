/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import ExperimentSmallPreview from 'templates/components/TemplateCard/ExperimentSmallPreview';
import { ActionVO, TargetTypeDescriptionVO, TemplatePreviewVO } from 'ui-api';
import textEllipsis3Lines from 'utils/styleSnippets/textEllipsis3Lines';
import { Container, Pill, Text, Tooltip } from 'components';
import { createIconFromDataUri } from 'components/icons';
import Skeletons from 'components/Skeleton/Skeletons';
import { Flex } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { theme } from 'styles.v2/theme';

import { correctDropAnimation, SidebarTemplateMaker, WorkspaceLaneMaker } from '../DragAndDropHandler';
import useIsExperimentDisabled from '../useIsExperimentDisabled';
import useActions from '../useActions';

interface TemplatesProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	templates?: TemplatePreviewVO[];
	selectedTemplateId?: string;
	collapsed?: boolean;
	onTemplateClick: (templateId: string) => void;
}

export default function Templates({
	selectedTemplateId,
	targetDefinitions,
	collapsed,
	templates,
	onTemplateClick,
}: TemplatesProps): ReactElement | null {
	const [fixedId] = useState(Math.random());
	const disabled = useIsExperimentDisabled();
	const { allActions } = useActions();

	if (!templates) {
		return null;
	}

	return (
		<Droppable droppableId={'droppable' + fixedId} isDropDisabled type="steps">
			{(droppableProvided) => (
				<div
					ref={droppableProvided.innerRef}
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: collapsed ? '16px' : '4px',
						width: '100%',
					}}
				>
					{templates.map((template, i) => {
						const id = template.id;
						if (disabled) {
							return (
								<Template key={id} targetDefinitions={targetDefinitions} template={template} actions={allActions} />
							);
						}

						return (
							<Draggable key={SidebarTemplateMaker + id} draggableId={SidebarTemplateMaker + id} index={i}>
								{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
									const isDraggingOverLane: boolean =
										dragSnapshot.draggingOver && dragSnapshot.draggingOver.startsWith(WorkspaceLaneMaker)
											? true
											: false;

									return (
										<div
											ref={dragProvided.innerRef}
											{...dragProvided.draggableProps}
											{...dragProvided.dragHandleProps}
											style={correctDropAnimation(dragSnapshot, dragProvided.draggableProps.style)}
											onClick={() => onTemplateClick(id)}
										>
											<Template
												key={template.id}
												isDraggingOverLane={isDraggingOverLane}
												isSelected={selectedTemplateId === id}
												targetDefinitions={targetDefinitions}
												isDragging={dragSnapshot.isDragging}
												collapsed={collapsed}
												actions={allActions}
												template={template}
											/>
										</div>
									);
								}}
							</Draggable>
						);
					})}

					{droppableProvided.placeholder}
				</div>
			)}
		</Droppable>
	);
}

interface TemplateProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	isDraggingOverLane?: boolean;
	template: TemplatePreviewVO;
	isSelected?: boolean;
	isDragging?: boolean;
	collapsed?: boolean;
	actions: ActionVO[];
}

function Template({
	isDraggingOverLane,
	targetDefinitions,
	isDragging,
	isSelected,
	collapsed,
	template,
	actions,
}: TemplateProps): ReactElement {
	const { purple100, neutral100, slate, slateLight, neutral000 } = theme.colors;

	const experimentPreview = (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				minHeight: '48px',
				maxHeight: '48px',
				minWidth: '54px',
				maxWidth: '54px',

				border: '1px solid',
				borderColor: collapsed && (isDragging || isSelected) ? slate : neutral100,
				backgroundColor: collapsed && (isDragging || isSelected) ? slateLight : neutral000,
			}}
		>
			<ExperimentSmallPreview key={template.id} lanes={template.lanes} actions={actions} />
		</Container>
	);

	if (collapsed) {
		return <Tooltip content={template.templateTitle}>{experimentPreview}</Tooltip>;
	}

	const borderColor = isDraggingOverLane || isSelected ? slate : isDragging ? slate : neutral000;

	return (
		<Flex
			spacing="xSmall"
			style={{
				padding: '10px 8px',
				width: '300px',

				backgroundColor: isDraggingOverLane || isSelected ? purple100 : neutral100,
				borderRadius: 'xxSmall',
				border: isDraggingOverLane ? `2px dashed ${borderColor}` : `2px solid ${borderColor}`,

				onHover: {
					border: isDraggingOverLane ? `2px dashed ${slate}` : `2px solid ${slate}`,
					backgroundColor: neutral100,
				},
			}}
		>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
					width: '100%',
				}}
			>
				{experimentPreview}

				<Tooltip content={template.templateTitle} onlyShowOnEllipsis>
					<Text
						as="span"
						variant="smallStrong"
						color="neutral800"
						sx={{
							...textEllipsis3Lines,
							lineHeight: '120%',
						}}
					>
						{template.templateTitle}
					</Text>
				</Tooltip>
			</Container>

			{template.targetTypes.length > 0 && (
				<Flex direction="horizontal" spacing="xxSmall" wrap>
					{template.targetTypes.map((target, i) => {
						const targetDefinition = targetDefinitions.find((def) => def.id === target);
						if (!targetDefinition) {
							return null;
						}
						const Icon = createIconFromDataUri(targetDefinition.icon);
						return (
							<Tooltip key={i} content={targetDefinition.label.one}>
								<div>
									<Pill
										backgroundColor="neutral200"
										color="neutral800"
										sx={{
											height: '28px',
											width: '28px',
											padding: '0px 0 0 4px',
											borderRadius: '14px',
										}}
									>
										<Icon variant="small" mr="xxSmall" />
									</Pill>
								</div>
							</Tooltip>
						);
					})}
				</Flex>
			)}
		</Flex>
	);
}

export function TemplateSkeleton({ collapsed }: { collapsed?: boolean }): ReactElement {
	if (collapsed) {
		return <Skeletons height={50} widths={[58]} />;
	}

	return (
		<Flex
			spacing="xSmall"
			style={{
				padding: '10px 8px',

				backgroundColor: theme.colors.neutral100,
				borderRadius: 'xxSmall',
				width: '300px',
			}}
		>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						minWidth: '58px',
						maxWidth: '58px',
						minHeight: '50px',
						maxHeight: '50px',
						borderRadius: '4px',
						backgroundColor: 'neutral000',
					}}
				></Container>

				<Flex spacing="xxSmall">
					<Skeletons height={12} widths={[100]} />
					<Skeletons height={12} widths={[160]} />
				</Flex>
			</Container>
			<Flex spacing="xxSmall">
				<Skeletons height={12} widths={[60, 40, 100]} />
				<Skeletons height={12} widths={[50, 140]} />
			</Flex>
		</Flex>
	);
}
