/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, LoadingIndicator, Stack } from 'components';
import { IconSaveDisc, IconTrash } from 'components/icons';
import { ReactElement, useState } from 'react';
import { ExperimentScheduleVO } from 'ui-api';

interface ScheduleFooterProps {
	schedule: ExperimentScheduleVO | undefined;
	isValidDateSelected: boolean;
	disabled: boolean;
	setScheduleIdToDelete: (id: string | undefined) => void;
	onSave: () => Promise<void>;
}

export default function ScheduleFooter({
	isValidDateSelected,
	schedule,
	disabled,
	setScheduleIdToDelete,
	onSave,
}: ScheduleFooterProps): ReactElement {
	const [isSaving, setIsSaving] = useState<boolean>(false);

	return (
		<Stack size="small" direction="horizontal" width={schedule ? '100%' : 'auto'}>
			{schedule && (
				<Button
					variant="chromelessSmall"
					color="neutral600"
					mr="auto"
					onClick={async () => {
						setScheduleIdToDelete(schedule?.id ?? '');
					}}
					disabled={disabled}
				>
					<IconTrash mr="xxSmall" />
					Delete Schedule
				</Button>
			)}

			<Button variant="secondary" onClick={close}>
				Cancel
			</Button>

			<Button
				variant="primary"
				disabled={!isValidDateSelected || isSaving || disabled}
				onClick={async () => {
					setIsSaving(true);
					await onSave();
					setIsSaving(false);
				}}
			>
				{isSaving ? <LoadingIndicator color="neutral000" sx={{ mr: 'xSmall' }} /> : <IconSaveDisc mr="xSmall" />}
				Save Schedule
			</Button>
		</Stack>
	);
}
