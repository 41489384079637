/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { LoadingIndicator, ModalContentV2, ModalHeaderV2, ModalV2, Stack, Text } from 'components';
import { ReactElement } from 'react';

interface LoadingTemplateModalProps {
	onClose: () => void;
}

export default function LoadingTemplateModal({ onClose }: LoadingTemplateModalProps): ReactElement {
	return (
		<ModalV2 slick withFooter width="90vw" maxWidth="1650px" minHeight="500px" centered>
			<ModalHeaderV2 title="" onClose={onClose} />
			<ModalContentV2>
				<Stack
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						pt: '140px',
					}}
				>
					<LoadingIndicator variant="xxLarge" color="slate" />
					<Text variant="largeStrong" color="neutral600">
						Preparing template...
					</Text>
				</Stack>
			</ModalContentV2>
		</ModalV2>
	);
}
