/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconLightbulb } from 'components/icons';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

export default function CronExamples(): ReactElement {
	return (
		<Stack
			direction="horizontal"
			size="xSmall"
			sx={{
				p: 'small',
				borderRadius: 8,
				alignItems: 'flex-start',
				border: '1px solid ' + theme.colors.neutral300,
			}}
		>
			<IconLightbulb variant="large" color="neutral600" />
			<Stack size="xSmall">
				<Text variant="largeStrong" color="neutral600">
					Crontab examples:
				</Text>
				<CronTabExample syntax="0 15 10 ? * *" description="= 10:15am every day" />
				<CronTabExample syntax="0 15 10 * * ? 2025" description="= 10:15am every day during the year 2025" />
				<CronTabExample
					syntax="0 15 10 ? * MON-FRI"
					description="= 10:15am every Monday, Tuesday, Wednesday, Thursday and Friday"
				/>
			</Stack>
		</Stack>
	);
}

interface CronTabExampleProps {
	syntax: string;
	description: string;
}

function CronTabExample({ syntax, description }: CronTabExampleProps): ReactElement {
	return (
		<Stack direction="horizontal" size="xSmall" alignItems="center">
			<Text
				variant="smallCode"
				sx={{
					color: 'neutral600',
					bg: 'neutral100',
					px: 'xSmall',
					py: 'xxSmall',
					borderRadius: 4,
				}}
			>
				{syntax}
			</Text>
			<Text variant="smallCode" color="neutral600">
				{description}
			</Text>
		</Stack>
	);
}
