/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconArrowLeft, IconArrowRight } from 'components/icons';
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { Button } from 'components';

import { UseTemplateFormData } from '../UseTemplateForm';

interface FooterProps {
	submitLabel: string;
	pages: number;
	page: number;
	setPage: (newPage: number) => void;
}

export default function Footer({ page, pages, submitLabel, setPage }: FooterProps): ReactElement | null {
	const { submitForm } = useFormikContext<UseTemplateFormData>();

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row-reverse',
				justifyContent: 'space-between',
				padding: '12px',
				boxShadow: '0px -1px 3px 1px rgba(0, 0, 0, 0.05)',
			}}
		>
			{page === pages - 1 ? (
				<Button onClick={submitForm} data-cy="create-experiment-button">
					{submitLabel}
				</Button>
			) : (
				<Button onClick={() => setPage(page + 1)} data-cy="next-button">
					Next <IconArrowRight ml="xSmall" />
				</Button>
			)}

			{page > 0 && (
				<Button variant="secondary" onClick={() => setPage(page - 1)}>
					<IconArrowLeft ml="-xxSmall" mr="xSmall" /> Back
				</Button>
			)}
		</div>
	);
}
